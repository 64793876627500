import React from 'react';
import { useAdsContext } from '../../providers';

/**
 * Our header is relative positioned on load, and fixed on scroll. This is because iur header has optional dynamically sized elements above it, such as a CalamityBanner or TopHeader. This component is used to wrap everything inside of the header, and will calculate the height of the header and pass it to the AdsContext.
 */

type HeaderWrapperProps = { children: React.ReactNode };
export const HeaderWrapper = ({ children }: HeaderWrapperProps) => {
  const adsContext = useAdsContext();
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const headerHeight = ref.current?.clientHeight;
    adsContext.setHeaderHeight(headerHeight || 0);
  }, [adsContext]);

  return <div ref={ref}>{children}</div>;
};
