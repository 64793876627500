import React from 'react';
import { FormEvent } from 'react';
import { Box } from '../Box';
import { Button, ButtonProps } from '../Button';
import { Heading as HeadingComponent } from '../Heading';
import { InputBaseProps } from '../InputBase';
import { InputGroup, InputGroupTone } from '../InputGroup';
import { InputNumber } from '../InputNumber';
import { InputText } from '../InputText';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import {
  PostalCodeTaskVariants,
  postalCodeTask,
} from './PostalCodeCheckForm.css';

export type PostalCodeCheckFormInputFieldProps = {
  houseNumber?: string;
  postalCode: string;
  addition?: string;
};

export type PostalCodeInputProps = Omit<InputBaseProps, 'type'>;

const Input = React.forwardRef<HTMLInputElement, PostalCodeInputProps>(
  (props, ref) => {
    return <InputText {...props} ref={ref} />;
  }
);

type PostalCodeAddendumProps = {
  label: string;
  error?: string;
  houseNumber: Omit<PostalCodeInputProps, 'tone'>;
  addition: Omit<InputBaseProps, 'tone'>;
  className?: string;
} & InputGroupTone;

const Addendum = ({
  label,
  error,
  houseNumber,
  addition,
  tone,
  className,
}: PostalCodeAddendumProps) => {
  return (
    <InputGroup label={label} error={error} tone={tone} className={className}>
      <Stack gap={4} direction="row" isFullWidth>
        <StackItem grow>
          <InputNumber {...houseNumber} tone={tone} />
        </StackItem>
        <StackItem grow>
          <InputText {...addition} tone={tone} />
        </StackItem>
      </Stack>
    </InputGroup>
  );
};

type PostalCodeSubmitButtonProps = {
  children: React.ReactNode;
  className?: string;
} & ButtonProps;

const SubmitButton = React.forwardRef<
  HTMLButtonElement,
  PostalCodeSubmitButtonProps
>(({ children, className, ...props }, ref) => {
  return (
    <Stack
      alignX="center"
      alignY="center"
      className={className}
      isFullWidth
      asChild
    >
      <Button {...props} type="submit" ref={ref}>
        {children}
      </Button>
    </Stack>
  );
});

type PostalCodeHeadingProps = {
  children: React.ReactNode;
  className?: string;
};

const Heading = ({ children, className }: PostalCodeHeadingProps) => {
  return (
    <StackItem className={className} asChild grow>
      <HeadingComponent
        size={{ initial: 'description', md: 'paragraph' }}
        fontWeight="semiBold"
        as="h2"
        color="onDark"
        isResponsive={false}
      >
        {children}
      </HeadingComponent>
    </StackItem>
  );
};

export type PostalCodeCheckFormProps = {
  handleOnSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
  className?: string;
} & PostalCodeTaskVariants;

export const PostalCodeCheckForm = ({
  handleOnSubmit,
  children,
  className,
}: PostalCodeCheckFormProps) => {
  return (
    <Stack
      gap={3}
      direction={'column'}
      className={className}
      isFullHeight
      asChild
    >
      <form onSubmit={(e) => handleOnSubmit(e)}>{children}</form>
    </Stack>
  );
};

type PostalCodeCheckContainerProps = {
  children?: React.ReactNode;
  className?: string;
} & PostalCodeTaskVariants;

const Container = ({
  children,
  color = 'purple',
  className,
}: PostalCodeCheckContainerProps) => {
  return (
    <Box
      paddingTop={{ initial: 10, lg: 12 }}
      paddingBottom={{ initial: 10, lg: 12 }}
      paddingLeft={{ initial: 10, lg: 12 }}
      paddingRight={{ initial: 10, lg: 12 }}
      className={className}
      asChild
    >
      <Stack
        className={postalCodeTask({ color })}
        alignX={{ lg: 'justify' }}
        alignY={{ lg: 'justify' }}
        direction={{ initial: 'column' }}
        gap={4}
      >
        {children}
      </Stack>
    </Box>
  );
};

PostalCodeCheckForm.Heading = Heading;
PostalCodeCheckForm.Container = Container;
PostalCodeCheckForm.Input = Input;
PostalCodeCheckForm.Addendum = Addendum;
PostalCodeCheckForm.SubmitButton = SubmitButton;
