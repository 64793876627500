import React from 'react';
import { WizardSimpleProps } from '@ads-core/types';
import { ScrollLinkWrapper, WizardSimple } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTrackingContext } from '@liander/context';

type WizardJssResponseProps = ComponentProps & {
  fields: WizardSimpleProps;
  params?: {
    anchor?: string;
  };
};

type Conclusion = {
  title: string;
};

type Question = {
  text: string;
};

const Wizard = (props: WizardJssResponseProps) => {
  const { trackWizardSimpleChange } = useTrackingContext();
  const tracking = (step: Question[] | Conclusion, title: string, index?: number) => {
    if (Array.isArray(step)) {
      trackWizardSimpleChange({
        action: 'wizard proceed',
        actionDetail: `Step: ${step[0].text}`,
        wizardStep: `Step: ${index}`,
        wizardName: title,
      });
    } else {
      trackWizardSimpleChange({
        action: 'wizard conclusion',
        actionDetail: step.title,
        wizardStep: 'conclusion',
        wizardName: title,
      });
    }
  };

  // TODO: Map the primaryButton and secondaryButton to ReactElements (<NextLink> or <a>). If they're possibly external links, we should create an InternalExternalLink component
  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <WizardSimple {...props.fields} onStepChange={tracking} />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(Wizard);
