import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYBToAo7AGd4k9inry2PRPygkS3KcOLZjOYmTov9ejDZuIZJToDEi2GfOXDjiIaf4A4edPqBW8OdPAHd3t98YYEbfqST/6wSnALP1+DfFdYBZSbjyyyluAsyU8tW9nuI2wMyskcxiijshjhfylgJeCXgtxG/GOq31bopvhPpbIX4n4L2Ab4U+Pwj17IT6H4U4T0KcvbCuZ6HOg4AfBfwk4C8CfhbqHwT+ReC/Cvw3AX8X4lwDzHrLnyn+wThujOqn+GeAGdbNptpMcVRCYmSFre/XZq0iAwmvDPU/terYx0hZrJTFCQtHL+wwZKmtFsvlKpIsstbcAitXRgYWm12gtVVkaAT1oyQ3ZL0tq9ViHRu60cOV5Soy9D/auPBxS7ZSf1lzfvnd6LgqFt3KrtUyXiCrrmt737WRgWXX2d71sYF11/e96aJ9hSy8vu3aJNRBaslRWsdJ8hjFd195jHvF6lsqX6m4V0OAy7k+DKf63B0ukVHSIL7+l5ekRHyXNt5V2i0f0kI/hdaQEuRAKCQn6cIjLd2QRugyWckgaZGkW49KqVzWInW+1RQZWIuKyrLFyNCMe9htVOzBWmw19RQdddQKOiHW4r1WSsXd7QOo0xVQ8bPkx3nb1L9oPwfCOeg5qELRt/nIMlh4793pCloXhgjzdDTae/zyISq0dppz+KJ0leCCFdHNBamwhG4sq1DoSfD5orvCWcWl2EKTUmWerUrlfeluVRXeeXLjN0JdCitR1hFV+C1q2jbA7jB0F1Djh53zu+QhQ8zuml0YjcNxv2uFWI8TSjbKUwDuVVsPD10ryGk/5eRESs8BzOkagQfxjqN/R43EdJLk8CKcECTNGzQIhw1dpBzSyEFvUo534bilq6SsD0lZfNI1bVs1XTSP80lXb9qmj2Q9TvZ901IdTSrjaN/rpktC6VG9EWjGNxyBNvNKeaQvE9AHUAzf/iNzyeYf4rw9IkYVwKqfI7DOVdrkitrkimoDYLqoLuTy9wEozbUNQGmyhwCUZtsF0Gm2xwA6zfYUwKTZ9gFMGvc5gE0jHAK4tLJjAJ/WcArjIR2hLwGqNO6Ze5aSB25aCl+4ayn8GuD38/G6e67ht+NbPYf7867ez2GoD8Pd0J130RXE4/z/c3gP4GLx8JBvEvAj29TPAFhEV6dRubdiMEel3Ms2mqnR0WRMbrcYm6O63HYzPkctA/BNGqFVjloHwLSAhqnR1Go2OW2YNkdlGaW19l/Uv/4GI4wnl4oQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA72VXY+iMBSG7/kV52YTTcTw4TiKycZ/Mqn0IF1Ly7TVcbPxv29A5UuQgTV7J3L6nIeX07I94O9IkQQ1fLi/MF6n0oc/FkCkZJL/AOAYmQDsN+fHxgK4WABG1m4Vdy7W9pG3aOd149znvLehPPs5b9nOc8c+77rJMzLtiC+/85xGWmmdsJ7sdsNoPcmFrbTW4JpPOr8znLwglZoZJkUAZKclPxrcWPVFRhGhI6mS4PqTE4OTzG+WW043Veh7Dg0llyqAE1ET2/5wNfOF67vTjFYfDCJYQq7tyyHzHSfRgESjzYQtj6bWYVXtwESMipkS3M1d9nC9QEgzmYdSGCU5RzoNYnlCBT+hqHFnYEF56VWq5yQ07IS16tx0R8LDXsmjoHZ7LJ95LjupKKoAnO9JlYH3KzVeTks2fls2tUgHOa0GOK26nBbPnNymFP3nudv1zAeOnLtw7NyVzzYgTtoV5/q1rxhn8H0p7JIig95xdN1P+UaxFaHsqIvgy2NMISdZ++zfzDni8iuAmFGKYtO6H/NTLSUKhSnOuxusWQzO3NdZUcTxbOtYMXFo7tl93yj2nghO7URw0zNoyRltgVXaxv+prVdvy7rbemN4POd9MWriAHwvPWeMGNk+NvfrSnVSrV449err9cXaJkgZAR0qRAFEUJgkTNi3Ve/LVXqe5pwCK27fzoZGU+T6pS1Wyfqqu05T6PZRfyrlOt7iwSodZfX5QqtFVtuwUqOs9Aut1t6jlRlldRxg9RfMCCDtwAsAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var AnimatedArrow = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{size:{medium:{initial:'_1jeh9pol',sm:'_1jeh9pon',md:'_1jeh9pop',lg:'_1jeh9por',xl:'_1jeh9pot'},large:{initial:'_1jeh9pom',sm:'_1jeh9poo',md:'_1jeh9poq',lg:'_1jeh9pos',xl:'_1jeh9pou'}}},variantClassNames:{color:{onLight:{initial:'_1jeh9pog'},onDark:{initial:'_1jeh9poh'},ghostOnDark:{initial:'_1jeh9poi'}},direction:{leftToRight:{initial:'_1jeh9poj'},topToBottom:{initial:'_1jeh9pok'}}},compoundVariants:[],defaultVariants:{direction:'leftToRight',size:'medium',color:'onLight'},baseClassName:'_1jeh9pof'});
export var arrowWrap = '_1jeh9po2';
export var flyBase = '_1jeh9po0';
export var flyIn = '_1jeh9po7';
export var flyInAnimation = '_1jeh9po5';
export var flyInAnimationHover = '_1jeh9po3';
export var flyInTopToBottom = '_1jeh9pod';
export var flyInTopToBottomAnimation = '_1jeh9pob';
export var flyInTopToBottomAnimationHover = '_1jeh9po9';
export var flyOut = '_1jeh9po8';
export var flyOutAnimation = '_1jeh9po6';
export var flyOutAnimationHover = '_1jeh9po4';
export var flyOutTopToBottom = '_1jeh9poe';
export var flyOutTopToBottomAnimation = '_1jeh9poc';
export var flyOutTopToBottomAnimationHover = '_1jeh9poa';
export var innerWrap = '_1jeh9po1';