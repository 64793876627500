import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { Stack } from '../Stack';
import { button } from '../Button/Button.css';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons/index';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import * as styles from './Pagination.css';

type PaginationProps = ReactPaginateProps;

export const Pagination = ({
  onPageChange,
  pageCount,
  forcePage,
}: PaginationProps) => {
  const isDesktop = useMedia(mq.md);
  const ghostButtonClassName = button({ variant: 'ghost' });

  if (pageCount > 1) {
    return (
      <Stack
        asChild
        direction="row"
        gap={{ initial: 1, md: 4 }}
        isFullWidth
        alignX="center"
        alignY="center"
      >
        <ReactPaginate
          forcePage={forcePage}
          breakLabel="..."
          onPageChange={(e) => {
            onPageChange?.(e);
          }}
          pageLinkClassName={ghostButtonClassName}
          previousLinkClassName={ghostButtonClassName}
          nextLinkClassName={ghostButtonClassName}
          activeLinkClassName={styles.active}
          marginPagesDisplayed={isDesktop ? 3 : 0}
          pageRangeDisplayed={isDesktop ? 3 : 1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          previousAriaLabel="Vorige pagina"
          nextAriaLabel="Volgende pagina"
          nextLabel={
            <>
              {isDesktop ? 'Volgende' : null}
              <ArrowRightIcon size="xs" />
            </>
          }
          previousLabel={
            <>
              <ArrowLeftIcon size="xs" />
              {isDesktop ? 'Vorige' : null}
            </>
          }
        />
      </Stack>
    );
  }

  return null;
};
