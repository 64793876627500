import React from 'react';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading';
import { PageGrid } from '../PageGrid';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { ButtonLink } from '../ButtonLink';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './InspirationalCards.css';

export const InspirationalCardsComponent = (props: InspirationalCardsProps) => (
  <InspirationalCardsView {...props} />
);

export const InspirationalCardsSitecore = (
  props: InspirationalCardsSitecoreProps
) => <InspirationalCardsView {...props} />;

export const InspirationalCardsView = ({
  onColoredSection,
  title,
  articles,
}: InspirationalCardsViewProps) => {
  return (
    <Box>
      <PageGrid>
        <GridItem
          columnStart={{ initial: '1', lg: '3' }}
          columnEnd={{ initial: '-1', lg: '-5' }}
        >
          <Heading
            size="h2"
            align={{ initial: 'center', lg: 'start' }}
            className={styles.title}
            color={onColoredSection ? 'onDark' : 'onLight'}
            asChild
          >
            {title}
          </Heading>
        </GridItem>
      </PageGrid>

      <PageGrid className={styles.cardWrapper}>
        {articles.map(({ infoImage, tag, shortTitle, button }, i) => {
          return (
            <GridItem
              columnStart={{ initial: '1', md: 'auto' }}
              columnEnd={{ initial: '-1', md: 'span 4' }}
              key={`inspiration-card-${i}`}
            >
              <Stack
                direction="column"
                className={styles.card}
                asChild
                isFullWidth
              >
                <Box>
                  {infoImage ? (
                    <Box className={styles.image} asChild>
                      {infoImage}
                    </Box>
                  ) : null}

                  <Stack
                    direction="column"
                    className={styles.cardContentWrapper}
                    alignY="justify"
                  >
                    <Box>
                      {tag ? (
                        <Box paddingBottom={{ initial: 6, lg: 8 }}>
                          <Text size="label" asChild>
                            {tag}
                          </Text>
                        </Box>
                      ) : null}
                      {shortTitle ? (
                        <Heading
                          size="h5"
                          className={styles.description}
                          asChild
                        >
                          {shortTitle}
                        </Heading>
                      ) : null}
                    </Box>
                    {button ? (
                      <Box className={styles.button}>
                        <ButtonLink
                          size="large"
                          tone="onLight"
                          variant="primary"
                          asChild
                        >
                          {button}
                        </ButtonLink>
                      </Box>
                    ) : null}
                  </Stack>
                </Box>
              </Stack>
            </GridItem>
          );
        })}
      </PageGrid>
    </Box>
  );
};

type InspirationalCardsProps = {
  title?: string;
  articles: {
    tag?: string;
    shortTitle?: string;
    button?: React.ReactElement;
    infoImage?: React.ReactElement;
  }[];
  onColoredSection?: boolean;
};

export type InspirationalCardsSitecoreProps = {
  title?: React.ReactElement;
  articles: {
    infoImage?: React.ReactElement;
    tag?: React.ReactElement;
    button?: React.ReactElement;
    shortTitle?: React.ReactElement;
  }[];
  onColoredSection?: boolean;
};

type InspirationalCardsViewProps = CombineProps<
  InspirationalCardsProps,
  InspirationalCardsSitecoreProps
>;
