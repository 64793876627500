import clsx from 'clsx';
import React from 'react';
import {
  PageGrid,
  GridItem,
  Tag,
  Box,
  Heading,
  StackItem,
  Stack,
  Hidden,
  Image,
} from '..';
import { useAdsContext } from '../../providers';
import { scrollToElement } from '../../global/utils';
import * as styles from './WizardSimple.css';
import { WizardSimpleStepWrapper } from './components/WizardSimpleStepWrapper';
import { WizardSimpleConclusion } from './components/WizardSimpleConclusion';

// We do a tree traversal to extract all questions and conclusions from the steps
function extractQuestionsAndConclusions(node: Question | Conclusion) {
  const questions: Question[] = [];
  const conclusions: Conclusion[] = [];

  function traverse(node: Question | Conclusion) {
    if (!node) return; 
    // Check if the current node is a Question type
    if (node.type === 'question') {
      questions.push(node);

      // Traverse through answers of the question
      node.answers.forEach((answer) => {
        traverse(answer.followUpSteps);
      });
    }

    // Check if the current node is a Conclusion type.
    // Conclusions don't have answers, so we dont need to traverse through them
    else if (node.type === 'conclusion') {
      conclusions.push(node);
    }
  }

  traverse(node);

  return { questions, conclusions };
}

/**
 * We need to render the whole tree of questions and conclusions in the wizard for the search results and SEO
 * But, obviously, we only want to show the [first question/all answered questions] and the current conclusion
 * So we keep track of the visible steps and visible conclusion in the state
 **/

export const WizardSimple = (props: WizardSimpleProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const conclusionRef = React.useRef<HTMLDivElement>(null);

  const { title, tag } = props;
  const amount = 2;

  const adsContext = useAdsContext();

  const firstStep = props.steps;
  const [visibleSteps, setVisibleSteps] = React.useState<Question[]>([
    firstStep,
  ]);

  const visibleStepIds = visibleSteps.map((s) => s.id);

  const [visibleConclusion, setVisibleConclusion] =
    React.useState<Conclusion | null>(null);

  const results = React.useMemo(
    () => extractQuestionsAndConclusions(props.steps),
    [props.steps]
  );
  const lastAvailableImage = [...visibleSteps, visibleConclusion]
    .reverse()
    .find((s) => s?.image?.src)?.image;

  const tracking = React.useCallback(
    (step: Question[] | Conclusion, index?: number) => {
      props.onStepChange(step, title, index);
    },
    [props, title]
  );

  // When the step or the conclusion changes, the page has to scroll to the bottom of the wizard container or to the start of the conclusion
  React.useEffect(() => {
    if (visibleConclusion) {
      scrollToElement(conclusionRef, adsContext.topHeaderHeight);
      return;
    };

    if (visibleSteps.length > 1) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };
  }, [visibleConclusion, visibleSteps, adsContext.topHeaderHeight]);

  return (
    <PageGrid>
      <GridItem
        ref={containerRef}
        columnStart={{ initial: '1', md: '3' }}
        columnEnd={{ initial: '-1', md: '-3' }}
      >
        <StackItem asChild grow>
          <Box zIndex="2" position="relative">
            <Stack alignX={{ initial: 'center', md: 'start' }}>
              {tag ? (
                <Box paddingBottom={{ initial: 6, md: 8 }}>
                  <Tag>{tag}</Tag>
                </Box>
              ) : null}
              {title ? (
                <Box paddingBottom={{ initial: 6, md: 12 }} asChild>
                  <Heading size="h2" align={{ initial: 'center', md: 'start' }}>
                    {title}
                  </Heading>
                </Box>
              ) : null}

              <Box position="relative" asChild>
                <Stack
                  isFullWidth
                  gap={2}
                  className={clsx({ [styles.lined]: amount > 1 })}
                >
                  {results.questions.map((s) => {
                    const answeredQuestion = visibleSteps.find(
                      (step) => step.id === s.id
                    );
                    const index = visibleStepIds.indexOf(s.id);

                    return (
                      <WizardSimpleStepWrapper
                        show={visibleStepIds.includes(s.id)}
                        key={s.id}
                        index={index + 1}
                        question={answeredQuestion || s}
                        editAnswerLabel={props.editAnswerLabel}
                        onEditAnswerClick={() => {
                          // Set current step as unanswered
                          const newSteps = [...visibleSteps];
                          newSteps[index] = {
                            ...newSteps[index],
                            givenAnswer: undefined,
                          };

                          // Remove all steps after the current step
                          newSteps.splice(index + 1, newSteps.length - index);

                          // Update state
                          setVisibleSteps(newSteps);

                          // Remove conclusion
                          setVisibleConclusion(null);
                        }}
                        onAnswerClick={(nextStep, answer) => {
                          // Set the current answer as answered
                          const newSteps = [...visibleSteps];
                          newSteps[index] = {
                            ...newSteps[index],
                            givenAnswer: answer,
                          };

                          const previousStep = newSteps.slice(-1);
                          const stepCount = newSteps.length;

                          tracking(previousStep, stepCount);

                          // Update state
                          if (nextStep.type === 'conclusion') {
                            setVisibleConclusion(nextStep);
                            setVisibleSteps(newSteps);

                            tracking(nextStep);
                          } else {
                            setVisibleSteps([...newSteps, nextStep]);
                          }
                        }}
                      />
                    );
                  })}
                </Stack>
              </Box>
              {results.conclusions.map((conclusion) => {
                const isActiveConclusion =
                  visibleConclusion?.id === conclusion.id;

                return (
                  <Hidden
                    ref={isActiveConclusion ? conclusionRef : null}
                    hide={!isActiveConclusion}
                    key={conclusion.id}
                  >
                    <Box width="100%">
                      <WizardSimpleConclusion {...conclusion} />
                    </Box>
                  </Hidden>
                );
              })}
            </Stack>
          </Box>
        </StackItem>
      </GridItem>
      <GridItem columnStart="1" columnEnd="-1" className={styles.imageWrapper}>
        <Box
          borderRadius={{ md: 'brandXl' }}
          zIndex="1"
          width="100%"
          height={{ initial: '200px', md: '400px' }}
          asChild
        >
          <Image objectFit="cover" asChild>
            <img alt={lastAvailableImage?.alt} src={lastAvailableImage?.src} />
          </Image>
        </Box>
      </GridItem>
    </PageGrid>
  );
};

export type WizardSimpleProps = {
  tag: string;
  title: string;
  editAnswerLabel: string;
  steps: Question;
  onStepChange: (
    // TODO: why is Question an Array?
    step: Question[] | Conclusion,
    title: string,
    index?: number
  ) => void;
};

type Image = {
  src: string;
  alt: string;
};

export type Answer = {
  text: string;
  followUpSteps: Conclusion | Question;
};

export type Conclusion = {
  type: 'conclusion';
  title: string;
  text: string;
  image: Image;
  primaryButton: { href: string; text: string };
  secondaryButton: { href: string; text: string };
  id: string;
};

export type Question = {
  id: string;
  type: 'question';
  text: string;
  image: Image;
  givenAnswer?: Answer;
  answers: Answer[];
};
