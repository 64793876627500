import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { isElement } from 'react-is';
import { Text } from '../Text';
import { ThemeContainer } from '../ThemeContainer';
import { Heading } from '../Heading';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { useAdsContext } from '../../providers';
import { CloseIcon, InfoIcon } from '../../icons';
import { IconButton } from '../IconButton';
import { RichText } from '../RichText';
import { ButtonLink } from '../ButtonLink';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './DictionaryDialog.css';

function removeDoubleBrackets(str: string) {
  return str.replace(/\[\[(.*?)\]\]/g, '$1');
}

function splitByDoubleBrackets(str: string): string[] {
  const regex = /\[\[.*?\]\]/g; // regular expression to match double bracketed pieces
  const arr = str.split(regex); // split the string by the regular expression
  const pieces = str.match(regex); // get an array of the matched double bracketed pieces
  return arr.reduce((result, item, index) => {
    // add each piece and its corresponding double bracketed piece (if any) to the result array
    result.push(item);
    if (pieces && index < pieces.length) {
      result.push(pieces[index]);
    }
    return result;
  }, [] as string[]);
}

type Props = {
  children: string | React.ReactElement;
};
// Check for multiple bracketed words. Create a button for each bracketed word that will open a dialog with parameters that can be found in the context
// If the string does not contains brackets, we look for a single word. If that word is found in the context, we create a button that will open a dialog with parameters that can be found in the context
export const DictionaryDialog = ({ children }: Props) => {
  const adsContext = useAdsContext();
  if (isElement(children)) return children;

  const splitChildren = splitByDoubleBrackets(children);

  const words = splitChildren.reduce((result, word) => {
    const wordToCheck = removeDoubleBrackets(word.trim());
    const wordInContext = adsContext.dictionary.find(
      (item) => item.key === wordToCheck
    );

    if (wordInContext) {
      const element = (
        <React.Fragment key={word}>
          {' '}
          <ToneFocus>
            <Dialog.Trigger
              className={styles.button}
              onClick={() => adsContext.setActiveDictionary(wordInContext)}
            >
              {wordInContext.key}{' '}
              <div className={styles.buttonIcon}>
                <InfoIcon />
              </div>
            </Dialog.Trigger>
          </ToneFocus>{' '}
        </React.Fragment>
      );

      result.push(element);
    } else {
      const prevResult = result[result.length - 1];
      if (typeof prevResult === 'string') {
        result[result.length - 1] = `${prevResult} ${wordToCheck}`;
      } else {
        result.push(wordToCheck);
      }
    }

    return result;
  }, [] as React.ReactNode[]);

  const activeDictionary = adsContext.activeDictionary;

  return (
    <Dialog.Root>
      {words}
      {activeDictionary ? (
        <Dialog.Portal>
          <ThemeContainer>
            <Dialog.Overlay className={styles.overlay} />
            <Dialog.Content className={styles.dictionaryDialogContent}>
              <Dialog.Close className={styles.tooltipCloseButton} asChild>
                <IconButton
                  icon={<CloseIcon />}
                  label="Sluit"
                  tone="onDark"
                  shape="brand"
                />
              </Dialog.Close>
              <div className={styles.tooltipCircle} />
              <div className={styles.innerContent}>
                {activeDictionary.key ? (
                  <Box paddingBottom={{ initial: 12, md: 20 }}>
                    <Text asChild>
                      <RichText tone="onDark">
                        {`U klikte op '${activeDictionary.key}'`}
                      </RichText>
                    </Text>
                  </Box>
                ) : null}
                <Stack gap={{ initial: 8, md: 10 }}>
                  {activeDictionary.tooltip.title ? (
                    <Dialog.Title asChild>
                      <Heading size="h2">
                        {activeDictionary.tooltip.title}
                      </Heading>
                    </Dialog.Title>
                  ) : null}
                  <Dialog.Description asChild>
                    <Text asChild>
                      <RichText tone="onDark">
                        {activeDictionary.tooltip.text}
                      </RichText>
                    </Text>
                  </Dialog.Description>
                  {activeDictionary.tooltip.button ? (
                    <Stack isFullWidth alignX="start">
                      <ButtonLink asChild tone="onDark">
                        {activeDictionary.tooltip.button}
                      </ButtonLink>
                    </Stack>
                  ) : null}
                </Stack>
              </div>
            </Dialog.Content>
          </ThemeContainer>
        </Dialog.Portal>
      ) : (
        ''
      )}
    </Dialog.Root>
  );
};
