import {
  CongestionMarker,
  Heading,
  Stack,
  RichText,
  ButtonLink,
  DictionaryDialog,
} from '@ads-core/components';
import { ButtonProps } from '@alliander-fe/sitecore-types';

type CongestionsProps = {
  consume?: boolean;
  supply?: boolean;
};

export type CongestionFeedbackProps = {
  title?: string;
  description?: string;
  buttonPrimary?: ButtonProps;
  buttonSecondary?: ButtonProps;
  congestions?: CongestionsProps;
};

export const CongestionFeedback = ({
  title,
  description,
  congestions,
  buttonPrimary,
  buttonSecondary,
}: CongestionFeedbackProps) => {
  return (
    <Stack gap={6}>
      {congestions ? (
        <Stack direction="row" alignY="center" gap={6}>
          <CongestionMarker titel="Afnemen" congestion={congestions.consume} />
          <CongestionMarker titel="Terugleveren" congestion={congestions.supply} />
        </Stack>
      ) : null}

      {title ? (
        <Heading size="h3" as="h2">
          <DictionaryDialog>{title}</DictionaryDialog>
        </Heading>
      ) : null}

      {description ? <RichText>{description}</RichText> : null}

      {buttonPrimary?.text || buttonSecondary?.text ? (
        <Stack direction={{ initial: 'column', sm: 'row' }} gap={6}>
          {buttonPrimary?.text ? (
            <ButtonLink {...buttonPrimary} variant="primary">
              {buttonPrimary.text}
            </ButtonLink>
          ) : null}

          {buttonSecondary?.text ? (
            <ButtonLink {...buttonSecondary} variant="ghost">
              {buttonSecondary.text}
            </ButtonLink>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};
