import { useFormContext } from 'react-hook-form';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import * as styles from '../styles/Form.css';
import { encodeNameToReactHookFormFormat } from '../utils/utils';

export const HoneypotInputFieldMapper = ({ field }: ValueFieldProps) => {
  const { register } = useFormContext();
  const name = encodeNameToReactHookFormFormat(field.valueField.name);

  return (
    <div>
      <div>
        <div>
          <input type="text" id="familienaam" {...register(name)} className={styles.hiddenInput} />
        </div>
      </div>
    </div>
  );
};
