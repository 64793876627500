import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-nextjs';

import type {
  ImageProps,
  RichTextProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import type { Text as SitecoreTextType } from '@sitecore-jss/sitecore-jss-react';
import { RichText } from '@ads-core/components';

// For some reason it says that TextProps itself is not exported. So we infer from something we -can- get.
type TextProps = typeof SitecoreTextType extends React.ComponentType<infer P>
  ? P
  : never;

// JSS components don't allow refs which gives errors in the console when used with asChild and the Slot component from Radix. So we make our own wrappers, but don't pass the ref.to the component.
export const SitecoreImage = React.forwardRef<HTMLImageElement, ImageProps>(
  (props, _) => <Image {...props} />
);

export const SitecoreText = React.forwardRef<HTMLDivElement, TextProps>(
  (props, _) => <Text {...props} />
);

export const SitecoreRichText = React.forwardRef<HTMLDivElement, RichTextProps>(
  (props, _) => {
    const { editable, field } = props;
    const isEditor = editable && field?.editable;

    return <RichText>{isEditor ? field.editable : field?.value}</RichText>;
  }
);

// TODO: can we make this work?
// type SitecoreLinkProps = {
//   field: LinkField;
//   children?: React.ReactElement;
//   editable?: boolean;
// };

// const SitecoreLink = React.forwardRef<HTMLAnchorElement, SitecoreLinkProps>(
//   ({ field, children, editable = true, ...props }, _) => {
//     const isEditor = Boolean(field.editableFirstPart);
//     const hasElement = Boolean(field.value.href && field.value.text);
//     const content = isEditor ? undefined : children || field.value.text;
//     const element = (
//       <Link field={field} editable={editable} {...props}>
//         {content}
//       </Link>
//     );

//     // Note: we render a static link in the editor, because of the weird inner workings of the JSS <Link> component
//     return hasElement
//       ? isEditor
//         ? (parse(renderToString(element)) as React.ReactElement)
//         : element
//       : undefined;
//   }
// );
