import { Box } from '../../Box';
import { Button } from '../../Button';
import { Heading } from '../../Heading';
import { Stack } from '../../Stack';
import type { Answer, Conclusion, Question } from '..';

type StepContent = {
  question: Question;
  onAnswerClick(nextStep: Conclusion | Question, answer: Answer): void;
};

export const WizardSimpleUnansweredStep = ({
  question,
  onAnswerClick,
}: StepContent) => {
  return (
    <Box
      paddingTop={16}
      paddingBottom={16}
      paddingRight={4}
      paddingLeft={0}
      width="100%"
    >
      <Box paddingRight={{ initial: 4, md: 12 }}>
        <Stack gap={4}>
          <Heading size="h5" as="h3">
            {question.text}
          </Heading>
          <Stack direction="row" gap={2} wrap>
            {question.answers.map((a) => 
              a.followUpSteps ? (
                <Button
                  onClick={() => onAnswerClick(a.followUpSteps, a)}
                  key={a.text}
                >
                  {a.text}
                </Button>
              ): null
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
