import { getValidationModel } from '../utils/helpers';
import { ValidationModels } from '../types/global';

export function isValidValidationModel(
  validationModel: ValidationModels[0] | undefined,
  inputValue: string
): string | boolean {
  if (validationModel && inputValue) {
    const validation = getValidationModel(validationModel);

    if (validation && !validation.value.test(inputValue)) {
      return validation.message;
    }
  }
  return true;
}
