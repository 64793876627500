import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';

import { TextFieldMapper } from '../fields/TextField';
import { InputMapper } from '../fields/InputText';
import { ButtonMapper } from '../fields/Button';
import { DateFieldMapper } from '../fields/DateField';
import { SectionFieldMapper } from '../fields/Section';
import { FileFieldMapper } from '../fields/FileField';
import { NumberFieldMapper } from '../fields/NumberField';
import { EmailFieldMapper } from '../fields/EmailField';
import { TelephoneFieldMapper } from '../fields/TelephoneField';
import { RadioGroupFieldMapper } from '../fields/RadioGroupField';
import { CheckboxFieldMapper } from '../fields/CheckboxField';
import { TextareaFieldMapper } from '../fields/TextareaField';
import { CheckboxListFieldMapper } from '../fields/CheckboxListField';
import { DropdownListFieldMapper } from '../fields/DropdownField';
import { HoneypotInputFieldMapper } from '../fields/HoneypotField';
import { HiddenInputFieldMapper } from '../fields/HiddenInputField';
import { RichTextFieldMapper } from '../fields/RichTextField';
import { AddressBlockFieldMapper } from '../fields/AddressBlockField';

enum CustomFieldTypes {
  Honeypot = '{994AF83B-FCC8-4E24-882B-0C087B7EECAB}',
  AddressBlock = '{382EBA7B-CE68-4D1E-A729-54B9E7608880}',
  HiddenInput = '{C76C36FF-18A2-4260-8863-4FE04D2A0F87}',
  RichTextField = '{8C36842D-5518-4944-A2E4-84CACC0ECAB1}',
}

export const mapper = {
  [FieldTypes.SingleLineText]: InputMapper,
  [FieldTypes.TextField]: TextFieldMapper,
  [FieldTypes.Button]: ButtonMapper,
  [FieldTypes.DateField]: DateFieldMapper,
  [FieldTypes.Section]: SectionFieldMapper,
  [FieldTypes.FileUpload]: FileFieldMapper,
  [FieldTypes.NumberField]: NumberFieldMapper,
  [FieldTypes.Email]: EmailFieldMapper,
  [FieldTypes.Telephone]: TelephoneFieldMapper,
  [FieldTypes.RadioButtonList]: RadioGroupFieldMapper,
  [FieldTypes.Checkbox]: CheckboxFieldMapper,
  [FieldTypes.MultipleLineText]: TextareaFieldMapper,
  [FieldTypes.CheckboxList]: CheckboxListFieldMapper,
  [FieldTypes.DropdownList]: DropdownListFieldMapper,

  [CustomFieldTypes.Honeypot]: HoneypotInputFieldMapper,
  [CustomFieldTypes.HiddenInput]: HiddenInputFieldMapper,
  [CustomFieldTypes.RichTextField]: RichTextFieldMapper,
  [CustomFieldTypes.AddressBlock]: AddressBlockFieldMapper,
} as const;

export type Mapper = typeof mapper;
export type MapperKeys = keyof Mapper;
