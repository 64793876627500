import { ParsedUrlQuery } from 'querystring';
import { forceString } from './string';

export const baseQueryParams = {
  page: 'pagina',
  amount: 'aantal',
  term: 'term',
  reference: 'referentie',
  postalCode: 'postcode',
  city: 'plaats',
  houseNumberAddition: 'toevoeging',
  houseNumber: 'huisnummer',
  region: 'regio',
  segment: 'segment',
  tag: 'tag',
  category: 'categorie',
  type: 'type',
  brand: 'merk',
};

/**
 *
 * @param query a object of query parameters
 * @returns the values of the query parameters
 *
 * This function will return the values of the query parameters that are in the baseQueryParams object and replace the keys with the mapped values
 * We want to enforce using a fixed set of parameters to prevent typos and to have a clear overview of the parameters that are used
 *
 * Undefined router parameters will return an empty string, which are falsy but will prevent nonnull assertion errors. We might change this, it feels a bit hacky, but it prevents so much hassle
 */
export function getQueryParams(query: ParsedUrlQuery) {
  const params = Object.fromEntries(
    (Object.keys(baseQueryParams) as (keyof typeof baseQueryParams)[]).map((key) => [
      key,
      forceString(query[baseQueryParams[key]]),
    ])
  );

  return params as Record<keyof typeof baseQueryParams, '' | string>;
}
