import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';

import React from 'react';
import { CookieBotDeclaration } from '@ads-core/components';
import { COOKIEBOT_CBID } from 'src/data/constants';

const CookieDeclarationSitecore = () => {
  return <CookieBotDeclaration cbid={COOKIEBOT_CBID} />;
};

export default withDatasourceCheck()<ComponentProps>(CookieDeclarationSitecore);
