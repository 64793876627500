import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYBToAo7AGd4k9inry2PRPygkS3KcOLZjOYmTov9ejDZuIZJToDEi2GfOXDjiIaf4A4edPqBW8OdPAHd3t98YYEbfqST/6wSnALP1+DfFdYBZSbjyyyluAsyU8tW9nuI2wMyskcxiijshjhfylgJeCXgtxG/GOq31bopvhPpbIX4n4L2Ab4U+Pwj17IT6H4U4T0KcvbCuZ6HOg4AfBfwk4C8CfhbqHwT+ReC/Cvw3AX8X4lwDzHrLnyn+wThujOqn+GeAGdbNptpMcVRCYmSFre/XZq0iAwmvDPU/terYx0hZrJTFCQtHL+wwZKmtFsvlKpIsstbcAitXRgYWm12gtVVkaAT1oyQ3ZL0tq9ViHRu60cOV5Soy9D/auPBxS7ZSf1lzfvnd6LgqFt3KrtUyXiCrrmt737WRgWXX2d71sYF11/e96aJ9hSy8vu3aJNRBaslRWsdJ8hjFd195jHvF6lsqX6m4V0OAy7k+DKf63B0ukVHSIL7+l5ekRHyXNt5V2i0f0kI/hdaQEuRAKCQn6cIjLd2QRugyWckgaZGkW49KqVzWInW+1RQZWIuKyrLFyNCMe9htVOzBWmw19RQdddQKOiHW4r1WSsXd7QOo0xVQ8bPkx3nb1L9oPwfCOeg5qELRt/nIMlh4793pCloXhgjzdDTae/zyISq0dppz+KJ0leCCFdHNBamwhG4sq1DoSfD5orvCWcWl2EKTUmWerUrlfeluVRXeeXLjN0JdCitR1hFV+C1q2jbA7jB0F1Djh53zu+QhQ8zuml0YjcNxv2uFWI8TSjbKUwDuVVsPD10ryGk/5eRESs8BzOkagQfxjqN/R43EdJLk8CKcECTNGzQIhw1dpBzSyEFvUo534bilq6SsD0lZfNI1bVs1XTSP80lXb9qmj2Q9TvZ901IdTSrjaN/rpktC6VG9EWjGNxyBNvNKeaQvE9AHUAzf/iNzyeYf4rw9IkYVwKqfI7DOVdrkitrkimoDYLqoLuTy9wEozbUNQGmyhwCUZtsF0Gm2xwA6zfYUwKTZ9gFMGvc5gE0jHAK4tLJjAJ/WcArjIR2hLwGqNO6Ze5aSB25aCl+4ayn8GuD38/G6e67ht+NbPYf7867ez2GoD8Pd0J130RXE4/z/c3gP4GLx8JBvEvAj29TPAFhEV6dRubdiMEel3Ms2mqnR0WRMbrcYm6O63HYzPkctA/BNGqFVjloHwLSAhqnR1Go2OW2YNkdlGaW19l/Uv/4GI4wnl4oQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXTW/iMBC98yusSkhEqlESCLTmsoc97nEPe1uZ2CFugx05poWu+O+rOF92cCAg9UbIfLw382bszL/S04JSH/ybAEBYkWf4hAAXnG4mAHwyolIEwmhaPqWU7VKFQODnx/J5KyShEiqRIxDmR0BwkVIClMS8yLGkXJVWuSiYYoIjgLeFyA5KR9ZOK3+6mZwn8xrDnH5QroHIJlHPQBCi32c0cb2OD4USeyp/aiC/GKfausYZi0xIBD6wnEH4NyjYggeLIPZuhEBbmghJn8E1G5woKqtkOH7fSXHgZGRCsc8xP90DeX0jggPxhckdgHvpyibVGbR/LLiiXCHw9GRIptHIgGYkJuxQIBD502GNWCqoJePrX6XCEiH3qPqZYUVnkT99BjDyp15p8gUZJ/SIQNQXkEH9+6CbAr2JHI6AHmjAXTJJM6zYh062x3LHONxmIn6HhcJSlehK0J37lY4NTf19dagYxziLZw0RF9k/s5anu3It+6CtHAxtLu0AmqTum7ugGYm7QliTEPY64lCu7b6IvZaS48150vL6jXfjABET0OJxLouH98HyAaDRAz6rK/pv9WodB6vhQe8Uvawk3ZxzlsArRRsHm6MO1+ff7yS8sDW8HkunN92UkwvxMLNO6zGsq8DfTjqwd9AacZXCOGUZmQlCvPZ8Ml+Up4un0bu2mm+Ee0EZLlTlZpDubbPW/FW/TQRXsGBfFIFgVRG3F457udWlikJ7IzbP7nJUoaoygEJk7KKYgS7m26FQLDnBtl0x5YpKazUnGdWZcMZ2HDJF94Vp5xygUEfXjD9rwCvfdzVq5NHU1hI/sCi2D2zpeJxPqn1+7ClhGBSxpJQDzAmY7RmH7TSFy/xYKWtu3nmNImuplaU4d0bWwXnDdlUb5ZgQxnfQtemF13cytNs/RQEEQX689LAg9aZ8ZF+0oVPrlwe+A1ZUwxrSfnPBXzcRencK+5Zj0VvXvGqPZYXKNOjtEcSFmqGEyWYbeHUE1waBgV9DGIjYLaA2AOMZ47SJgA9KbO6Nb/b4Zn8Cr9+H2zW4FFGHs76N6PbBMDJlZS6AawUZEum4cAOr2jVOxnK9IP1qK2P10mipcWn+MXxI7dN9bxoV8TfOeo34vDAyUGeGMRf98+T8HyIaNqR/DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';