import { RichText } from '@ads-core/components';
import { useIsConditionallyHidden } from '../hooks';
import { PropsWithConditions } from '../types';

type RichTextProps = PropsWithConditions<{ field: { model: { richTextField: string } } }>;

export const RichTextFieldMapper = ({ field }: RichTextProps) => {
  const { fieldKey } = field.model.conditionSettings;
  const isHidden = useIsConditionallyHidden({ fieldKey });

  if (isHidden) return null;

  return <RichText>{field.model.richTextField}</RichText>;
};
