import { FormField, TextViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { FieldWithValueProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { Heading, Text } from '@ads-core/components';
import { isHeadingSize } from '../utils/utils';
import { useIsConditionallyHidden } from '../hooks';
import { PropsWithConditions } from '../types';

type TextFieldProps = PropsWithConditions<FieldWithValueProps<FormField<TextViewModel>, string>>;

export const TextFieldMapper = ({ field }: TextFieldProps) => {
  const component = field.model.htmlTag as keyof JSX.IntrinsicElements;
  const { text, cssClass } = field.model;
  const { fieldKey } = field.model.conditionSettings;

  const isHidden = useIsConditionallyHidden({ fieldKey });
  if (isHidden) return null;

  if (isHeadingSize(component)) {
    return (
      <Heading size={component} className={cssClass} isResponsive>
        {text}
      </Heading>
    );
  }

  return (
    <Text className={cssClass} as={component}>
      {text}
    </Text>
  );
};
