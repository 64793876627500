import type { PreviewSearchInitialState } from '@sitecore-search/react';
import { WidgetDataType, usePreviewSearch, widget } from '@sitecore-search/react';
import type { PreviewSearchActionProps } from '@sitecore-search/widgets';
import { ChevronRightIcon } from '@ads-core/icons';
import { Stack, Box, TextLink, NavLink, Text } from '@ads-core/components';
import { SearchInputSuggestions } from 'src/components/SearchInput';
import React from 'react';
import { debounce } from '@sitecore-search/common';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';
import { queryParams } from './MeterManuals';

type ArticleModel = {
  id: string;
  name: string;
  image_url: string;
  url: string;
  source_id?: string;
};
const Articles = ({
  loading = false,
  articles,
}: {
  loading?: boolean;
  articles: Array<{ term: string; url: string }>;
  onItemClick: PreviewSearchActionProps['onItemClick'];
}) => {
  return (
    <>
      {loading ? <div>Laden..</div> : null}
      <Stack direction={'column'}>
        {!loading &&
          articles.map((article) => (
            <Box paddingBottom={2} asChild key={article.url}>
              <NavLink href={article.url} beforeIcon={<ChevronRightIcon size="sm" />} asChild>
                <InternalOrExternalLink>{article.term}</InternalOrExternalLink>
              </NavLink>
            </Box>
          ))}
      </Stack>
    </>
  );
};

type InitialState = PreviewSearchInitialState<'itemsPerPage' | 'keyphrase' | 'suggestionsList'>;

export const PreviewSearchBasicComponent = ({ defaultKeyphrase = '', defaultItemsPerPage = 8 }) => {
  const router = useRouter();
  // These are defined in the backend
  const suggestionsList = [{ suggestion: 'multiword_autocomplete', max: 5 }];

  const {
    widgetRef,
    state: { keyphrase },
    actions: { onItemClick, onKeyphraseChange },
    queryResult: { isFetching, isSuccess, data: { suggestion } = {} },
    query,
  } = usePreviewSearch<ArticleModel, InitialState>({
    query: (q) => {
      q.isEnabled = () => false;
    },
    state: {
      keyphrase: defaultKeyphrase,
      itemsPerPage: defaultItemsPerPage,
      suggestionsList,
    },
  });

  // Only show results of the suggestion type that has results.
  // The order is determined by our definition of suggestionsList
  const suggestionOrder = suggestionsList.map((s) => s.suggestion);
  const foundSuggestionType = suggestion
    ? suggestionOrder.find((s) => suggestion?.[s]?.length > 0)
    : null;
  const foundSuggestion = foundSuggestionType ? suggestion?.[foundSuggestionType] || [] : [];

  const keyphraseHandler = debounce((e: React.ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    query.isEnabled = () => true;
    onKeyphraseChange({
      keyphrase: target.value,
    });
  }, 400);

  const suggestionData = foundSuggestion.map((d) => {
    const term = d.text.trim() + ' (' + d.freq + ' resultaten)';
    return {
      term,
      url: `/zoeken?${queryParams.term}=${d.text}`,
    };
  });

  return (
    <Box padding={7}>
      <div ref={widgetRef}>
        <>
          <Box>
            <SearchInputSuggestions
              name="previewSearch"
              onChange={keyphraseHandler}
              aria-label="zoek"
              onSubmit={(e) => {
                e.preventDefault();
                // We use the previewSearch input name to get the value from the form event, because keyphrase takes too long to update and users press enter immediately
                // @ts-ignore we cant type Target properly
                router.push(`/zoeken?${queryParams.term}=${e.target.previewSearch.value}`);
              }}
            />
          </Box>
          {isFetching ? <div>Laden..</div> : null}
          <Box>
            {!isFetching && (
              <div ref={widgetRef}>
                <ul>
                  <li key="defaultArticlesResults">
                    {suggestionData.length > 0 && isSuccess && keyphrase && (
                      <Text size="paragraph">
                        Suggesties voor &apos;{keyphrase}&apos;. Soortgelijke zoektermen:
                      </Text>
                    )}
                    <Articles articles={suggestionData} onItemClick={onItemClick} />
                    {suggestionData.length === 0 && isSuccess && keyphrase && (
                      <Text size="paragraph">Geen resultaten gevonden</Text>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </Box>
        </>

        {keyphrase ? (
          <Box paddingTop={6}>
            <TextLink asChild>
              <NextLink href={`/zoeken?term=${keyphrase}`}>Bekijk alle resultaten</NextLink>
            </TextLink>
          </Box>
        ) : null}
      </div>
    </Box>
  );
};
const PreviewSearchBasicWidget = widget(
  PreviewSearchBasicComponent,
  WidgetDataType.PREVIEW_SEARCH,
  'content'
);

export default PreviewSearchBasicWidget;
