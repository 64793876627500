import React from 'react';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Tag } from '../Tag';
import { ButtonLink } from '../ButtonLink';
import { DictionaryDialog } from '../DictionaryDialog';
import { CombineProps } from '../../types/type-helpers';

type DialogueProps = {
  tag?: string;
  text?: string;
  button?: React.ReactElement;
};

type DialogueSitecoreProps = {
  tag?: React.ReactElement;
  text?: React.ReactElement | string;
  button?: React.ReactElement;
};

type DialogueViewProps = CombineProps<DialogueProps, DialogueSitecoreProps>;

export const DialogueSitecore = (props: DialogueSitecoreProps) => (
  <DialogueView {...props} />
);
export const Dialogue = (props: DialogueProps) => <DialogueView {...props} />;

export const DialogueView = ({ tag, text, button }: DialogueViewProps) => {
  return (
    <section>
      {tag ? (
        <Box paddingBottom={{ initial: 10, md: 12 }}>
          <Tag>{tag}</Tag>
        </Box>
      ) : null}
      {text ? (
        <Heading size="h3" as="h2">
          <DictionaryDialog>{text}</DictionaryDialog>
        </Heading>
      ) : null}
      {button ? (
        <Box paddingTop={{ initial: 12, md: 16 }}>
          <ButtonLink asChild>{button}</ButtonLink>
        </Box>
      ) : null}
    </section>
  );
};
