import React from 'react';
import { RadioButton, RadioGroup } from '@ads-core/components';
import { ListFieldItem } from '@sitecore-jss/sitecore-jss-forms';
import { ListFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext, Controller } from 'react-hook-form';
import { getOptions } from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions } from '../types';

type Props = PropsWithConditions<ListFieldProps>;

export const RadioGroupFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, setValue, control } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);

  const error = extractString(formState.errors[name]?.message);
  const defaultValue = field.model.items.find((item) => item.selected)?.value;

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  React.useEffect(() => {
    // Because the default value of the radioGroup is a array we set it to a string
    if (typeof getValues(name) !== 'string') {
      setValue(name, defaultValue ? defaultValue : '');
    }
  }, [defaultValue, getValues, name, setValue]);

  if (isHidden) return null;

  const options = getOptions(field, ['required']);

  return (
    <Controller
      name={name}
      control={control}
      rules={options}
      render={(props) => {
        return (
          <RadioGroup
            isOptional
            error={error}
            label={getLabel(field.model.title, !!options.required)}
            name={props.field.name}
            ref={props.field.ref}
            onValueChange={(v) => {
              props.field.onChange(v);
            }}
            defaultValue={defaultValue} // This is only visually. The initial values for the form are set in the useForm
          >
            {field.model.items
              .filter((item): item is Required<ListFieldItem> => !!item.text)
              .map((item) => {
                return (
                  <RadioButton
                    value={item.value}
                    key={item.value}
                    onFocus={() => tracker.onFocusField(field, getValues(name))}
                    onBlur={() =>
                      tracker.onBlurField(field, getValues(name), error ? [error] : undefined)
                    }
                    className={field.model.cssClass}
                  >
                    {item.text}
                  </RadioButton>
                );
              })}
          </RadioGroup>
        );
      }}
    />
  );
};
