import { Textarea } from '@ads-core/components';
import { MultiLineStringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { getOptions } from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions } from '../types';

type Props = PropsWithConditions<ValueFieldProps<MultiLineStringInputViewModel>>;

export const TextareaFieldMapper = ({ tracker, field }: Props) => {
  const { formState, getValues, register } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const error = extractString(formState.errors[name]?.message);
  const options = getOptions(field);
  const methods = register(name, options);

  return (
    <Textarea
      error={error}
      tone="onLight"
      label={getLabel(field.model.title, !!options.required)}
      className={field.model.cssClass}
      rows={field.model.rows}
      minLength={field.model.minLength}
      maxLength={field.model.maxLength}
      placeholder={field.model.placeholderText}
      {...methods}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
    />
  );
};
