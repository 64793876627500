import React from 'react';
import clsx from 'clsx';
import * as styles from './Divider.css';

type DividerProps = styles.DividerVariants & { className?: string };
export const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
  ({ tone = 'onLight', className = '' }, ref) => {
    const classNames = styles.divider({ tone });
    return <hr className={clsx(classNames, className)} ref={ref} />;
  }
);
