import React from 'react';
import * as styles from './ColoredSection.css';

type ColoredSectionProps = styles.ColoredSectionVariants & {
  children: React.ReactNode;
};

export const ColoredSection = ({ children, color }: ColoredSectionProps) => {
  const cntClassName = styles.section({ color });
  return (
    <section className={cntClassName}>
      <div className={styles.innerSection}>{children}</div>
    </section>
  );
};
