import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import * as styles from '../styles/Form.css';
import { encodeNameToReactHookFormFormat } from '../utils/utils';

export const HiddenInputFieldMapper = ({ field }: ValueFieldProps) => {
  const { register } = useFormContext();
  const name = encodeNameToReactHookFormFormat(field.valueField.name);

  return (
    <input type="hidden" id={field.model.name} {...register(name)} className={styles.hiddenInput} />
  );
};
