import React, { InputHTMLAttributes } from 'react';
import { InputBase, InputBaseProps } from '../InputBase';

/** Allowed autocomplete values for InputEmail component */
type InputEmailAutocompleteAttribute = 'email' | 'username' | 'off';

interface InputEmailProps extends InputBaseProps {
  /**
   * Custom `autoComplete` attribute specifically for the email input. More info on when to
   * use a certain value can be found here:
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
   */
  autoComplete: InputEmailAutocompleteAttribute;
}

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
  InputEmailProps;

export const InputEmail = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return <InputBase type="email" ref={ref} {...props} />;
  }
);
