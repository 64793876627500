export const requiredFieldPattern = (fieldName: string | undefined) => {
  if (fieldName) {
    return {
      value: true,
      message: `"${fieldName}" is een verplicht veld.`,
    };
  }

  return {
    value: true,
    message: 'Dit veld is verplicht',
  };
};