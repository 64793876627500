import { ArrowRightIcon } from '../../../icons/index';
import { ButtonLink } from '../../ButtonLink';
import { Heading } from '../../Heading';
import { RichText } from '../../RichText';
import { Stack } from '../../Stack';
import { Box } from '../../Box';
import { Conclusion } from '..';

export const WizardSimpleConclusion = (conclusion: Conclusion) => {
  return (
    <Stack gap={8} asChild>
      <Box bg="containerPrimary" width="100%" padding={{ initial: 10, md: 20 }}>
        <Heading size="h5" as="h3" color="onDark">
          {conclusion.title}
        </Heading>
        <RichText tone="onDark">{conclusion.text}</RichText>
        <Box paddingTop={4}>
          <Stack direction="row" gap={2} wrap={true}>
            {conclusion.primaryButton.href ? (
              <ButtonLink
                variant="secondary"
                href={conclusion.primaryButton.href}
                afterIcon={<ArrowRightIcon size="xs" />}
              >
                {conclusion.primaryButton.text}
              </ButtonLink>
            ) : null}
            {conclusion.secondaryButton.href ? (
              <ButtonLink
                variant="ghostOnDark"
                href={conclusion.secondaryButton.href}
              >
                {conclusion.secondaryButton.text}
              </ButtonLink>
            ) : null}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
