import React from 'react';
import type { TextField, ImageFieldValue, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Box, InfoSectionSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';

type InfoSectionSitecoreProps = ComponentProps & {
  fields: {
    title: TextField;
    articles: Array<{
      fields: {
        tag: TextField;
        shortTitle: TextField;
        button: LinkField;

        infoImage: {
          value: ImageFieldValue & { height: string; width: string; alt: string };
        };
      };
    }>;
  };
  params?: {
    anchor?: string;
  };
};

const InfoSection = (props: InfoSectionSitecoreProps) => {
  const siteCoreArticles = props.fields.articles.map((article) => {
    const { button, shortTitle } = article.fields;
    const buttonTitle = shortTitle.value?.toString();

    return {
      title: renderJssLink(
        { ...button, value: { ...button.value, text: buttonTitle } },
        <Box position="relative" zIndex="1" as="span">
          {buttonTitle}
        </Box>
      ),
      tag: <SitecoreText field={article.fields.tag} editable tag="span" />,
      infoImage: <SitecoreImage field={article.fields.infoImage} editable />,
      link: article.fields.button.value.href ? (
        <NextLink href={article.fields.button.value.href} />
      ) : undefined,
    };
  });

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <InfoSectionSitecore
        title={<SitecoreText field={props.fields.title} editable />}
        articles={siteCoreArticles}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(InfoSection);
