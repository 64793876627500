import { IntegrationApi } from '@alliander-fe/api';
import { useParams } from 'src/hooks/useParams';
import { useQuery } from '@tanstack/react-query';
import { rewriteEnergyType } from 'src/utils';
import { Box, GridItem, Heading, PageGrid, Text, TextLink } from '@ads-core/components';
import { OutageView } from './_View';
import { OutageSpecific } from './_OutageSpecific';
import { Props } from '.';

export function OutageGeneral(props: Props) {
  const { reference, postalCode, houseNumber, houseNumberAddition } = useParams();

  const query = useQuery({
    enabled: Boolean(reference),
    queryKey: [reference],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetSpecificOutage({
        outageNumber: reference,
      }),
    select: (data) => {
      return {
        ...data,
        energyType: rewriteEnergyType(data.energyType),
      };
    },
  });

  if (!query.isFetched) return null;

  if (query.data && Object.values(query.data).filter(Boolean).length) {
    return (
      <OutageView
        {...props}
        outageData={query.data || {}}
        isSuccess={query.isSuccess}
        isLoading={query.isLoading}
      />
    );
  }

  // In case the reference number is wrong we try to fallback on the OutageSpecific.
  if (postalCode && houseNumber) {
    return (
      <OutageSpecific
        postalCode={postalCode}
        houseNumber={houseNumber}
        addition={houseNumberAddition}
        {...props}
      />
    );
  }

  // Fallback when everything fails.
  return (
    <PageGrid>
      <GridItem columnStart="1" columnEnd="-1">
        <Box paddingBlock={10} width="100%">
          <Box paddingBottom={3} asChild>
            <Heading size="h2" align="center">
              We hebben geen gegevens kunnen vinden.
            </Heading>
          </Box>
          <Text size="paragraph" align="center">
            Controleer uw gegevens of bekijk het{' '}
            <TextLink href="/storingen-en-onderhoud/opgeloste-storingen">
              overzicht van alle opgeloste storingen
            </TextLink>
            .
          </Text>
        </Box>
      </GridItem>
    </PageGrid>
  );
}
