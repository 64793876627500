import React from 'react';

import { Box, GridItem, Grid, Stack, IconButton } from '../';
import { GridColumn } from '../GridItem';
import { CloseIcon } from '../../icons';
import { mq, ResponsiveValue } from '../../global/breakpoints';
import { useMedia } from '../../hooks';

import * as styles from './CTABlock.css';

type ContentImageBlock = {
  children?: React.ReactNode;
};

export const CTABlock = ({ children }: ContentImageBlock) => {
  return (
    <Box width="100%" borderRadius="brandXl" overflow="hidden" asChild>
      <Grid columns={12} gap={0}>
        {children}
      </Grid>
    </Box>
  );
};

type ColumnStyles = {
  columnStart?: ResponsiveValue<GridColumn>;
  columnEnd?: ResponsiveValue<GridColumn>;
};

type ColumnProps = {
  children?: React.ReactNode;
  variant?: 'main' | 'side';
  bg?: 'containerPrimary' | 'containerSecondary';
} & ColumnStyles;

const Column = ({ children, variant, bg, ...column }: ColumnProps) => {
  if (!children) return null;

  const isMain = variant === 'main';

  return (
    <GridItem {...column}>
      <Box
        width="100%"
        paddingTop={isMain ? { initial: 10, md: 20 } : undefined}
        paddingBottom={isMain ? { initial: 10, md: 20 } : undefined}
        paddingInline={isMain ? { initial: 8, md: 20 } : undefined}
        bg={bg}
      >
        {children}
      </Box>
    </GridItem>
  );
};

type NotificationProps = {
  children?: React.ReactNode;
  handleOnClose?: () => void;
  arrowColor?: 'containerPrimary' | 'containerSecondary';
};

const Notification = ({
  children,
  handleOnClose,
  arrowColor,
}: NotificationProps) => {
  return (
    <>
      <NotificationArrow arrowColor={arrowColor} />
      <Stack isFullHeight isFullWidth alignX="center" alignY="center">
        <Box
          bg={children ? 'backgroundLight' : undefined}
          padding={10}
          width={{ initial: '100%', md: undefined }}
          borderRadius={{ initial: 'none', md: 'brandXl' }}
          position="relative"
        >
          {children && handleOnClose ? (
            <Box top="8px" right="8px" position="absolute">
              <IconButton
                onClick={handleOnClose}
                icon={<CloseIcon />}
                label="Sluit"
                tone="onLight"
                shape="brand"
                size="small"
                variant="transparant"
              />
            </Box>
          ) : null}
          {children}
        </Box>
      </Stack>
    </>
  );
};

const NotificationArrow = ({
  arrowColor,
}: {
  arrowColor?: 'containerPrimary' | 'containerSecondary';
}) => {
  return (
    <Box
      width={{ initial: '24px', md: '40px' }}
      height={{ initial: '24px', md: '40px' }}
      left={{ initial: '50%', md: '0%' }}
      top={{ initial: '0%', md: '50%' }}
      position="absolute"
      bg={arrowColor}
      className={styles.notificationArrow}
      zIndex="3"
    />
  );
};

type ImageContainerProps = {
  overlay?: React.ReactNode | null;
  children?: React.ReactNode;
};

const ImageContainer = ({ overlay, children }: ImageContainerProps) => {
  const isDesktop = useMedia(mq.md);

  if (isDesktop) {
    return (
      <Box width="100%" height="100%" position="relative">
        <Box
          bg={overlay ? 'backgroundBackdrop' : undefined}
          position="absolute"
          padding={20}
          zIndex="1"
          asChild
        >
          <Stack isFullHeight isFullWidth alignX="center" alignY="center">
            {overlay}
          </Stack>
        </Box>
        {children}
      </Box>
    );
  }

  if (overlay) {
    return (
      <Box
        bg={overlay ? 'backgroundBackdrop' : undefined}
        zIndex="1"
        width={'100%'}
        position="relative"
        asChild
      >
        <Stack isFullHeight isFullWidth alignX="center" alignY="center">
          {overlay}
        </Stack>
      </Box>
    );
  }

  return children;
};

const Image = ({
  children,
  ...imageProps
}: { children?: React.ReactNode } & styles.ImageVariants) => {
  return (
    <Box
      width="100%"
      height="100%"
      className={styles.image(imageProps)}
      asChild
    >
      {children}
    </Box>
  );
};

CTABlock.Image = Image;
CTABlock.ImageContainer = ImageContainer;
CTABlock.Notification = Notification;
CTABlock.NotificationArrow = NotificationArrow;
CTABlock.Column = Column;
