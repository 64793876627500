import React from 'react';
import {
  LinkField,
  LinkFieldValue,
  RichTextField,
  TextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import {
  FilterValuePropertyFromFields,
  mapWithoutValueProperty,
} from 'src/utils/mapWithoutValueProperty';
import { renderJssLink, SitecoreText } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';
import {
  DictionaryDialog,
  GridItem,
  Heading,
  PageGrid,
  RichText,
  Stack,
  TopTasksSitecore,
} from '@ads-core/components';

export type CheckTaskProps = ComponentProps & {
  fields?: {
    title?: TextField;
    text?: RichTextField;

    taskOneTitle?: TextField;
    taskOneText?: RichTextField;
    taskOneLink?: LinkField;

    taskTwoTitle?: TextField;
    taskTwoText?: RichTextField;
    taskTwoLink?: LinkField;

    taskThreeTitle?: TextField;
    taskThreeText?: RichTextField;
    taskThreeLink?: LinkField;
  };
};

export type FilteredCheckTaskProps = FilterValuePropertyFromFields<CheckTaskProps['fields']>;

const TasksSection = (props: CheckTaskProps) => {
  if (props.fields) {
    const { title, text, ...topTasks } = mapWithoutValueProperty(props.fields);
    const isEditor = props.fields.text?.editable;

    // Render the experience editor html in the RichText if its available
    const richText = props.fields.text?.editable || text;

    const mappedTaks = ({
      title,
      text,
      link,
    }: {
      title?: string;
      text?: string;
      link?: LinkFieldValue;
    }) => {
      return {
        link: renderJssLink({
          ...link,
          value: { ...link, text: title?.toString() },
        }),
        text: text ? <RichText tone="onDark">{text}</RichText> : undefined,
        arrowLink: link?.href ? <NextLink href={link.href} aria-label={link.text} /> : null,
        mobileVariant: 'column',
      };
    };

    const mappedTopTasks = () => {
      const tasks = [];

      if (topTasks?.taskOneLink && topTasks?.taskOneTitle) {
        tasks.push(
          mappedTaks({
            title: topTasks.taskOneTitle?.toString(),
            text: topTasks.taskOneText?.toString(),
            link: topTasks.taskOneLink,
          })
        );
      }

      if (topTasks?.taskTwoLink && topTasks?.taskTwoTitle) {
        tasks.push(
          mappedTaks({
            title: topTasks.taskTwoTitle?.toString(),
            text: topTasks.taskTwoText?.toString(),
            link: topTasks.taskTwoLink,
          })
        );
      }

      if (topTasks?.taskThreeLink && topTasks?.taskThreeTitle) {
        tasks.push(
          mappedTaks({
            title: topTasks.taskThreeTitle?.toString(),
            text: topTasks.taskThreeText?.toString(),
            link: topTasks.taskThreeLink,
          })
        );
      }

      return tasks.filter(
        (
          t
        ): t is {
          link: React.ReactElement;
          arrowLink: React.ReactElement;
          text: React.ReactElement;
          mobileVariant: 'row' | 'column';
        } => !!t.link
      );
    };

    return (
      <PageGrid>
        <GridItem asChild>
          <Stack gap={10}>
            <Stack gap={6}>
              {title ? (
                <Heading size="h1" as="h2">
                  <DictionaryDialog>
                    {isEditor ? (
                      <SitecoreText field={props.fields.title} editable />
                    ) : (
                      title?.toString() ?? ''
                    )}
                  </DictionaryDialog>
                </Heading>
              ) : null}
              {richText ? <RichText>{richText}</RichText> : null}
            </Stack>
            {mappedTopTasks().length ? <TopTasksSitecore tasks={mappedTopTasks()} /> : null}
          </Stack>
        </GridItem>
      </PageGrid>
    );
  }

  return null;
};

export default withDatasourceCheck()<CheckTaskProps>(TasksSection);
