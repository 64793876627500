import React from 'react';
import { NavLink, GridItem, Stack, PageGrid, StackItem, Text } from '../';

import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';

import { ArrowLeftIcon } from '../../icons/index';
import { Box } from '../Box';
import { ToneVariants } from '../ToneFocusProvider';
import * as styles from './Breadcrumb.css';

type Breadcrumb =
  | { link: React.ReactElement; isActive: false }
  | { link: string; isActive: true };
export type BreadcrumbProps = { links: Array<Breadcrumb> } & ToneVariants;

export const Breadcrumb = (props: BreadcrumbProps) => {
  const isDesktop = useMedia(mq.md);
  const secondToLastItem = props.links[Math.max(props.links.length - 2, 0)];

  return isDesktop ? (
    <PageGrid>
      <Box asChild paddingTop={5}>
        <GridItem columnStart="1" columnEnd="-1">
          {props.links.map((breadcrumbLink) =>
            breadcrumbLink.isActive ? (
              <Text size="description" key={breadcrumbLink.link}>
                {breadcrumbLink.link}
              </Text>
            ) : (
              <React.Fragment key={breadcrumbLink.link.props.children}>
                <NavLink
                  className={styles.link}
                  tone={props.tone}
                  isCurrent={breadcrumbLink.isActive}
                  asChild
                >
                  {breadcrumbLink.link}
                </NavLink>
                {!breadcrumbLink.isActive ? (
                  <Box asChild paddingLeft={2} paddingRight={2}>
                    <span>/</span>
                  </Box>
                ) : null}
              </React.Fragment>
            )
          )}
        </GridItem>
      </Box>
    </PageGrid>
  ) : (
    /** This className is used for hiding it on the searchpage*/
    <PageGrid className="mobile-breadcrumb-container">
      <Box asChild paddingTop={5}>
        <GridItem columnStart="1" columnEnd="-1">
          <Stack
            direction="row"
            alignX="center"
            isFullWidth
            className={styles.mobileBreadcrumb}
          >
            <StackItem shrink>
              <ArrowLeftIcon className={styles.backArrow} size="xs" />
            </StackItem>
            <NavLink className={styles.link} tone={props.tone} asChild>
              {secondToLastItem.link}
            </NavLink>
          </Stack>
        </GridItem>
      </Box>
    </PageGrid>
  );
};
