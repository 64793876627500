import { ValidationDataModel } from '@sitecore-jss/sitecore-jss-forms';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { validationModels } from '../utils';

type Field = {
  model: {
    required: boolean;
    title: string;
    minLength?: number;
    maxLength?: number;
    min?: number | string;
    max?: number | string;
    validationDataModels: Array<ValidationDataModel>;
  };
};

type AllowedKeys = Exclude<
  keyof Field['model'],
  'title' | 'validationDataModels'
>;

export function isRequiredField(field: Field) {
  // The required option comes from a custom validationModel, because Sitecore can't handle required conditional fields on its own
  return (
    field.model.validationDataModels.some(
      (v) => v.itemId === validationModels.CONDITIONALLY_REQUIRED
    ) || field.model.required
  );
}

const condition = <V extends number | string | boolean>(
  value: V | undefined,
  message: string
) => (value ? { value, message } : undefined);

export const getOptions = (
  /** The field that needs to be validated */
  field: Field,
  /** If you only want a subset of validation keys, you can pass an array of those keys here */
  include?: AllowedKeys[]
): Pick<RegisterOptions<FieldValues, string>, AllowedKeys> => {
  const { min, max, minLength, maxLength } = field.model;

  const required = isRequiredField(field);

  const options = {
    required: condition(required, `Dit veld is verplicht`),
    min: condition(min, `Minimale waarde is ${min}`),
    max: condition(max, `Maximale waarde is ${max}`),
    minLength: condition(minLength, `Minimale lengte is ${minLength}`),
    maxLength: condition(maxLength, `Maximale lengte is ${maxLength}`),
  };

  if (include) {
    return include.reduce(
      (acc, key) => ({ ...acc, [key]: options[key] }),
      {}
    ) as Pick<RegisterOptions<FieldValues, string>, AllowedKeys>;
  }

  return options;
};
