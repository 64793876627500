import React from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import clsx from 'clsx';
import { useInputIds } from '../../hooks/useInputIds';
import { Stack, Text } from '..';
import { CheckmarkIcon } from '../../icons/index';
import { Error, InputBaseOwnProps } from '../InputBase';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './Checkbox.css';

type Props = Omit<InputBaseOwnProps, 'name'> &
  RadixCheckbox.CheckboxProps &
  styles.CheckboxVariants;

export const Checkbox = React.forwardRef<HTMLButtonElement, Props>(
  ({ label, error, className, defaultChecked, tone, ...restProps }, ref) => {
    const { errorId, inputId } = useInputIds({
      error,
    });

    return (
      <React.Fragment>
        <Stack direction="row" gap={3}>
          <ToneFocus tone={tone}>
            <RadixCheckbox.Root
              ref={ref}
              defaultChecked={defaultChecked}
              id={inputId}
              className={clsx(styles.checkbox({ tone: tone }), className)}
              {...restProps}
            >
              <RadixCheckbox.CheckboxIndicator>
                <CheckmarkIcon size="xs" />
              </RadixCheckbox.CheckboxIndicator>
            </RadixCheckbox.Root>
          </ToneFocus>
          <Text color={tone} asChild>
            <label htmlFor={inputId} className={styles.label}>
              {label}
            </label>
          </Text>
        </Stack>
        {error ? (
          <Error id={errorId} tone={tone}>
            {error}
          </Error>
        ) : null}
      </React.Fragment>
    );
  }
);

Checkbox.displayName = 'Checkbox';
