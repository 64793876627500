import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'src/jss/lib/component-props';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { CongestionCheck } from 'src/components/CongestionCheck';

export type CongestionCheckerProps = ComponentProps & {
  fields?: {
    congestionText?: { value: string };

    feedbackNoCongestionText?: { value: string };
    feedbackNoCongestionPrimaryButton?: { value: ButtonProps };
    feedbackNoCongestionSecondaryButton?: { value: ButtonProps };

    feedbackOnlyConsumeText?: { value: string };
    feedbackOnlyConsumePrimaryButton?: { value: ButtonProps };
    feedbackOnlyConsumeSecondaryButton?: { value: ButtonProps };

    feedbackOnlySupplyText?: { value: string };
    feedbackOnlySupplyPrimaryButton?: { value: ButtonProps };
    feedbackOnlySupplySecondaryButton?: { value: ButtonProps };

    feedbackNoConsumeAndSupplyText?: { value: string };
    feedbackNoConsumeAndSupplyPrimaryButton?: { value: ButtonProps };
    feedbackNoConsumeAndSupplySecondaryButton?: { value: ButtonProps };

    conclusionNoCongestionUpperText?: { value: string };
    conclusionNoCongestionLowerText?: { value: string };
    conclusionNoCongestionFirstPrimaryButton?: { value: ButtonProps };
    conclusionNoCongestionFirstSecondaryButton?: { value: ButtonProps };
    conclusionNoCongestionSecondPrimaryButton?: { value: ButtonProps };
    conclusionNoCongestionSecondSecondaryButton?: { value: ButtonProps };

    conclusionOnlyConsumeUpperText?: { value: string };
    conclusionOnlyConsumeLowerText?: { value: string };
    conclusionOnlyConsumeFirstPrimaryButton?: { value: ButtonProps };
    conclusionOnlyConsumeFirstSecondaryButton?: { value: ButtonProps };
    conclusionOnlyConsumeSecondPrimaryButton?: { value: ButtonProps };
    conclusionOnlyConsumeSecondSecondaryButton?: { value: ButtonProps };

    conclusionOnlySupplyUpperText?: { value: string };
    conclusionOnlySupplyLowerText?: { value: string };
    conclusionOnlySupplyFirstPrimaryButton?: { value: ButtonProps };
    conclusionOnlySupplyFirstSecondaryButton?: { value: ButtonProps };
    conclusionOnlySupplySecondPrimaryButton?: { value: ButtonProps };
    conclusionOnlySupplySecondSecondaryButton?: { value: ButtonProps };

    conclusionNoConsumeAndSupplyUpperText?: { value: string };
    conclusionNoConsumeAndSupplyLowerText?: { value: string };
    conclusionNoConsumeAndSupplyFirstPrimaryButton?: { value: ButtonProps };
    conclusionNoConsumeAndSupplyFirstSecondaryButton?: { value: ButtonProps };
    conclusionNoConsumeAndSupplySecondPrimaryButton?: { value: ButtonProps };
    conclusionNoConsumeAndSupplySecondSecondaryButton?: { value: ButtonProps };
  };
};

const CongestionChecker = (props: CongestionCheckerProps) => {
  if (props.fields) {
    return <CongestionCheck {...props.fields} />;
  }

  return null;
};

export default withDatasourceCheck()<CongestionCheckerProps>(CongestionChecker);
