import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { CookieAcceptBanner, Iframe as IframeComponent } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { useAdsContext } from '@ads-core/providers';
import { CapacityLegend } from 'src/components/CapacityLegend';
import { IframeContentBlockProps } from '@ads-core/types';

type IframeJssResponse = ComponentProps & {
  fields: {
    title: Field<string>;
    source: Field<string>;
    width: Field<string>;
    height: Field<string>;
    secondTitle: Field<string>;
    secondSource: Field<string>;
    secondWidth: Field<string>;
    secondHeight: Field<string>;
    enableCapacityLegend: { value: boolean };
    contentBlockTitle: { value: string };
    contentBlockText: { value: string };
    contentBlockButton: { value: IframeContentBlockProps['button'] };
  };
};

const Iframe = (props: IframeJssResponse) => {
  const adsContext = useAdsContext();
  const {
    secondTitle,
    secondSource,
    secondWidth,
    secondHeight,
    title,
    source,
    width,
    height,
    enableCapacityLegend,
    contentBlockTitle,
    contentBlockText,
    contentBlockButton,
  } = props.fields;
  const isEditor = props.fields.source.editable;
  let contentBlock: IframeContentBlockProps | undefined;

  if (!isEditor && !adsContext.allCookiesAccepted && !adsContext.marketingCookiesAccepted) {
    return <CookieAcceptBanner />;
  }

  const items = [
    { title: title.value, source: source.value, width: width.value, height: height.value },
  ];

  if (contentBlockTitle?.value && contentBlockText?.value) {
    contentBlock = {
      title: contentBlockTitle.value,
      text: contentBlockText.value,
      button: contentBlockButton.value,
    };
  }

  if (secondSource.value) {
    items.push({
      title: secondTitle.value,
      source: secondSource.value,
      width: secondWidth.value,
      height: secondHeight.value,
    });
  }

  return (
    <IframeComponent
      items={items}
      footer={enableCapacityLegend?.value ? <CapacityLegend /> : undefined}
      contentBlock={contentBlock}
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(Iframe);
