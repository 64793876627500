// Couldn't find any node package with dutch country names so i created this one from https://countrycode.org/

export const isoCode2 = ['NL', 'BE', 'DE'] as const;

export type IsoCode2 = (typeof isoCode2)[number];

export type CountryCodesProps = {
  country: string;
  countryCodes: string;
  isoCode2: IsoCode2;
  isoCode3: string;
  areaCodes: string[]
};

export const countryCodeSelection: CountryCodesProps[] = [
  {
    country: 'Nederland',
    countryCodes: '31',
    isoCode2: 'NL',
    isoCode3: 'NLD',
    areaCodes: [
      '010', '0111', '0113', '0114', '0115', '0117', '0118', '013', '015', '0161', '0162', '0164', '0165',
      '0166', '0167', '0168', '0172', '0174', '0180', '0181', '0182', '0183', '0184', '0186', '0187', '020',
      '0222', '0223', '0224', '0226', '0227', '0228', '0229', '023', '024', '0251', '0252', '0255', '026',
      '0294', '0297', '0299', '030', '0313', '0314', '0315', '0316', '0317', '0318', '0320', '0321', '033',
      '0341', '0342', '0343', '0344', '0345', '0346', '0347', '0348', '035', '036', '038', '040', '0411',
      '0412', '0413', '0416', '0418', '043', '045', '046', '0475', '0478', '0481', '0485', '0486', '0487',
      '0488', '0492', '0493', '0495', '0497', '0499', '050', '0511', '0512', '0513', '0514', '0515', '0516',
      '0517', '0518', '0519', '0521', '0522', '0523', '0524', '0525', '0527', '0528', '0529', '053', '0541',
      '0543', '0544', '0545', '0546', '0547', '0548', '055', '0561', '0562', '0566', '0570', '0571', '0572',
      '0573', '0575', '0577', '0578', '058', '0591', '0592', '0593', '0594', '0595', '0596', '0597', '0598',
      '0599', '060', '070', '071', '072', '073', '074', '075', '076', '077', '078', '079', '085', '088', '097'
      ]
  },
  {
    country: 'België',
    countryCodes: '32',
    isoCode2: 'BE',
    isoCode3: 'BEL',
    areaCodes:  [
      '010', '011', '012', '013', '014', '015', '016', '019', '02', '03', '04', '050', '051', '052', '053',
      '054', '055', '056', '057', '058', '059', '060', '061', '063', '064', '065', '067', '068', '069', '071',
      '080', '081', '082', '083', '084', '085', '086', '087', '089', '09',
      ]
  },
  {
    country: 'Duitsland',
    countryCodes: '49',
    isoCode2: 'DE',
    isoCode3: 'DEU',
    areaCodes: [
      '0241', '02408', '07361', '07367', '06120', '034779', '09178', '09873', '09443', '07366', '08380', '07841',
      '04202', '04340', '07166', '05506', '07963', '09195', '06291', '09865', '02691', '08707', '037423', '05154',
      '08207', '04247', '06262', '02561', '02567', '04269', '039775', '02382', '04166', '05609', '06296', '04525', 
      '04556', '04102', '08251', '07565', '08543', '08237', '08343', '034909', '07753', '04835', '06345', '06355',
      '07431', '07435', '07432', '02464', '09085', '05181', '09157', '05464', '05126', '07533', '09176', '08166',
      '034652', '02802', '07444', '02404', '06362', '06631', '034692', '04338', '038859', '033602', '035434',
      '09187', '08704', '02352', '02643', '05328', '05255', '035056', '02505', '039456', '03447', '09675', '039225',
      '033363', '06386', '038391', '02681', '05807', '08295', '06047', '08337', '07453', '09408', '03961', '09307',
      '08705', '08202', '039973', '033337', '033438', '09446', '08468', '08254', '08671', '033457', '07584', '08373',
      '039773', '06023', '06731', '09621', '04132', '08075', '07073', '09373', '08636', '04682', '02632', '07265',
      '06464', '03331', '039363', '02639', '039921', '03971', '05462', '03733', '035385', '06346', '02947', '0981',
      '09802', '02693', '04489', '039001', '04167', '03644', '07805', '039384', '033635', '036081', '07566', '09945',
      '039321', '02931', '02937', '09977', '035200', '036736', '03628', '09363', '08723', '03466', '034222', '09233',
      '02683', '06021', '08052', '08057', '04526', '02593', '02599', '04353', '03473', '04253', '02722', '08168',
      '07357', '08064', '08752', '09335', '03771', '09643', '03744', '037208', '05753', '05752', '09454', '0821',
      '05237', '037291', '04873', '07525', '036626', '04104', '09804', '04941', '04947', '039404', '08095', '08276',
      '06073', '08333', '06743', '09077', '07191', '09405', '08061', '05691', '05696', '04745', '05922', '05924',
      '06343', '036458', '02751', '02759', '02758', '02755', '09273', '02674', '05821', '034465', '08563', '036741',
      '09708', '05824', '037438', '04192', '09741', '07582', '06434', '036871', '038203', '05253', '05259', '034243',
      '06322', '03462', '07726', '037437', '02603', '05624', '02776', '08053', '05472', '08066', '034671', '03344',
      '07136', '08537', '05382', '035023', '08334', '05327', '05322', '08046', '07083', '06621', '08506', '06172',
      '02224', '05403', '05672', '0971', '038423', '08845', '06063', '09761', '034463', '036605', '0671', '02752',
      '02754', '03603', '034635', '034345', '05524', '036961', '035341', '07052', '05252', '02661', '07931', '05042',
      '06708', '02253', '035771', '06032', '05723', '02641', '09771', '05731', '04531', '06052', '07806', '05281',
      '05285', '07264', '07066', '07268', '05037', '08651', '07440', '09564', '05424', '033631', '05523', '07761',
      '05063', '06648', '05222', '03695', '035022', '034925', '07253', '07583', '06124', '04551', '06751', '06196',
      '06056', '05652', '09288', '036461', '038229', '07053', '036041', '08041', '07125', '06101', '07524', '07081',
      '05621', '038791', '07063', '09841', '08247', '07564', '07568', '05626', '04403', '05433', '05345', '039009',
      '07221', '07632', '039422', '02401', '035697', '05358', '033656', '07442', '07449', '07447', '09133', '08340',
      '04446', '05438', '08328', '07433', '04753', '039483', '04939', '02375', '0951', '039298', '04630', '04137',
      '035691', '037347', '04532', '039203', '04857', '04123', '09635', '05442', '05263', '05105', '04499', '04497',
      '039050', '038231', '05806', '033704', '05448', '04766', '035249', '04241', '04248', '04249', '04159', '06452',
      '038462', '06783', '09544', '03591', '05963', '08774', '09925', '0921', '08023', '039062', '06622', '06736', 
      '06757', '09822', '06835', '06832', '02521', '02525', '02272', '05145', '02586', '033204', '039856', '033637',
      '06068', '03366', '05905', '039000', '038356', '08461', '07062', '07348', '034224', '039365', '05406', '033841',
      '04237', '02622', '08857', '039457', '038843', '02204', '036843', '06251', '038379', '09493', '07747', '08462',
      '08460', '08652', '09189', '09293', '036623', '038785', '05435', '03838', '05845', '05051', '037463', '05054',
      '04885', '02271', '033200', '02202', '05734', '033634', '04544', '030', '036452', '07675', '03338', '08860',
      '03471', '04406', '038464', '06531', '09905', '035723', '035874', '05439', '06484', '07143', '06095', '033763',
      '02904', '02905', '036943', '02741', '04138', '09244', '06586', '07466', '04747', '05273', '06826', '07351',
      '08226', '06245', '06569', '08348', '06050', '06409', '06461', '039292', '0521', '05206', '05205', '05823',
      '03337', '07142', '039858', '02543', '06265', '06349', '039036', '09208', '06721', '038393', '09398', '06782',
      '035471', '03303', '06594', '06054', '06668', '06444', '09276', '09772', '06144', '09920', '03594', '07476',
      '039089', '05194', '05402', '09084', '09089', '06561', '03493', '03944', '039829', '036459', '02449', '034659',
      '02697', '06545', '039826', '07344', '07953', '07304', '05852', '05854', '05857', '06555', '036338', '04233',
      '06842', '06844', '05235', '04977', '05236', '033743', '038427', '07702', '033394', '039752', '033984', '04557',
      '06239', '037434', '08234', '038424', '07031', '02871', '0234', '02327', '05067', '05060', '05572', '06135',
      '08745', '09924', '05533', '09434', '07773', '07520', '09422', '08338', '08406', '05471', '05475', '039889',
      '038847', '04394', '04623', '07164', '06526', '02383', '039039', '0228', '07703', '033605', '04393', '07362',
      '06742', '04322', '05643', '05645', '05953', '05425', '05682', '02861', '039777', '033845', '04922', '038234',
      '03433', '02222', '02227', '04323', '033230', '034291', '04276', '04527', '04494', '04268', '02041', '02045',
      '06834', '05593', '05594', '07930', '035774', '02745', '07135', '04401', '05272', '05648', '05461', '05468',
      '037322', '03381', '034292', '08034', '02627', '06442', '05520', '05583', '07707', '07906', '034633', '0531',
      '05307', '02338', '033972', '04334', '03964', '033087', '04671', '06465', '07667', '08054', '06675', '08584',
      '037756', '09495', '08263', '02777', '0421', '0471', '04761', '04767', '04769', '09484', '06161', '07252',
      '07946', '06165', '033837', '033232', '035606', '033607', '033609', '035696', '02961', '02964', '02963', '04266',
      '038204', '02633', '04324', '05833', '04252', '06372', '05226', '07251', '07257', '033844', '08765', '08062',
      '038483', '02232', '039030', '04852', '039742', '07343', '08086', '02678', '04155', '06281', '08378', '06543',
      '04181', '04186', '08241', '05722', '033433', '039224', '06139', '06558', '06042', '06048', '06509', '07223',
      '07226', '07974', '04550', '04754', '06542', '04953', '05223', '06394', '02736', '02951', '02958', '02955',
      '04371', '03921', '04825', '035603', '039603', '08222', '09843', '02636', '05347', '05136', '05085', '09546',
      '036692', '035727', '09552', '06653', '08677', '08432', '08679', '09572', '09471', '09805', '09534', '09356',
      '03724', '09183', '06457', '05139', '05135', '09551', '09734', '035953', '035245', '07475', '07126', '03965',
      '02174', '08285', '033876', '06408', '04834', '04733', '04735', '04736', '036451', '09545', '08274', '09336',
      '036373', '06033', '038461', '033836', '04161', '04777', '09103', '03541', '039291', '05677', '039051', '07051',
      '03866', '036421', '04478', '033209', '06356', '039821', '033331', '02305', '034363', '05141', '05086', '09971',
      '0371', '08664', '033366', '04339', '037202', '05323', '05844', '04471', '034923', '09561', '02671', '02541',
      '02546', '06427', '039207', '034381', '09376', '09803', '04434', '03966', '05156', '05159', '05650', '034903',
      '03523', '0355', '07951', '037342', '07933', '05306', '09270', '036926', '03762', '035324', '03863', '036693',
      '037344', '038794', '035877', '04721', '04722', '04723', '04724', '02743', '038485', '039991', '02193', '08131',
      '09163', '06776', '04667', '02447', '034361', '05851', '035451', '04364', '06391', '039031', '038783', '039922',
      '05491', '04352', '04349', '05861', '033975', '039959', '06151', '08205', '05564', '05562', '038826', '02363',
      '06592', '06466', '06468', '0991', '07555', '07334', '06326', '09184', '07420', '05250', '034202', '06336', '05187',
      '04221', '038488', '03998', '08466', '09855', '07666', '039453', '038793', '036964', '0340', '033974', '04957',
      '05231', '09324', '038228', '05333', '037368', '06071', '02750', '036024', '038822', '05633', '05694', '05775',
      '05777', '05441', '02689', '038226', '035267', '03902', '08807', '07347', '09824', '08565', '08464', '08374',
      '08027', '02774', '06432', '02771', '09071', '036075', '039425', '08731', '09851', '08292', '04443', '02064', 
      '033846', '03504', '08267', '06238', '07327', '05421', '036648', '07156', '03431', '035322', '035600', '034495', 
      '039248', '06595', '04959', '039364', '035329', '08422', '038758', '034223', '038728', '0771', '09403', '0906', 
      '09528', '05265', '08081', '02133', '06436', '036427', '036963', '07455', '07443', '04933', '04963', '04627', 
      '02362', '02866', '02369', '0231', '04742', '04234', '04239', '07937', '04433', '04432', '035609', '04177', 
      '035453', '04704', '033966', '05502', '05445', '035602', '039293', '039857', '02508', '02538', '02387', '0351', 
      '02775', '04143', '04148', '036737', '02763', '038853', '034425', '039726', '06565', '05527', '05185', '0203', 
      '02066', '02065', '02594', '02590', '02548', '03869', '07403', '02421', '07245', '035026', '09856', '034386', 
      '0211', '036020', '06271', '05507', '07358', '09194', '09531', '07163', '037607', '06656', '04765', '03334', 
      '07458', '09553', '06424', '05822', '08709', '06008', '034467', '09126', '04351', '04855', '05176', '05177', 
      '06323', '05623', '04405', '04486', '02675', '07628', '039268', '07429', '04175', '04609', '08721', '039779', 
      '08056', '08544', '08206', '08176', '09197', '08435', '07391', '09835', '07395', '07034', '05377', '06683', 
      '04275', '037341', '034494', '06443', '06449', '09303', '037752', '06246', '039206', '09378', '09952', '09956', 
      '06659', '037430', '08421', '07663', '039297', '07774', '03423', '039409', '05873', '05561', '05565', '037209', 
      '03691', '07657', '06351', '036691', '03364', '03686', '06672', '02243', '039454', '038755', '02673', '04120', 
      '036332', '07961', '07965', '038327', '04121', '06328', '02274', '08753', '04404', '04485', '035383', '036621', 
      '03533', '035793', '09522', '06123', '036200', '07682', '05068', '04134', '04921', '04330', '05943', '04665', 
      '06747', '07641', '05875', '02822', '02828', '05155', '05157', '07465', '07485', '05903', '02572', '09104', 
      '04473', '04295', '09209', '07642', '05926', '02263', '07733', '05224', '06303', '02333', '02524', '02528', 
      '02587', '09624', '02938', '07085', '07404', '06827', '06735', '037293', '07262', '06198', '07305', '06062', 
      '09682', '036946', '08122', '04333', '02235', '0361', '09847', '08771', '08573', '02431', '02435', '08336', '03362',
      '09131', '06183', '034743', '036208', '02753', '07354', '036082', '02943', '039052', '09374', '05142', '09645', '02770',
      '05534', '09948', '05651', '02403', '04971', '09653', '02973', '05772', '05743', '09394', '0201', '05434', '02054',
      '08703', '09447', '07365', '09305', '05955', '04140', '05025', '07822', '09148', '07243', '08249', '09726', '09704',
      '08179', '08208', '02251', '02255', '09350', '04521', '02582', '05262', '05754', '04254', '06267', '033208', '035365',
      '033458', '08727', '09637', '033603', '033986', '034262', '03322', '09462', '03745', '033088', '05162', '05163', '05055',
      '05053', '09392', '039951', '04423', '033932', '08157', '06637', '039831', '07676', '07655', '09420', '08063', '033734',
      '08335', '05662', '09438', '039778', '035363', '09128', '09852', '09272', '07962', '033206', '02395', '02724', '03335',
      '03531', '04265', '08236', '06393', '08028', '06166', '08326', '09829', '09778', '02685', '039054', '04354', '033923',
      '039034', '0461', '04347', '03726', '06734', '06057', '06145', '09603', '07402', '07228', '07220', '09191', '07947',
      '039266', '03562', '08124', '09355', '06455', '06451', '037206', '07959', '037321', '06233', '0335', '069', '06109',
      '034637', '09926', '037326', '02234', '05184', '04149', '033439', '07645', '03731', '04779', '0761', '07664', '06666',
      '04602', '06055', '09646', '08654', '02666', '06353', '06855', '08161', '05355', '09086', '09502', '05902', '033964', 
      '09375', '09627', '02734', '09377', '06582', '07441', '034464', '033967', '09179', '08469', '08551', '08684', '06031', 
      '04465', '04468', '09683', '033767', '06674', '033676', '05504', '039601', '03623', '039487', '06175', '07541', '04854', 
      '04881', '033367', '036254', '05684', '036258', '033235', '08347', '04491', '04493', '04496', '04495', '05622', '034348', 
      '02378', '06426', '07505', '08732', '034741', '08243', '0661', '05607', '033654', '06709', '05901', '033093', '08141', 
      '03361', '039859', '08502', '09973', '06253', '07723', '08362', '08230', '03886', '07225', '07971', '07734', '08458', 
      '038732', '038850', '08766', '07574', '04222', '04223', '02454', '08722', '08735', '038737', '05131', '08634', '03907',
      '04862', '04795', '08821', '04474', '08073', '05846', '033332', '038304', '034299', '039482', '06725', '06733', '09337',
      '036463', '07056', '06045', '05907', '05937', '039397', '04152', '036649', '09254', '036845', '05108', '036783',
      '02451', '036967', '09423', '09556', '08452', '08743', '07704', '07708', '07331', '034341', '038201', '02831', '06051',
      '0209', '08193', '04643', '07267', '09351', '06634', '06765', '06453', '07803', '06727', '03933', '09658', '09172',
      '036253', '05946', '05401', '0365', '07952', '08728', '034783', '09344', '05808', '037382', '07274', '039485', '07224',
      '06258', '09747', '09366', '08445', '06591', '09382', '037203', '035829', '06654', '06843', '07323', '036922', '039887',
      '08744', '04553', '02542', '09201', '02942', '09867', '08238', '04346', '02332', '037346', '09334', '05528', '05339',
      '039957', '07322', '034746', '0641', '05371', '08105', '06573', '06696', '038305', '039342', '02043', '06462',
      '06383', '05426', '035053', '038429', '035265', '06041', '03763', '05592', '05508', '033677', '039606', '034927',
      '08093', '09075', '07684', '038787', '04631', '04124', '04763', '04764', '039971', '02823', '02827', '038308', 
      '036255', '039388', '038736', '04444', '039882', '035435', '035452', '033835', '033472', '07385', '07072', '039200',
      '06745', '07161', '07165', '08302', '039851', '05882', '03581', '039992', '07754', '034975', '033847', '035694',
      '07426', '05321', '05325', '034955', '09358', '034493', '09242', '03621', '0551', '035478', '038206', '07255', '08661',
      '038756', '039926', '08552', '09192', '09357', '034953', '07748', '036205', '036703', '09641', '036785', '08092',
      '08144', '08585', '039749', '038334', '039952', '039861', '03306', '04208', '05357', '08641', '06086', '038720',
      '06646', '06644', '05674', '04383', '08463', '02158', '034781', '02779', '06477', '06478', '033334', '03834', '03661',
      '07624', '038224', '09729', '03636', '02571', '02575', '02181', '02182', '03881', '039362', '033086', '08532', '06155',
      '03437', '038326', '034605', '034976', '035263', '034296', '04562', '04366', '05182', '02562', '02565', '039403',
      '039202', '033239', '038332', '039976', '039883', '05064', '039398', '033201', '038725', '04509', '04224', '033207',
      '05035', '039605', '033478', '05838', '035608', '033983', '039934', '039978', '039294', '035726', '033393', '038788', 
      '04356', '033364', '06152', '06078', '08203', '05604', '033701', '036330', '036077', '034384', '07148', '036781', 
      '06337', '035934', '04943', '04946', '04945', '04327', '04367', '036370', '036022', '03522', '04435', '04487', 
      '036920', '04604', '04775', '036206', '09105', '035954', '037329', '04936', '034446', '06186', '08407', '06026', 
      '035938', '035753', '035952', '06809', '037328', '035841', '06339', '036335', '09766', '033873', '09560', '06401', 
      '06058', '08383', '037294', '033655', '09346', '06359', '07972', '03561', '05603', '04547', '033929', '038844', 
      '02261', '033977', '09073', '06269', '039488', '06249', '08221', '09831', '09836', '05865', '039344', '038486', 
      '039262', '03843', '07685', '037433', '033084', '039247', '05241', '05209', '035932', '038353', '05665', '04286', 
      '08072', '02129', '08535', '02662', '06433', '039408', '04746', '02331', '02337', '02334', '07273', '03883', 
      '035822', '04938', '09664', '035894', '06430', '08556', '02773', '07474', '08133', '06456', '037207', '07456', 
      '03941', '08368', '03904', '08055', '0811', '0345', '05201', '02984', '06557', '02364', '02360', '02353', '04793', 
      '05084', '040', '04332', '05151', '02682', '02381', '02385', '02388', '09732', '06185', '02852', '02856', '06181', 
      '04608', '04872', '033632', '05832', '05541', '0511', '04184', '039406', '09080', '05505', '06283', '05932', '05934', 
      '04244', '036642', '04164', '05247', '02588', '05127', '04195', '09152', '037605', '034328', '039484', '05405', 
      '06574', '04129', '05961', '07832', '039459', '09521', '039884', '06324', '06266', '04482', '04481', '06190', 
      '02324', '05584', '04846', '06467', '06392', '09837', '06673', '07831', '09448', '08026', '08586', '039387', 
      '04603', '02507', '07386', '034658', '07471', '04774', '033451', '05545', '039481', '02568', '04287', '0481', 
      '09177', '06221', '02867', '03529', '07321', '09833', '04320', '06020', '07131', '07554', '033962', '04362', 
      '02056', '03606', '09198', '09872', '02446', '06789', '06092', '02452', '02453', '034772', '034673', '04725', 
      '02482', '02448', '09252', '05351', '05356', '09491', '02372', '05158', '04771', '09901', '036945', '02242', 
      '02248', '033732', '03302', '039038', '04877', '04836', '02367', '04193', '06252', '07586', '07643', '02772', 
      '07324', '06643', '06647', '02330', '02744', '07557', '05221', '08385', '08388', '036333', '06624', '04365', 
      '038378', '05654', '05052', '06503', '035057', '036601', '02323', '09190', '07389', '07032', '09825', '07764', 
      '035873', '08152', '06785', '09151', '02357', '02366', '07276', '05521', '03535', '033926', '05585', '05245', 
      '033434', '05962', '09132', '02406', '02407', '033965', '09135', '039426', '06276', '05602', '05152', '03476', 
      '039745', '06508', '07173', '036761', '034423', '039458', '06104', '06806', '036084', '035266', '02733', '03685', 
      '02103', '06681', '05121', '04256', '08250', '05703', '05373', '06593', '09174', '05409', '07739', '05828', 
      '033089', '04144', '08324', '04925', '035974', '06396', '07652', '04768', '07478', '09543', '09622', '036644', 
      '035343', '035843', '06272', '05862', '05858', '07355', '06231', '09764', '06146', '035939', '06305', '06163', 
      '09193', '09074', '06347', '035055', '06205', '05164', '09281', '06657', '06684', '05671', '06192', '09523',
      '06122', '08545', '07808', '034263', '06446', '04893', '08558', '039394', '09626', '04385', '09472', '05128',
      '038428', '08102', '04826', '039993', '034441', '033872', '08805', '034242', '06676', '033368', '033744',
      '07387', '03723', '08784', '08443', '09946', '04871', '039226', '039824', '04335', '05083', '035975', '04139',
      '02624', '05494', '05062', '04165', '09274', '04187', '06523', '03865', '04950', '06439', '035389', '039853',
      '036379', '08393', '08024', '05531', '05536', '02301', '05681', '06633', '06841', '06848', '04849', '05944',
      '038451', '033982', '05458', '05457', '07451', '07486', '07482', '07483', '08294', '07504', '02687', '035892',
      '05234', '06338', '07833', '05334', '04163', '035698', '039055', '036023', '038333', '04126', '04288', '05459',
      '05978', '05454', '02558', '035756', '06650', '07587', '039405', '05257', '05294', '05271', '05277', '05275',
      '05278', '04251', '03571', '02433', '02192', '04408', '04484', '06268', '08802', '05744', '06652', '06438',
      '06402', '02858', '02429', '02233', '04634', '04841', '08505', '05451', '08223', '08178', '06781', '039390',
      '06126', '07229', '04755', '04929', '04928', '07668', '036331', '07346', '07303', '06825', '07558', '09666',
      '03677', '05172', '039452', '07904', '07462', '05673', '07545', '08323', '08257', '02465', '06132', '0841',
      '08450', '08143', '038300', '02695', '08665', '09323', '09339', '09846', '06525', '06550', '08083', '05374',
      '02371', '02374', '07562', '07569', '02874', '02873', '036425', '02835', '05948', '04821', '039929', '08043',
      '033608', '04454', '04455', '07967', '039885', '05864', '034466', '04607', '035607', '039997', '039741', '039086',
      '034907', '05366', '04958', '04902', '03641', '039087', '039343', '05354', '06695', '034241', '03537', '04183',
      '07745', '08225', '04337', '04461', '033361', '037439', '03773', '08564', '037343', '04673', '039977', '04162',
      '06059', '039003', '04625', '02165', '02164', '036947', '04935', '02461', '07477', '06597', '038466', '039955', '03372',
      '038845', '039347', '06188', '036424', '07184', '02653', '0631', '09099', '039081', '09742', '039080', '05553', '02824',
      '038827', '02441', '05264', '05755', '09473', '04191', '036966', '02307', '03578', '039382', '036028', '06773', '02842',
      '07275', '07626', '04642', '07842', '06039', '038733', '04644', '07202', '038371', '08454', '0721', '09353', '09359',
      '038738', '038797', '033922', '09220', '0561', '06762', '09625', '04501', '05552', '06486', '038373', '06774', '08341',
      '05605', '036733', '038208', '034426', '06049', '07851', '07853', '07854', '039366', '02692', '09441', '06195', '06589',
      '04822', '06107', '07236', '034921', '09642', '038352', '02152', '06786', '0831', '07644', '02833', '035692', '02237',
      '02275', '02273', '02647', '033233', '02832', '0431', '08628', '02359', '02269', '036375', '08467', '08869', '08465',
      '07825', '035052', '08685', '07266', '04302', '07954', '06763', '037602', '09927', '04273', '09928', '038425', '09285',
      '09647', '04135', '06422', '036043', '08266', '07021', '06352', '02723', '09536', '04236', '09428', '08091', '04289',
      '08623', '07661', '06849', '06752', '03592', '06635', '06766', '05337', '08233', '07563', '09306', '09321', '039324',
      '038723', '04668', '039602', '05965', '039724', '039399', '06805', '06536', '09325', '033203', '036372', '038784', '036849',
      '035935', '039854', '036209', '036336', '07742', '035754', '02821', '039327', '09372', '035202', '037467', '039348', '035895',
      '035388', '033396', '03909', '038825', '036484', '09527', '05685', '02607', '0261', '08851', '08858', '039004', '035825',
      '09608', '034344', '09385', '03635', '04128', '0221', '02203', '06440', '035605', '06737', '09340', '03375', '07232',
      '09525', '07328', '035795', '08231', '036738', '02646', '07725', '09207', '035826', '05353', '04180', '08433', '06174',
      '09665', '035021', '035931', '02223', '02244', '036732', '06384', '034691', '09292', '07531', '06501', '09963', '05631',
      '06505', '036025', '07150', '07154', '06566', '038375', '08536', '039607', '03496', '09941', '039746', '036606', '07250',
      '038457', '02826', '036450', '039822', '07576', '06294', '02151', '05563', '035206', '033055', '04824', '034295', '07957',
      '07543', '08029', '02422', '02732', '06358', '039723', '035604', '035028', '038454', '035244', '09261', '06173', '04856',
      '038292', '04624', '02688', '039264', '034920', '038370', '08282', '04508', '04926', '04927', '04923', '08825', '039035',
      '038293', '034261', '09221', '09345', '039008', '09199', '07940', '07944', '09264', '02268', '02207', '06381', '039005',
      '033479', '04762', '06563', '033971', '09498', '038459', '05947', '035436', '05102', '04343', '05485', '04666', '06203',
      '02554', '05232', '04828', '05964', '02621', '06420', '07821', '05174', '07333', '038452', '09904', '09943', '06325',
      '08248', '06206', '06256', '05183', '04773', '09951', '06341', '08191', '034602', '06575', '0871', '06371', '04636',
      '035201', '05326', '04743', '06103', '04707', '07345', '05248', '07905', '07376', '04684', '09164', '02173', '035032',
      '04672', '034497', '06704', '08239', '06184', '039205', '036625', '09101', '04972', '02423', '06447', '038456', '05082',
      '09452', '04329', '04232', '04235', '038780', '038858', '038374', '04472', '04477', '05933', '06405', '034462', '03574',
      '07363', '09343', '05043', '04267', '04153', '035054', '09123', '06093', '08682', '07133', '09072', '07392', '036702',
      '035693', '035722', '06641', '06382', '09186', '07332', '06254', '08394', '06881', '06888', '033604', '035364', '04662',
      '033052', '0491', '04552', '08330', '02566', '036653', '03382', '09820', '05308', '05301', '05309', '05132', '05175', '09427',
      '02175', '06559', '06397', '09120', '03605', '07175', '0341', '034321', '039241', '05447', '05443', '05261', '05266', '05883',
      '05344', '037367', '036027', '037606', '05904', '05481', '08042', '08045', '02721', '02725', '07026', '04363', '08456', '038792',
      '07653', '07152', '05208', '033475', '039088', '09659', '06473', '038754', '036734', '09823', '07561', '07567', '0214', '02171',
      '06404', '06004', '036701', '07227', '09827', '05295', '05292', '05647', '037323', '09571', '05636', '09576', '06454', '037204',
      '07129', '05676', '05023', '05346', '033054', '033671', '034297', '035025', '05483', '05484', '033362', '039721', '033470',
      '04298', '06287', '03722', '06431', '039246', '08382', '05556', '06403', '08381', '06255', '05957', '05725', '02266', '033933',
      '039084', '0591', '07247', '02462', '05027', '033927', '02644', '02923', '02527', '02941', '02945', '02948', '09549', '09505',
      '03585', '036651', '034208', '039245', '039754', '039265', '02605', '07654', '08637', '02246', '05732', '04442', '09352', '035724',
      '039208', '039998', '06406', '035241', '05432', '07336', '04358', '06726', '07172', '07621', '05954', '06872', '07446', '033094',
      '07130', '04744', '04740', '04703', '05741', '03546', '03542', '0451', '04502', '03867', '038855', '038731', '05841', '034492',
      '03544', '03371', '039391', '02351', '039361', '033365', '038821', '038227', '02591', '06670', '07141', '03378', '03874', '09263',
      '037295', '05283', '09607', '036257', '04882', '04131', '02306', '05906', '037383', '03475', '03491', '04381', '038842', '05383',
      '034444', '02677', '038874', '039888', '036454', '0391', '03379', '039032', '09639', '08751', '07903', '09229', '06131', '06134',
      '03994', '039932', '04523', '038824', '08772', '038750', '07246', '07204', '08145', '09955', '08459', '06804', '06803', '06572',
      '0621', '034782', '07144', '06421', '07665', '09906', '03735', '04934', '02264', '05276', '033080', '07544', '07145', '033765',
      '037422', '034205', '036925', '09146', '09326', '09106', '08136', '08392', '08121', '09332', '09391', '08678', '09255', '08342',
      '09087', '09231', '08650', '09574', '02365', '038221', '04851', '038729', '09532', '06853', '02992', '02991', '02993', '02994',
      '04176', '038321', '04255', '06754', '04185', '09097', '07248', '033747', '09735', '036870', '08724', '08764', '07043', '08557',
      '06237', '02651', '02443', '02484', '02256', '02225', '06226', '02982', '04605', '039728', '09566', '03764', '02132', '02150',
      '02159', '07532', '037431', '05304', '03721', '05372', '02354', '02358', '03693', '06753', '03521', '05657', '08271', '04832',
      '05422', '05427', '05428', '05429', '038781', '036453', '09776', '05661', '08331', '02373', '02379', '02652', '07572', '06476',
      '08733', '05437', '036029', '033082', '05931', '05966', '036969', '03461', '09078', '05466', '06861', '06869', '0291', '02903',
      '038351', '039083', '06159', '07575', '07436', '038727', '02556', '039994', '06522', '05452', '06864', '06865', '06868', '02104',
      '07123', '03448', '033968', '09467', '06061', '06066', '033205', '08204', '08025', '039082', '039006', '036924', '039886', '09944',
      '09371', '038328', '08261', '0571', '09406', '039833', '08320', '09279', '09206', '038453', '08262', '08823', '033764', '09961',
      '09633', '03727', '09266', '034325', '039221', '06298', '034244', '06167', '02841', '07174', '02924', '035209', '04646', '038297',
      '04475', '04479', '039928', '04542', '02747', '06029', '06138', '02161', '02166', '09088', '09091', '039774', '02472', '06243',
      '07044', '02602', '08146', '04954', '04924', '09938', '09656', '08761', '08123', '06533', '06105', '05554', '039329', '05555',
      '035207', '06209', '02294', '05664', '06261', '037604', '039222', '07473', '09748', '09083', '02245', '034632', '035893', '06400',
      '06284', '05375', '034362', '07041', '034922', '035342', '08631', '038871', '033056', '09548', '09185', '03601', '06108', '07463',
      '07775', '036645', '037320', '07938', '06534', '02630', '07631', '033606', '037601', '09251', '033432', '033760', '089', '036604',
      '08402', '09166', '06395', '07393', '02680', '09733', '08177', '07381', '07383', '0251', '05192', '02536', '02501', '02533', '02534',
      '02506', '07636', '07763', '08841', '07192', '04546', '06234', '034385', '09433', '09236', '07452', '07459', '04535', '05855',
      '09282', '06857', '08756', '034346', '02604', '08424', '033051', '06772', '03321', '034603', '05625', '03445', '034293', '02601',
      '034461', '039740', '06223', '06263', '06229', '07132', '07127', '04238', '039243', '07340', '05850', '02932', '07337', '033878',
      '09147', '06627', '06485', '034382', '07326', '07308', '035933', '08361', '02738', '02737', '02486', '02440', '02157', '02153',
      '05853', '035473', '04168', '06102', '09638', '04966', '0395', '038294', '08431', '08283', '08507', '038426', '06264', '036202',
      '09203', '07082', '09874', '02927', '03342', '05941', '036928', '04245', '05465', '05195', '04837', '05973', '05493', '09227',
      '02394', '07139', '06677', '07942', '06693', '06564', '08165', '08773', '07025', '03586', '033476', '04974', '09156', '08503',
      '03679', '038841', '04752', '036764', '02620', '07158', '037361', '07467', '06655', '06669', '06236', '039956', '034342', '07528',
      '035951', '039396', '09947', '04664', '09663', '06694', '09465', '02845', '038422', '04968', '033452', '07237', '06507', '037600', 
      '09181', '08639', '04321', '09672', '09439', '09134', '06821', '02735', '02247', '08583', '07807', '03391', '035872', '09234',
      '02131', '02137', '09161', '09445', '09602', '09648', '05032', '036481', '06321', '09568', '033970', '06692', '03596', '04561',
      '05073', '038757', '05034', '06327', '05072', '05036', '03981', '09401', '033474', '039401', '07055', '02631', '033652', '06043',
      '02427', '02425', '02474', '04661', '06136', '035827', '06625', '037325', '06787', '07728', '033741', '02208', '06363', '039204',
      '06127', '036076', '036607', '06082', '07932', '02428', '07233', '05274', '05238', '034604', '033843', '034721', '05021', '03588',
      '09436', '09404', '033877', '034496', '06852', '02696', '06873', '06875', '04931', '08273', '04731', '04932', '09267', '04804',
      '03631', '09779', '04741', '05921', '02596', '04758', '09081', '07838', '05069', '04842', '02573', '05503', '05551', '04392',
      '05436', '02426', '035242', '039995', '039927', '02502', '02509', '02293', '0911', '07022', '06364', '04543', '06728', '06002',
      '06154', '08822', '08824', '08033', '06628', '09529', '09749', '07706', '035875', '09845', '07045', '07258', '08404', '09774',
      '08372', '09503', '07837', '0208', '036842', '06567', '07802', '07364', '09286', '036878', '07375', '036949', '06022', '07423',
      '036465', '04772', '08630', '05724', '08591', '09844', '09937', '07602', '07977', '09167', '09426', '08847', '07973', '08386',
      '08325', '08322', '07546', '06854', '09736', '09245', '06171', '09671', '09677', '06527', '036705', '06744', '05574', '037348',
      '08624', '09331', '07352', '02625', '02553', '04674', '08134', '033369', '06755', '035842', '04756', '039002', '037292', '033742',
      '02522', '02529', '037298', '037421', '034347', '036940', '02368', '05202', '03967', '06723', '09082', '05805', '06348', '0781',
      '09158', '08224', '03624', '037360', '08142', '0441', '04361', '04864', '04683', '02595', '02761', '02962', '06308', '06552', '07804',
      '06133', '034904', '03301', '036423', '06046', '08542', '035755', '06535', '03435', '03949', '0541', '035792', '036462', '02532',
      '04776', '04845', '04847', '03937', '05473', '05476', '034422', '04865', '09932', '08547', '04791', '034973', '05522', '039421',
      '09777', '06242', '07585', '036378', '034600', '034324', '035823', '036426', '035205', '05286', '06301', '04751', '04205', '04293', 
      '04297', '08332', '06639', '06824', '06858', '04483', '04480', '02206', '04336', '08366', '035844', '04207', '07735', '07941',
      '07948', '07259', '039428', '05251', '05293', '05254', '08808', '04803', '09499', '08629', '06583', '035796', '04961', '04962',
      '09143', '039345', '03871', '039349', '039855', '05368', '09492', '03973', '0851', '033336', '05101', '033237', '037432', '033231',
      '033838', '035775', '09241', '09246', '05171', '08803', '035601', '04844', '037381', '039751', '05028', '08856', '03962', '06867',
      '06866', '03876', '08555', '05707', '05704', '05768', '05702', '05705', '08137', '033745', '08686', '033672', '08265', '08441',
      '07302', '08076', '06746', '07445', '08561', '09535', '09481', '07949', '08782', '07240', '08403', '08346', '07231', '09606',
      '07388', '08363', '07552', '06157', '07256', '08550', '06620', '038751', '09409', '09953', '04101', '033335', '06331', '03501',
      '038455', '09204', '03861', '09931', '038735', '036207', '03741', '09144', '08635', '02391', '09654', '07153', '04522', '09636',
      '06588', '08531', '08538', '033601', '02654', '05535', '09093', '09154', '09725', '06658', '08446', '05706', '038307', '03647',
      '09188', '0331', '09243', '08253', '04342', '03386', '03984', '038233', '09644', '09222', '09265', '035386', '039037', '034926',
      '035058', '05742', '05776', '034722', '039923', '08051', '033768', '035327', '035937', '033834', '038856', '03395', '036735', '039242',
      '038323', '06556', '09386', '033436', '06551', '02684', '02238', '035955', '08196', '038301', '033981', '09180', '05431', '03946',
      '034977', '034771', '04106', '033848', '03860', '06407', '034294', '04178', '05765', '03528', '035208', '034978', '02195', '07732',
      '038722', '02865', '033924', '034906', '05771', '09090', '09429', '04307', '038331', '06585', '08657', '033708', '02623', '04848',
      '06651', '07222', '05956', '04402', '036377', '03868', '04504', '03385', '02102', '04541', '05804', '039057', '08035', '06425', '0751',
      '06297', '037327', '039823', '05453', '02257', '02361', '038854', '039341', '02851', '02850', '02857', '038782', '09921', '0941',
      '09402', '034343', '09294', '09283', '05766', '05446', '04876', '033435', '06833', '038872', '06164', '06035', '07534', '035828',
      '03765', '06385', '033437', '08453', '02296', '02265', '04533', '05544', '06162', '036085', '037324', '036422', '08734', '08670',
      '08640', '037364', '02864', '02642', '02228', '036744', '036640', '02191', '07146', '07843', '04331', '02634', '02664', '08434',
      '07159', '036944', '038296', '05165', '08327', '034424', '037435', '07121', '04285', '04952', '04967', '06544', '05242', '04964',
      '02872', '07844', '02226', '02843', '02844', '06764', '02635', '05971', '05975', '07623', '033931', '07242', '02628', '033875',
      '05529', '03821', '038322', '07765', '04328', '09526', '04294', '09442', '07371', '06158', '09354', '03525', '04355', '05244',
      '02944', '035772', '035477', '09365', '039881', '05659', '04708', '05751', '04292', '037757', '039931', '034774', '039328', '03737',
      '06361', '09563', '06074', '06524', '05074', '06106', '09461', '09469', '05746', '02471', '033832', '07300', '038876', '08783',
      '09876', '036844', '08032', '08139', '08582', '05024', '034954', '038325', '07382', '036948', '02183', '06636', '039392', '036602',
      '05109', '08306', '06003', '06007', '05803', '05509', '04158', '06295', '02545', '02547', '07428', '04108', '08031', '06458', '035033',
      '034498', '09238', '02205', '036968', '08367', '034651', '034901', '034672', '09127', '034322', '0381', '07958', '06623', '04261',
      '09171', '034206', '039772', '08384', '06275', '09861', '035891', '09393', '037462', '039744', '034776', '09723', '08039', '036739',
      '08867', '08781', '07472', '07457', '08785', '09302', '09738', '08533', '09338', '034653', '0741', '09976', '037366', '035325', '08369',
      '07183', '033638', '08509', '06722', '03672', '09223', '09828', '05367', '036929', '035752', '09929', '08663', '07272', '06482',
      '02295', '06142', '02952', '02954', '09762', '038223', '036647', '03671', '0681', '06893', '06581', '06831', '06838', '07147', '033989',
      '02574', '038302', '07553', '06578', '05976', '05341', '05300', '04172', '05153', '05186', '05258', '034609', '03901', '038306',
      '039383', '09701', '04422', '034785', '06224', '04702', '03464', '038209', '05582', '09965', '06387', '04858', '04853', '04863',
      '08085', '039722', '05066', '04806', '02583', '05379', '05378', '038392', '04492', '04498', '038295', '04633', '04169', '08104',
      '07777', '07581', '037365', '04639', '034636', '04805', '036766', '02355', '07839', '04563', '04524', '04136', '04564', '05601',
      '05546', '04263', '06293', '037349', '09162', '07394', '07384', '05123', '07356', '04892', '06629', '02853', '09542', '05282',
      '036337', '039455', '09451', '04706', '04749', '06235', '034221', '09868', '07836', '08259', '039320', '034204', '036694', '05335',
      '038875', '06129', '08649', '02445', '02485', '02444', '035773', '03663', '035472', '04303', '04621', '036841', '035361', '07635',
      '06642', '05207', '036371', '036021', '07656', '06661', '09555', '03683', '02972', '02977', '02971', '02974', '02975', '06887', '09474',
      '036782', '06084', '039862', '034491', '05840', '08074', '09153', '03772', '05842', '039267', '07950', '05193', '05199', '05198', 
      '06445', '09908', '039389', '06024', '09903', '07427', '07084', '09775', '07673', '08726', '06228', '03968', '04344', '038828', '08554',
      '035326', '036874', '08192', '03928', '06187', '037464', '06553', '06373', '039852', '035362', '035248', '08861', '039323', '037755',
      '05352', '04348', '09674', '07943', '09978', '09727', '04528', '035474', '07622', '07620', '09857', '06307', '05332', '02555', '07181',
      '06044', '07422', '06698', '08252', '07935', '07936', '07939', '05329', '033083', '07826', '05923', '03844', '09122', '06130', '08138',
      '07171', '0791', '07907', '08232', '08868', '04884', '04277', '07138', '05284', '06691', '02163', '06638', '06630', '07824', '09431',
      '039424', '04209', '04296', '09384', '09170', '04258', '05071', '039827', '09962', '03564', '09284', '09289', '04151', '03774', '05686',
      '09435', '04308', '03332', '06344', '06502', '06342', '09721', '06429', '08444', '02336', '07353', '07720', '07579', '035797', '0385',
      '04257', '02304', '06202', '08082', '039953', '034773', '035971', '06292', '04555', '04545', '08364', '034928', '039386', '039863',
      '039407', '06257', '07823', '036608', '03346', '05137', '08667', '05381', '05384', '08801', '04125', '04105', '07448', '033978', '04357',
      '05138', '037362', '033085', '035930', '09287', '09280', '04384', '02456', '06182', '038303', '02592', '038823', '04284', '06483',
      '02626', '038222', '05336', '07307', '02597', '02598', '02526', '02535', '03573', '033238', '09569', '09567', '09497', '035387', '05065',
      '05305', '03969', '04272', '02778', '02241', '0271', '09444', '08662', '04107', '08258', '06788', '04194', '038724', '07571', '07570',
      '08571', '09954', '09155', '02473', '06761', '035695', '07484', '09848', '07683', '07731', '06665', '06664', '07261', '07260', '04282',
      '036730', '02921', '02928', '05952', '035936', '05129', '05608', '0212', '036695', '09145', '05191', '05190', '05197', '05874', '03634',
      '09333', '03632', '03675', '09562', '07128', '035323', '02838', '07325', '08321', '05653', '04635', '04264', '07424', '09175', '05663',
      '039727', '06562', '09275', '05977', '05225', '033703', '06232', '08553', '07194', '04976', '038726', '07176', '05837', '033633',
      '03563', '06701', '05041', '05045', '05044', '02339', '07672', '07724', '06741', '06771', '06894', '07122', '07669', '07660', '06851',
      '06856', '038207', '04141', '04146', '09504', '04732', '04737', '04734', '035024', '06428', '05721', '03629', '09724', '036965',
      '02563', '05532', '036428', '09225', '09573', '04875', '03329', '09964', '09256', '08405', '09466', '06357', '08151', '03925',
      '035268', '07633', '05543', '039608', '039954', '033874', '05026', '02667', '036762', '06663', '06660', '06667', '036847', '04632',
      '04534', '07627', '05492', '02552', '02551', '08862', '05204', '036704', '07329', '05233', '033636', '08094', '04536', '05148', '04142',
      '035384', '033657', '07738', '04174', '05474', '05745', '05773', '03931', '033969', '08036', '09935', '03847', '04637', '07573', '039244',
      '05764', '05769', '07771', '04505', '04506', '06027', '07964', '05872', '034654', '02402', '02409', '037296', '033338', '035973',
      '05761', '033678', '034445', '08349', '036204', '08345', '034929', '02834', '02839', '03831', '039753', '039489', '09424', '08170',
      '09421', '036875', '035475', '03341', '036376', '035264', '039427', '06724', '035020', '036026', '06775', '04748', '039924', '07744',
      '04206', '033733', '07249', '0711', '036784', '04942', '04641', '05826', '04830', '04663', '04883', '02862', '09165', '03681', '036846',
      '05820', '04537', '04271', '07454', '06028', '07193', '06897', '07976', '09661', '08376', '07634', '09763', '08135', '07269', '09480',
      '02933', '02393', '02934', '02935', '039053', '04965', '04529', '07162', '05939', '04242', '04240', '034742', '04651', '04622', '036259',
      '08622', '08687', '09094', '07464', '04156', '036252', '039007', '03935', '039322', '04109', '08572', '06682', '036646', '037465',
      '09655', '08395', '033333', '09070', '04283', '038450', '04638', '09341', '034298', '033675', '08084', '06128', '035240', '05482',
      '05455', '07629', '08022', '034606', '036743', '036624', '08666', '09923', '036071', '02504', '04838', '03328', '03987', '07736',
      '07729', '035456', '038205', '03996', '09269', '08534', '07542', '034443', '04796', '033766', '09268', '034601', '038759', '03947',
      '06334', '06504', '09453', '09173', '08281', '035203', '04204', '039223', '09834', '036873', '09235', '036643', '05859', '037297',
      '09360', '039975', '09228', '09271', '08501', '09673', '07234', '07741', '04503', '09631', '07651', '08423', '08504', '08683', '02463',
      '04874', '04558', '07674', '07671', '036042', '04861', '033769', '09295', '033202', '05320', '03421', '03976', '039996', '04182',
      '06541', '09974', '09765', '08669', '09107', '033674', '033731', '05848', '033477', '034383', '06147', '036923', '04626', '02672',
      '05675', '038309', '09142', '037468', '033748', '07722', '038320', '036622', '09395', '039820', '0651', '08562', '038225', '06306',
      '036482', '04154', '07124', '07425', '08621', '06335', '036840', '07071', '039346', '08067', '08065', '08245', '08268', '08633',
      '039999', '07461', '08158', '05936', '05935', '05695', '04243', '04246', '06836', '07551', '08642', '09720', '06596', '07743',
      '05763', '035454', '036083', '039771', '02825', '05942', '0581', '02657', '04122', '09369', '05173', '05147', '09842', '08846',
      '07556', '036742', '035728', '0731', '02676', '06645', '04189', '02303', '02308', '039263', '09773', '08269', '036927', '08593',
      '07975', '036731', '05827', '09533', '09367', '07235', '07966', '09565', '09554', '08377', '04956', '09628', '09396', '038372',
      '06081', '05571', '05573', '039058', '07374', '08806', '036962', '07042', '04451', '04456', '04274', '08106', '05302', '04441',
      '04447', '02051', '02052', '02053', '09182', '08742', '02863', '038324', '038848', '05364', '03304', '038484', '04231', '04230',
      '07577', '05246', '05423', '035433', '02645', '02424', '09942', '05324', '039748', '06204', '02162', '038796', '036203', '08194',
      '08296', '07721', '06474', '08741', '09662', '08706', '08541', '08549', '08548', '039325', '04843', '02656', '04445', '04262',
      '04260', '05733', '05228', '02855', '08038', '07662', '08457', '09651', '05635', '07727', '07306', '09381', '08754', '038202',
      '06898', '06802', '05693', '039933', '039402', '05467', '09205', '05495', '06398', '02564', '08021', '08246', '05683', '02836',
      '06053', '04827', '05849', '06871', '06874', '02523', '02520', '05444', '05774', '07254', '08681', '04554', '05835', '07151',
      '07263', '09652', '09202', '07578', '09463', '06207', '06758', '02638', '02291', '06274', '06479', '07529', '035455', '05634',
      '06087', '07157', '037608', '09464', '02455', '06333', '034327', '05656', '07681', '08581', '08638', '09972', '09975', '06500',
      '09632', '07751', '06085', '09657', '039972', '05525', '06227', '06282', '06285', '06286', '034639', '09262', '05407', '06837',
      '09936', '09933', '06375', '06706', '034656', '07955', '06435', '039033', '036201', '033920', '05161', '05166', '05168', '05167',
      '08303', '08379', '03622', '08426', '02309', '07203', '04606', '033397', '05655', '04382', '07522', '07506', '04463', '04425',
      '04426', '04469', '04326', '04757', '02325', '039395', '039209', '05641', '05642', '04407', '03991', '02581', '02585', '02584',
      '038482', '09277', '05767', '02902', '02925', '08762', '09737', '02432', '08071', '039451', '09832', '036941', '05144', '06554',
      '036256', '037384', '04103', '05130', '04951', '035027', '02837', '039400', '039061', '02434', '02436', '039423', '039059',
      '08592', '07762', '05658', '02655', '06094', '033673', '036603', '0961', '06599', '09826', '09278', '06329', '06784', '09605',
      '08708', '07934', '07033', '08195', '06471', '08387', '06374', '02254', '07023', '0881', '07755', '06472', '06475', '06083',
      '03643', '035243', '07244', '06201', '07134', '037436', '06876', '09575', '035876', '036072', '09141', '09149', '03443', '07309',
      '08389', '036374', '09253', '02637', '03576', '07646', '06150', '08375', '08293', '04188', '06584', '08427', '06506', '02764',
      '02608', '039296', '02384', '07182', '035751', '09092', '05303', '09129', '02762', '033679', '07024', '05103', '04559', '09407',
      '09348', '09349', '039393', '033746', '03761', '03327', '02392', '02922', '05951', '02196', '034364', '09604', '02389', '09722',
      '033398', '03943', '036848', '02694', '08365', '09342', '09397', '05203', '08272', '0281', '02859', '02803', '039832', '05376',
      '033831', '04833', '02236', '08153', '08809', '08344', '04305', '04539', '02663', '04975', '04127', '05404', '05456', '04488',
      '04409', '04372', '06244', '04955', '06423', '02335', '034607', '02557', '09869', '05909', '06441', '04829', '08020', '02686',
      '04203', '02377', '05586', '05726', '04458', '02262', '05149', '05825', '09634', '0611', '06096', '033849', '09966', '07335',
      '09383', '09196', '06222', '04944', '04948', '05925', '05908', '05146', '05022', '05196', '08370', '09853', '039604', '033928',
      '07054', '02297', '06678', '06626', '037603', '04431', '09745', '08304', '07503', '06220', '04421', '09260', '09102', '05646',
      '05644', '02154', '02156', '05632', '06697', '07852', '02739', '035204', '04823', '05945', '04859', '02292', '06707', '09681',
      '08546', '09871', '04778', '09676', '07195', '02606', '034745', '06302', '05143', '04171', '05056', '04179', '06756', '02981',
      '02985', '02983', '07434', '02267', '034775', '04920', '04770', '03841', '02742', '09854', '02302', '03877', '038852', '037200',
      '08574', '035725', '09347', '05831', '05834', '05839', '05836', '09076', '06571', '035476', '04462', '04466', '04973', '04464',
      '04467', '04133', '03394', '05542', '038481', '038753', '04839', '039825', '03963', '07834', '07527', '03494', '05331', '06036',
      '05692', '09875', '08171', '05361', '05362', '05365', '05363', '038789', '06304', '03836', '037609', '037369', '036334', '033833',
      '06034', '06703', '039201', '08442', '07502', '06568', '033454', '07705', '036074', '034905', '06241', '06247', '036464', '04792',
      '04794', '06732', '09482', '08702', '07271', '07277', '06340', '039925', '04705', '05802', '05829', '033456', '04802', '034979',
      '033963', '09728', '02058', '04173', '02953', '02957', '033702', '09232', '05031', '05033', '0202', '08725', '02405', '036652',
      '0931', '03425', '07945', '033979', '038354', '033234', '033839', '036087', '033925', '039828', '038220', '05843', '07709',
      '036921', '033976', '07746', '04681', '04903','02801', '02804', '07046', '034924', '09547', '038851', '033473', '033921', '03307',
      '033053', '038458', '035247', '09524', '09746', '09744', '03441', '07835', '07625', '09257', '09468', '03682', '09364', '06532',
      '06580', '09907', '03923', '06587', '05863', '033973', '033395', '039743', '04453', '04452', '036628', '033762', '04281', '039085',
      '038721', '035246', '036483', '08284', '05606', '038752', '033830', '033653', '038232', '035439', '038377', '038376', '03583',
      '037363', '035820', '033870', '08167', '034956', '034638', '03377', '03725', '039295', '02252', '039747', '08291', '038355',
      '06332', '034203', '0375', '039600', '07373', '09922', '034207', '037754',
    ]
  },
];

export const countryCodes = [
  '355', '376', '374', '43', '994', '375', '32', '387', '359', '385', '357', '45', '372', '358', '33', '995', '49', '30', '36',
  '353', '354', '39', '7', '383', '385', '371', '423', '370', '352', '389', '356', '373', '377', '382', '31', '47', '380', '43',
  '48', '351', '40', '378', '381', '421', '386', '34', '46', '41', '44', '90', '47', 
]