import React from 'react';
import clsx from 'clsx';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Heading } from '../Heading';
import { StackItem } from '../StackItem';
import { Divider } from '../Divider';
import { AnimatedArrowComponent } from '../AnimatedArrow';
import * as styles from './LinkList.css';

type LinkListItem = {
  link: React.ReactElement;
  arrowLink: React.ReactElement;
};

export type LinkListProps = {
  linklist: LinkListItem[];
};

export const LinkListComponent = (props: LinkListProps) => (
  <LinkListView {...props} />
);

export const LinkListSitecore = (props: LinkListProps) => {
  return <LinkListView {...props} />;
};

export const LinkListView = (props: LinkListProps) => {
  return (
    <Stack direction="column" className={styles.listWrapper}>
      {props.linklist.map((item, i) => {
        return <SingleLinkInner {...item} key={i} />;
      })}
    </Stack>
  );
};

export const SingleLinkInner = (props: LinkListItem) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      paddingLeft={10}
      paddingRight={10}
      className={clsx('ads-linklist', styles.linkWrapper)}
      width="100%"
      asChild
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box position="relative" asChild paddingTop={6} paddingBottom={6}>
          <Stack direction="row" alignY="center" gap={4}>
            <Divider tone="onLight" className={styles.divider} />
            <StackItem grow asChild>
              <Heading
                size={{ initial: 'description', md: 'paragraph' }}
                fontWeight="semiBold"
                as="h4"
                color="onLight"
                className={styles.linkHeading}
                isResponsive={false}
              >
                {props.link}
              </Heading>
            </StackItem>
            <Stack asChild alignX="end" alignY="center">
              <AnimatedArrowComponent
                direction="topToBottom"
                size="medium"
                active={isHovered}
                link={props.arrowLink}
              />
            </Stack>
          </Stack>
        </Box>
      </div>
    </Box>
  );
};
