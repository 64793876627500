import { stringifyObject, encodeNameToReactHookFormFormat } from './utils/utils';
import {
  ConditionSettings,
  Field,
  FieldWithConditons,
  Fields,
  MappedNestedFields,
  SimpleRecord,
  ValueFieldWithCondition,
} from './types';

export const replaceNamesWithFieldKeys = (values: SimpleRecord, nameToFieldKeyMap: SimpleRecord) =>
  Object.fromEntries(
    Object.entries(stringifyObject(values)).map(([key, value]) => [nameToFieldKeyMap[key], value])
  );

export function createNameToFieldKeyMap(names: string[], fields: ValueFieldWithCondition[]) {
  return names.reduce((acc, key) => {
    const field = fields.find((f) => encodeNameToReactHookFormFormat(f.valueField.name) === key);
    if (!field) return acc;
    return { ...acc, [key]: field.model.conditionSettings.fieldKey };
  }, {});
}

/**
 * We need to create a map of {[fieldKey]: fieldConditions[]}, so we have a quick lookup for all conditions per field. That way we can subscribe to the change in a field, retrieve the fieldkey of that field, and see what actions should be executed based on the fieldConditions of that field.
 * TODO: improve this
 * @param
 * @returns
 */
const hasConditionSettings = (v: {
  model: any;
}): v is { model: { conditionSettings: ConditionSettings } } => 'conditionSettings' in v.model;

export const createMappedFieldConditions = (flattenedFields: Fields) => {
  return Object.fromEntries(
    flattenedFields.map((field) => {
      if (!hasConditionSettings(field)) return [];
      const fieldConditions = flattenedFields
        .filter((f): f is ValueFieldWithCondition => 'conditionSettings' in f.model)
        .map((f) => f.model.conditionSettings.fieldConditions)
        .flat()
        .filter((f) =>
          f.conditions.some((c) => c.fieldId === field.model.conditionSettings.fieldKey)
        );

      return [field.model.conditionSettings.fieldKey, fieldConditions];
    })
  );
};
export function isNestedField(field: Field): field is MappedNestedFields {
  return 'fields' in field;
}

// A recursive function that flattens all the fields keys in all nested fietlds
export function flattenNestedFields(fields: Fields): FieldWithConditons[] {
  return fields
    .reduce((acc, field) => {
      if (isNestedField(field)) {
        return [...acc, field, ...flattenNestedFields(field.fields)];
      }

      return [...acc, field];
    }, [])
    .filter((f): f is FieldWithConditons => 'conditionSettings' in f.model);
}

export const getValueFields = (fields: FieldWithConditons[]) =>
  fields.filter(
    (item): item is ValueFieldWithCondition =>
      'valueField' in item && 'conditionSettings' in item.model
  );
