import { slugify } from './slug';

export type Filter = {
  displayName: string;
  id: string;
  name: string;
  url: string;
};

export function createFilterMapper(filters: Filter[], key: keyof Filter) {
  return Object.fromEntries(
    Object.entries(filters).map(([_, value]) => [slugify(value.displayName), value[key]])
  );
}
