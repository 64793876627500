import { LinkField, TextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'src/jss/lib/component-props';
import { ButtonProps, SitecoreImage } from '@alliander-fe/sitecore-types';
import { LocationOutages } from 'src/components/LocationOutages';
import { FilterValuePropertyFromFields, mapWithoutValueProperty } from 'src/utils/mapWithoutValueProperty';

export type OutagesOnLocationProps = ComponentProps & {
  fields: {
    image?: { value?: SitecoreImage };
    linkOne?: LinkField;
    linkTwo?: LinkField;
    maintenanceDetailsModalTitle?: TextField
    noOutagesLinkOne?: LinkField;
    noOutagesLinkTwo?: LinkField;
    onlyShowAllResolvedOutages?: { value?: boolean };
    onlyShowRecentlyResolvedOutages?: { value?: boolean };
    outageDetailsModalLinkOne?: { value?: ButtonProps };
    outageDetailsModalLinkTwo?: { value?: ButtonProps };
    outageDetailsModalText?: TextField;
    outageDetailsModalTitle?: TextField
    title?: TextField;
  };
};

export type FilteredOutagesOnLocationProps = FilterValuePropertyFromFields<OutagesOnLocationProps['fields']>;

const OutagesOnLocation = (props: OutagesOnLocationProps) => {
  const isEditor = Boolean(props.fields.title?.editable);

  if (props.fields) {
    const fields = mapWithoutValueProperty(props.fields)
    return <LocationOutages {...fields} isEditor={isEditor} />;
  }

  return null;
};

export default withDatasourceCheck()<OutagesOnLocationProps>(OutagesOnLocation);



