import clsx from 'clsx';
import { useState } from 'react';
import { AnimatedArrowComponent } from '../AnimatedArrow';
import { Box } from '../Box';
import { Text } from '../Text';
import { Heading } from '../Heading';
import { Stack } from '../Stack';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { StackItem } from '../StackItem';
import { Tag } from '../Tag';
import { CalendarIcon, PinDropIcon } from '../../icons/index';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './LinkCard.css';

// NOTE: Should this even be its own component? Or just part of InfoSection?
export const LinkCardView = (props: CardViewProps) => {
  const isDesktop = useMedia(mq.md);

  if (isDesktop) return <LinkCardDesktop {...props} />;
  return <LinkCardMobile {...props} />;
};

export const LinkCardDesktop = (props: CardViewProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      paddingTop={{ initial: 6, md: 10 }}
      paddingBottom={{ initial: 6, md: 10 }}
      paddingLeft={{ initial: 10, md: 10 }}
      paddingRight={{ initial: 10, md: 10 }}
      borderRadius="md"
      asChild
    >
      <Stack
        alignX="justify"
        alignY="justify"
        wrap
        direction={{ initial: 'row', md: 'column' }}
        asChild
        gap={2}
      >
        <Box asChild width="100%">
          <Stack
            className={styles.card}
            direction={{ initial: 'row', md: 'column' }}
            alignX="justify"
            alignY="justify"
            asChild
            gap={6}
          >
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Stack alignY="justify" isFullHeight>
                {props.tag ? (
                  <Box paddingBottom={{ initial: 6, lg: 8 }}>
                    <Tag>{props.tag}</Tag>
                  </Box>
                ) : null}

                <Stack alignY="justify" gap={6} isFullHeight>
                  <Stack asChild alignY="center" gap={6}>
                    <Box paddingBottom={14}>
                      {props.title ? (
                        <Heading size="h5" as="h3" className={styles.cardTitle}>
                          {props.title}
                        </Heading>
                      ) : null}

                      <Stack direction="column">
                        <Stack
                          direction="row"
                          gap={4}
                          wrap
                          className={styles.metaDataWrapper}
                        >
                          {props.publicationDate && (
                            <Stack direction="row" gap={2} alignY="start">
                              <CalendarIcon size="xs" />
                              <Text weight="bold" size="label">
                                {props.publicationDate}
                              </Text>
                            </Stack>
                          )}

                          {props.regions &&
                            props.regions.length > 0 &&
                            props.regions.map((item, i) => (
                              <Stack
                                direction="row"
                                gap={2}
                                alignY="start"
                                key={i}
                              >
                                <PinDropIcon size="xs" />

                                <Stack>
                                  <Text size="label">{item}</Text>
                                </Stack>
                              </Stack>
                            ))}
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>

                  {props.link ? (
                    <Stack asChild alignX={'start'} alignY={'end'}>
                      <AnimatedArrowComponent
                        size={{ initial: 'medium', md: 'large' }}
                        active={isHovered}
                        link={props.link}
                      />
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            </div>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
export const LinkCardMobile = (props: CardViewProps) => {
  return (
    <Box position="relative" asChild>
      <Stack direction="column" asChild isFullWidth>
        <div>
          <Box
            paddingTop={{ initial: 6, md: 6 }}
            paddingBottom={{ initial: 6, md: 6 }}
            paddingLeft={{ initial: 8, md: 6 }}
            paddingRight={{ initial: 8, md: 6 }}
            asChild
          >
            <Stack direction="column" alignY="center" asChild>
              <Box>
                {props.tag ? (
                  <Box paddingBottom={{ initial: 8 }}>
                    <Tag>{props.tag}</Tag>
                  </Box>
                ) : null}
                {props.title ? (
                  <StackItem grow asChild>
                    <Box asChild paddingBottom={{ initial: 8 }}>
                      <Heading
                        size="h6"
                        as="h3"
                        fontWeight="semiBold"
                        color="onLight"
                        className={styles.cardTitle}
                      >
                        {props.title}
                      </Heading>
                    </Box>
                  </StackItem>
                ) : null}

                <Stack direction="column">
                  <Stack direction="column" wrap gap={2} asChild>
                    <Box paddingRight={14}>
                      {props.publicationDate && (
                        <Stack direction="row" gap={2} alignY="start">
                          <CalendarIcon size="xs" />
                          <Text size="label">{props.publicationDate}</Text>
                        </Stack>
                      )}

                      {(props.regions || []).map((item, i) => (
                        <Stack direction="row" gap={2} alignY="start" key={i}>
                          <PinDropIcon size="xs" />

                          <Stack>
                            <Text size="label">{item}</Text>
                          </Stack>
                        </Stack>
                      ))}
                    </Box>
                  </Stack>
                  {props.link ? (
                    <Stack asChild alignX={'end'} alignY={'end'}>
                      <AnimatedArrowComponent size="medium" link={props.link} />
                    </Stack>
                  ) : null}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </div>
      </Stack>
    </Box>
  );
};

export const LinkCardWithImageView = (props: CardViewProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      borderRadius="md"
      asChild
      overflow={{ md: 'hidden' }}
      bg="containerPrimary"
    >
      <StackItem grow asChild>
        <Stack
          className={clsx(styles.cardWithImage)}
          direction={{ initial: 'column', md: 'row' }}
        >
          <Box
            paddingTop={{ initial: 8, md: 10 }}
            paddingBottom={{ initial: 16, md: 10 }}
            paddingLeft={{ initial: 8, md: 10 }}
            paddingRight={{ initial: 8, md: 10 }}
            asChild
          >
            <Stack
              alignX="justify"
              alignY="justify"
              className={styles.textWrapper}
              asChild
            >
              <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Box asChild paddingBottom={{ initial: 8, md: 10 }}>
                  {props.tag ? (
                    <Text size="label" color="onDark">
                      {props.tag}
                    </Text>
                  ) : null}
                </Box>
                <Stack
                  direction="column"
                  alignX="justify"
                  wrap
                  gap={6}
                  isFullHeight
                  asChild
                >
                  <Box>
                    {props.title ? (
                      <Box asChild>
                        <Heading
                          size="h5"
                          className={styles.cardTitle}
                          color="onDark"
                          isResponsive={false}
                          asChild
                        >
                          {props.title}
                        </Heading>
                      </Box>
                    ) : null}

                    <Stack direction="column">
                      <Stack
                        direction="row"
                        gap={4}
                        wrap
                        className={styles.metaDataWrapper}
                      >
                        {props.publicationDate && (
                          <Stack direction="row" gap={2} alignY="start">
                            <CalendarIcon size="xs" color="onDark" />
                            <Text weight="bold" size="label" color="onDark">
                              {props.publicationDate}
                            </Text>
                          </Stack>
                        )}

                        {(props.regions || []).map((item, i) => (
                          <Stack direction="row" gap={2} alignY="start" key={i}>
                            <PinDropIcon size="xs" color="onDark" />
                            <Stack>
                              <Text size="label" color="onDark">
                                {item}
                              </Text>
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                    {props.link ? (
                      <Stack asChild alignX={'start'} alignY={'end'}>
                        <AnimatedArrowComponent
                          color="onDark"
                          size={{ initial: 'medium', md: 'large' }}
                          active={isHovered}
                          link={props.link}
                        />
                      </Stack>
                    ) : null}
                  </Box>
                </Stack>
              </div>
            </Stack>
          </Box>
          <Box className={styles.imageWrapper} width="100%" height="100%">
            <Box className={styles.image} width="100%" height="100%" asChild>
              {props.infoImage}
            </Box>
          </Box>
        </Stack>
      </StackItem>
    </Box>
  );
};

type CardProps = {
  tag?: string;
  title?: string;
  link?: React.ReactElement;
  infoImage?: React.ReactNode;
  publicationDate?: string;
  regions?: string[];
};

type CardSitecoreProps = {
  tag?: React.ReactElement;
  title?: React.ReactElement;
  link?: React.ReactElement;
  infoImage?: React.ReactNode;
  publicationDate?: React.ReactElement;
  regions?: React.ReactElement[];
};

type CardViewProps = CombineProps<CardProps, CardSitecoreProps>;
