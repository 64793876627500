export function isIBAN(input: string, message: string): boolean | string {
  const normalizedInput = input.replace(/\s/g, '');

  if (!/^([A-Z]{2})(\d{2})([A-Z\d]+)$/.test(normalizedInput)) {
    return false;
  }

  const reorderedInput = normalizedInput.slice(4) + normalizedInput.slice(0, 4);
  const convertedInput = reorderedInput.replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString()
  );

  const remainder = Array.from(convertedInput).reduce(
    (prev, curr) => (Number(prev + curr) % 97).toString(),
    ''
  );

  return remainder === '1' || message;
}
