import React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { IconButton } from '../IconButton';
import { ChevronDownIcon } from '../../icons/index';
import { Heading } from '../Heading';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import { Box } from '../Box';
import { Text } from '../Text';
import { Divider } from '../Divider';
import { ButtonUnset } from '../ButtonUnset';
import { CombineProps } from '../../types/type-helpers';

import * as styles from './Accordion.css';

// TODO: refactor interface for this component. Is currently too specific for FAQ
type AccordionProps = {
  items: {
    answer: string;
    question: string;
    questionName?: string | number;
    id: string;
  }[];
  onAccordionOpen?: (id: string, opened: boolean) => void;
};

type AccordionSitecoreProps = {
  items: {
    answer: React.ReactNode;
    question: React.ReactNode;
    questionName?: string | number;
    id: string;
  }[];
  onAccordionOpen?: (id: string, opened: boolean) => void;
};

type AccordionCombinedProps = CombineProps<
  AccordionProps,
  AccordionSitecoreProps
>;

export const Accordion = (props: AccordionProps) => {
  return <AccordionView {...props} />;
};

export const AccordionSitecore = (props: AccordionSitecoreProps) => {
  return <AccordionView {...props} />;
};

export const AccordionView = (props: AccordionCombinedProps) => {
  const [openedItem, setOpenedItem] = React.useState<string>();

  // When the accordion opens, value is the id of the accordion item
  // When the accordion closes, value is empty - so we use the state to get the active item
  const onChange = (value?: string) => {
    const activeItem = value || openedItem;
    setOpenedItem(value);
    if (activeItem && props.onAccordionOpen) {
      props.onAccordionOpen(activeItem, !!value);
    }
  };

  return (
    <Box width="100%">
      <RadixAccordion.Root
        type="single"
        collapsible
        onValueChange={(openedItem) => {
          onChange(openedItem);
        }}
        value={openedItem}
      >
        {props.items.map((item, i) => {
          return (
            <React.Fragment key={item.id}>
              <RadixAccordion.Item value={item.id}>
                <Box
                  paddingBottom={6}
                  paddingTop={i === 0 ? 0 : 6}
                  paddingRight={{ initial: 10, md: 20 }}
                  position="relative"
                >
                  <RadixAccordion.Header asChild>
                    <Stack
                      direction="row"
                      alignY="center"
                      gap={2}
                      isFullWidth
                      className={styles.questionWrapper}
                    >
                      <StackItem grow asChild className={styles.question}>
                        <Heading size="h5" as="h3">
                          <RadixAccordion.Trigger asChild>
                            <ButtonUnset>{item.question}</ButtonUnset>
                          </RadixAccordion.Trigger>
                        </Heading>
                      </StackItem>
                      <StackItem
                        shrink={false}
                        className={clsx(styles.triggerCnt)}
                      >
                        <RadixAccordion.Trigger asChild>
                          <IconButton
                            className={clsx(styles.trigger)}
                            size={{ initial: 'small' }}
                            variant={{ initial: 'transparant', md: 'solid' }}
                            icon={<ChevronDownIcon />}
                            label={
                              openedItem === item.id
                                ? 'Sluit accordion'
                                : 'Open accordion'
                            }
                          />
                        </RadixAccordion.Trigger>
                      </StackItem>
                    </Stack>
                  </RadixAccordion.Header>
                  <Text asChild>
                    <Box asChild>
                      <RadixAccordion.Content
                        forceMount
                        hidden={item.id !== openedItem}
                      >
                        <Box paddingTop={10}>
                          <Text asChild>{item.answer}</Text>
                        </Box>
                      </RadixAccordion.Content>
                    </Box>
                  </Text>
                </Box>
              </RadixAccordion.Item>
              <Divider />
            </React.Fragment>
          );
        })}
      </RadixAccordion.Root>
    </Box>
  );
};
