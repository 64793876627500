import { ParagraphSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { renderJssLink, renderJssRichText } from '@alliander-fe/jss-utils';
import { RichTextField, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import React from 'react';

type ParagraphPropsJssProps = ComponentProps & {
  fields: {
    text: RichTextField;
    primaryButton: LinkField;
    secondaryButton: LinkField;
  };
  params?: {
    anchor?: string;
  };
};

const Paragraph = (props: ParagraphPropsJssProps) => {
  const paragraphText = props.fields.text;

  const text = renderJssRichText(paragraphText);
  const primaryButton = renderJssLink(props.fields.primaryButton);
  const secondaryButton = renderJssLink(props.fields.secondaryButton);

  if (!primaryButton && !secondaryButton && !text) {
    return null;
  }

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <ParagraphSitecore
        text={text}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(Paragraph);
