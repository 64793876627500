import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import Layout from 'src/components/Layout';
import {
  RenderingType,
  SitecoreContext,
  ComponentPropsContext,
  handleEditorFastRefresh,
  EditingComponentPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useTrackingTargetView } from '@liander/context';
import { sitecorePagePropsFactory } from 'src/jss/lib/page-props-factory';
// different componentFactory method will be used based on whether page is being edited

import sitecoreRedirect from 'src/jss/lib/sc-redirect';
import { DictionaryItem, useAdsContext } from '@ads-core/providers';
import NextLink from 'next/link';

import React from 'react';
import fileBasedRedirect from 'lib/filebased-redirect';
import { CACHE_CONTROL_AGES } from 'src/data/constants';
import { componentFactory, editingComponentFactory } from '../jss/temp/componentFactory';
import { SitecorePagePropsEnriched } from './_app';

const SitecorePage = ({ componentProps, layoutData }: SitecorePagePropsEnriched): JSX.Element => {
  const { setIds, setDictionary } = useAdsContext();
  const tooltips = layoutData.sitecore.context.tooltips as (DictionaryItem & {
    tooltip: { button: { href: string; text: string; isExternal: boolean; target: string } };
  })[];

  const mappedTooltips = React.useMemo(
    () =>
      tooltips.map(({ tooltip: { button, ...tooltipRest }, ...rest }) => {
        const Component = button?.isExternal ? 'a' : NextLink;
        return {
          ...rest,
          tooltip: {
            ...tooltipRest,
            button:
              button && button.href && button.text ? (
                <Component href={button?.href} target={button.target}>
                  {button.text}
                </Component>
              ) : undefined,
          },
        };
      }),
    [tooltips]
  );

  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  useTrackingTargetView();

  useEffect(() => {
    // Add tooltip dictionary to context
    if (mappedTooltips.length > 0) {
      setDictionary(mappedTooltips);
    }

    // Add separation ids to context
    const placeholders = layoutData.sitecore.route?.placeholders;

    if (placeholders) {
      const idsTest = placeholders['jss-separation']
        // Experience editor adds <code> elements to the placeholder, so the first element in the normal mode isnt always the firrst element in the editor mode
        .filter((item: any) => item.uid !== undefined)
        .slice(1)
        .map((item) => (item as { uid?: string }).uid)
        .filter((uid) => uid !== undefined) as string[];

      setIds(idsTest);
    }
  }, [tooltips, layoutData.sitecore.route?.placeholders, mappedTooltips, setDictionary, setIds]);

  // if (notFound || !layoutData.sitecore.route) {
  //   // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
  //   return <NotFound />;
  // }

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isComponentRendering =
    layoutData.sitecore.context.renderingType === RenderingType.Component;

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={isEditing ? editingComponentFactory : componentFactory}
        layoutData={layoutData}
      >
        {/*
          Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
          If you are using Experience Editor only, this logic can be removed, Layout can be left.
        */}
        {isComponentRendering ? (
          <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
        ) : (
          <Layout layoutData={layoutData} />
        )}
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at request time on server-side.
export const getStaticProps = (async (context) => {
  // When draftMode is enabled it passes a previewData property
  // previewData is filled by api/editing/render.ts which is only executed by Sitecore editors
  // Locally this is not available, so it breaks
  const props = await sitecorePagePropsFactory.create(context);

  // If a page does NOT exist, then look for a redirect
  const path = context.params?.path;

  if (props.notFound && path) {
    // Path elements can come in as an array of each path element
    const sourceUrl = '/' + (Array.isArray(path) ? path.join('/') : path);

    // Check hardcoded redirect list
    props.redirect = fileBasedRedirect(sourceUrl);

    if (!props.redirect) {
      // Our sitecore managed redirect function is called here
      props.redirect = await sitecoreRedirect(sourceUrl);
    }

    if (props.redirect) {
      return {
        redirect: props.redirect,
      };
    }
  }

  return {
    props,
    revalidate: CACHE_CONTROL_AGES.MINUTE,
    notFound: props.notFound,
  };
}) satisfies GetStaticProps<{
  notFound: boolean;
}>;

export const getStaticPaths = (async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
}) satisfies GetStaticPaths;

export default SitecorePage;
