import React from 'react';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { PageGrid } from '../PageGrid';
import { Stack } from '../Stack';
import { ArrowRightIcon } from '../../icons/index';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { TextLink } from '../TextLink';
import { DictionaryDialog } from '../DictionaryDialog';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './HomepageHero.css';

export const HomepageHeroComponent = (props: HomepageHeroProps) => (
  <HomepageHeroComponentView {...props} />
);

export const HomepageHeroSitecore = (props: HomepageHeroSitecoreProps) => (
  <HomepageHeroComponentView {...props} />
);

const HomepageHeroComponentView = (props: HomepageHeroViewProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div>
      <PageGrid className="ads-homePageHero">
        {props.text ? (
          <Box asChild paddingBottom={10}>
            <GridItem
              columnStart={{ initial: '1', md: '1' }}
              columnEnd={{
                initial: '-1',
                md: props.explanation ? 'span 7' : '-1',
                xl: props.explanation ? 'span 8' : '-1',
              }}
            >
              <Heading size="h1" asChild align={{ initial: 'center', md: 'start' }}>
                <h1>
                  <DictionaryDialog>{props.text}</DictionaryDialog>
                </h1>
              </Heading>
            </GridItem>
          </Box>
        ) : null}

        {props.explanation && (
          <LinkBlock explanation={props.explanation} textLink={props.textLink} />
        )}
      </PageGrid>

      <Box className={styles.ImageWrapper} ref={ref}>
        <Box asChild className={styles.image} borderRadius={{ md: 'brandXl' }}>
          {props.image}
        </Box>
      </Box>

      {props.tasks && (
        <PageGrid className={styles.TopTaskSection}>
          <GridItem columnStart="1" columnEnd="-1">
            {props.tasks}
          </GridItem>
        </PageGrid>
      )}
    </div>
  );
};

type HomepageHeroProps = {
  image?: React.ReactElement;
  text?: string;
  tasks?: React.ReactElement;
  explanation?: string;
  textLink?: React.ReactElement;
};

type HomepageHeroSitecoreProps = {
  text?: React.ReactElement | string;
  image?: React.ReactElement;
  tasks?: React.ReactElement;
  explanation?: React.ReactElement;
  textLink?: React.ReactElement;
};

type HomepageHeroViewProps = CombineProps<HomepageHeroProps, HomepageHeroSitecoreProps>;

const LinkBlock = (props: LinkBlockProps) => {
  return (
    <GridItem
      columnStart={{ initial: '1', md: 'span 4' }}
      columnEnd={{ initial: '-1', md: '-1' }}
      className={styles.heroInformationWrapper}
    >
      <Box
        className={styles.rectangleWrapper}
        paddingTop={8}
        paddingBottom={8}
        paddingLeft={6}
        paddingRight={6}
      >
        <Stack alignX="center" alignY="center" gap={6}>
          {props.explanation && (
            <Text align="center" size="description" className={styles.heroInformationText}>
              {props.explanation}
            </Text>
          )}
          {props.textLink && (
            <TextLink
              asChild
              afterIcon={<ArrowRightIcon size="xs" />}
              className={styles.TextLink}
              variant="secondary"
            >
              {props.textLink}
            </TextLink>
          )}
        </Stack>
      </Box>
    </GridItem>
  );
};

type LinkBlockProps = Pick<HomepageHeroViewProps, 'textLink' | 'explanation'>;

