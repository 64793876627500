import { ButtonProps, TextFieldProps } from '@alliander-fe/sitecore-types';
import { LinkField, RichTextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { CongestionCheckerParticulier as CongestionChecker } from 'src/components/CongestionCheckerParticulier';

import { ComponentProps } from 'src/jss/lib/component-props';
import {
  FilterValuePropertyFromFields,
  mapWithoutValueProperty,
} from 'src/utils/mapWithoutValueProperty';

export type SitecoreCongestionCheckerParticulierProps = ComponentProps & {
  fields: {
    title: TextFieldProps;
    postcode: ButtonProps;
    huisnr: TextFieldProps;
    explanation: RichTextField;
    inputFormTitle: TextFieldProps;
    inputFormText: TextFieldProps;
    submitButtonLabel: TextFieldProps;

    feedbackPurpleTitle: TextFieldProps;
    feedbackPurpleText: RichTextField;
    feedbackPurplePrimaryButton: LinkField;
    feedbackPurpleSecondaryButton: LinkField;

    additionalInfoBlockPurpleTitle: TextFieldProps;
    additionalInfoBlockPurpleText: RichTextField;
    additionalInfoBlockPurplePrimaryButton: LinkField;
    additionalInfoBlockPurpleSecondaryButton: LinkField;

    conclusionPurpleUpperText: RichTextField;
    conclusionPurpleFirstPrimaryButton: LinkField;
    conclusionPurpleFirstSecondaryButton: LinkField;

    conclusionPurpleLowerText: RichTextField;
    conclusionPurpleSecondPrimaryButton: LinkField;
    conclusionPurpleSecondSecondaryButton: LinkField;

    feedbackRedTitle: TextFieldProps;
    feedbackRedText: RichTextField;
    feedbackRedPrimaryButton: LinkField;
    feedbackRedSecondaryButton: LinkField;

    additionalInfoBlockRedTitle: TextFieldProps;
    additionalInfoBlockRedText: RichTextField;
    additionalInfoBlockRedPrimaryButton: LinkField;
    additionalInfoBlockRedSecondaryButton: LinkField;

    conclusionRedUpperText: RichTextField;
    conclusionRedFirstPrimaryButton: LinkField;
    conclusionRedFirstSecondaryButton: LinkField;

    conclusionRedLowerText: RichTextField;
    conclusionRedSecondPrimaryButton: LinkField;
    conclusionRedSecondSecondaryButton: LinkField;

    feedbackGridFullTitle: TextFieldProps;
    feedbackGridFullText: RichTextField;
    feedbackGridFullPrimaryButton: LinkField;
    feedbackGridFullSecondaryButton: LinkField;

    additionalInfoBlockGridFullTitle: TextFieldProps;
    additionalInfoBlockGridFullText: RichTextField;
    additionalInfoBlockGridFullPrimaryButton: LinkField;
    additionalInfoBlockGridFullSecondaryButton: LinkField;

    conclusionGridFullUpperText: RichTextField;
    conclusionGridFullFirstPrimaryButton: LinkField;
    conclusionGridFullFirstSecondaryButton: LinkField;

    conclusionGridFullLowerText: RichTextField;
    conclusionGridFullSecondPrimaryButton: LinkField;
    conclusionGridFullSecondSecondaryButton: LinkField;

    feedbackYellowTitle: TextFieldProps;
    feedbackYellowText: RichTextField;
    feedbackYellowPrimaryButton: LinkField;
    feedbackYellowSecondaryButton: LinkField;

    additionalInfoBlockYellowTitle: TextFieldProps;
    additionalInfoBlockYellowText: RichTextField;
    additionalInfoBlockYellowPrimaryButton: LinkField;
    additionalInfoBlockYellowSecondaryButton: LinkField;

    conclusionYellowUpperText: RichTextField;
    conclusionYellowFirstPrimaryButton: LinkField;
    conclusionYellowFirstSecondaryButton: LinkField;

    conclusionYellowLowerText: RichTextField;
    conclusionYellowSecondPrimaryButton: LinkField;
    conclusionYellowSecondSecondaryButton: LinkField;

    feedbackNoneTitle: TextFieldProps;
    feedbackNoneText: RichTextField;
    feedbackNonePrimaryButton: LinkField;
    feedbackNoneSecondaryButton: LinkField;

    additionalInfoBlockNoneTitle: TextFieldProps;
    additionalInfoBlockNoneText: RichTextField;
    additionalInfoBlockNonePrimaryButton: LinkField;
    additionalInfoBlockNoneSecondaryButton: LinkField;

    conclusionNoneUpperText: RichTextField;
    conclusionNoneFirstPrimaryButton: LinkField;
    conclusionNoneFirstSecondaryButton: LinkField;

    conclusionNoneLowerText: RichTextField;
    conclusionNoneSecondPrimaryButton: LinkField;
    conclusionNoneSecondSecondaryButton: LinkField;

    disclaimerTextCongestionNearbyConnection: RichTextField;

    errorTitle: TextFieldProps;
    errorMessage: RichTextField;

    errorTitleAddressHasNoLowVoltageConnection: TextFieldProps;
    errorMessageAddressHasNoLowVoltageConnection: RichTextField;

    errorTitleOtherGridOperatorElectricity: TextFieldProps;
    errorMessageOtherGridOperatorElectricity: RichTextField;

    errorTitlePostalCodeHouseNumberNotFound: TextFieldProps;
    errorMessagePostalCodeHouseNumberNotFound: RichTextField;
  };
};

export type FilteredCongestionCheckerParticulierProps = FilterValuePropertyFromFields<
  SitecoreCongestionCheckerParticulierProps['fields']
>;

const CongestionCheckerParticulier = (props: SitecoreCongestionCheckerParticulierProps) => {
  const mappedFields = mapWithoutValueProperty(props.fields);

  return <CongestionChecker {...mappedFields} />;
};

export default withDatasourceCheck()<SitecoreCongestionCheckerParticulierProps>(
  CongestionCheckerParticulier
);
