import { baseQueryParams } from 'src/utils';

type replaceUrlParametersProps = {
  reference?: number | null | undefined;
  city?: string;
  postalCode?: string;
  houseNumber?: string;
  houseNumberAddition?: string;
};

// Create the the url query parameters.
export const replaceUrlParameters = ({
  postalCode,
  city,
  houseNumber,
  houseNumberAddition,
  reference,
}: replaceUrlParametersProps): { [key: string]: string } => {
  let query;

  if (reference) {
    query = `?${baseQueryParams.reference}=${reference}`;
  }

  if (city) {
    query = `${query ? `${query}&` : '?'}${baseQueryParams.city}=${city}`;
  }

  if (postalCode) {
    query = `${query ? `${query}&` : '?'}${baseQueryParams.postalCode}=${postalCode}`;
  }

  if (houseNumber) {
    query = `${query ? `${query}&` : '?'}${baseQueryParams.houseNumber}=${houseNumber}`;
  }

  if (houseNumberAddition) {
    query = `${query ? `${query}&` : '?'}${
      baseQueryParams.houseNumberAddition
    }=${houseNumberAddition}`;
  }

  return query ? { '{queryParameters}': query } : {};
};
