import React from 'react';
import { MappedMainMenu } from '@alliander-fe/sitecore-types';
import clsx from 'clsx';
import { Box } from '../Box';
import { NavLink } from '../NavLink';
import { Navigation } from '../Navigation';
import { Stack } from '../Stack/index';
import * as styles from './DesktopHeader.css';

type DesktopHeaderProps = {
  mainNav: MappedMainMenu;
  segmentMenu: Array<{ link: React.ReactElement }>;
  rightTopMenu: React.ReactNode;
  currentSegmentLink?: React.ReactElement;
  search: React.ReactNode;
};

export const DesktopHeader = ({
  mainNav,
  currentSegmentLink,
  rightTopMenu,
  search,
  segmentMenu,
}: DesktopHeaderProps) => {
  const [isSticky, setIsSticky] = React.useState(false);
  const [navHeight, setNavHeight] = React.useState<number>(0);

  const topRef = React.useRef<HTMLDivElement>(null);
  const navRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const topElement = topRef.current;
    const navElement = navRef.current;

    // Set up intersection observer that add a class 'sticky' when the topnav is out of view
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: [0] }
    );

    if (topElement && navElement) {
      observer.observe(topElement);
      // We need the height of the nav to fill the layout space that appears when the nav becomes sticky (position: fixed)
      const height = navElement.getBoundingClientRect().height;
      setNavHeight(height);
    }

    return () => {
      if (topElement) {
        observer.unobserve(topElement);
      }
    };
  }, []);

  return (
    <header style={{ paddingBottom: isSticky ? `${navHeight + 20}px` : 0 }}>
      <Stack gap={5} className={styles.header}>
        <Box bg="containerDark" height="48px" asChild>
          <div ref={topRef}>
            <Box height="100%" width="100%" asChild>
              <Stack
                alignX="justify"
                direction="row"
                isFullHeight
                alignY="center"
                className={styles.navitemsWrapper}
              >
                <Stack direction="row" gap={6} as="ul" isFullHeight>
                  {segmentMenu.map(({ link }) => {
                    return (
                      <Stack as="li" key={link.props.href}>
                        <Stack asChild isFullHeight alignY="center">
                          <NavLink
                            tone="onDark"
                            asChild
                            className={styles.topMenuItem}
                          >
                            {link}
                          </NavLink>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
                <Stack direction="row" gap={6} alignY="center">
                  {rightTopMenu}
                </Stack>
              </Stack>
            </Box>
          </div>
        </Box>
        <Box
          width="100%"
          asChild
          className={clsx(
            isSticky ? styles.navWrapper : '',
            styles.navitemsWrapper
          )}
        >
          <div ref={navRef}>
            <Navigation
              items={mainNav}
              currentSegmentLink={currentSegmentLink}
              search={search}
            />
          </div>
        </Box>
      </Stack>
    </header>
  );
};
