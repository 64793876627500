import { Answer, Conclusion, Question } from '..';
import { Box } from '../../Box';
import { Hidden } from '../../Hidden';
import { Stack } from '../../Stack';
import { StackItem } from '../../StackItem';
import { Text } from '../../Text';
import * as styles from '../WizardSimple.css';
import { WizardSimpleAnsweredStep } from './WizardSimpleAnsweredStep';
import { WizardSimpleUnansweredStep } from './WizardSimpleUnansweredStep';

type WizardStepProps = {
  index: number;
  onAnswerClick(nextStep: Conclusion | Question, answer: Answer): void;
  onEditAnswerClick(): void;
  question: Question;
  editAnswerLabel: string;
  show: boolean;
};

export const WizardSimpleStepWrapper = ({
  index,
  onAnswerClick,
  onEditAnswerClick,
  question,
  editAnswerLabel,
  show = false,
}: WizardStepProps) => {
  return (
    <Hidden hide={!show}>
      <Box bg="backgroundLight" borderRadius="md" asChild>
        <Stack direction="row">
          <StackItem shrink={false} asChild>
            <Stack
              alignX="center"
              alignY={{ initial: 'start', md: 'center' }}
              asChild
            >
              <Box
                width={{ md: '200px' }}
                className={styles.circleContainer}
                position="relative"
              >
                <Stack alignX="center" alignY="center" asChild>
                  <Box
                    zIndex="3"
                    position="relative"
                    className={styles.circle}
                    width="50px"
                    height="50px"
                    bg="containerPrimary"
                    borderRadius="circle"
                    asChild
                  >
                    <Text color="onDark">{index}</Text>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </StackItem>

          {question?.givenAnswer ? (
            <WizardSimpleAnsweredStep
              onEditAnswerClick={onEditAnswerClick}
              question={question}
              editAnswerLabel={editAnswerLabel}
            />
          ) : (
            <WizardSimpleUnansweredStep
              onAnswerClick={onAnswerClick}
              question={question}
            />
          )}
        </Stack>
      </Box>
    </Hidden>
  );
};
