import { setReplaceText } from '@alliander-fe/validation';
import { CongestionLevel } from 'src/utils/congestionLevel';
import { FilteredNetworkCheckProps } from '..';
import { DialogStatusProps } from '../_DialogNetwork';

// Maps the api response to the correct response for the view
export const mappedNetworkCongestions = ({
  networkCheck,
  level,
  replaceText,
}: {
  networkCheck?: FilteredNetworkCheckProps;
  level?: CongestionLevel;
  replaceText?: { [key: string]: string };
}): DialogStatusProps | undefined => {
  const replaceTextParameters = replaceText ? replaceText : { '{}': '' };

  if (!level) return;

  if (level === 'Yellow' || level === 'None') {
    return {
      title: setReplaceText(networkCheck?.noCongestionsTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.noCongestionsText, replaceTextParameters),
      available: true,
    };
  }

  return {
    title: setReplaceText(networkCheck?.congestionsTitle, replaceTextParameters) ?? '',
    message: setReplaceText(networkCheck?.congestionsText, replaceTextParameters),
    available: false,
  };
};
