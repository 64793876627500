import { LoadIcon, SearchIcon } from '@ads-core/icons';
import { Box, IconButton, Stack, ToneFocus } from '@ads-core/components';
import React from 'react';
import clsx from 'clsx';
import { useSitecoreContext } from '@liander/context';
import * as styles from './Search.css';

interface SearchInputProps {
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | KeyboardEvent) => void;
  'aria-label': string;
  icon?: boolean;
  isFetching?: boolean;
  defaultValue?: string;
}

export const SearchInput = (props: SearchInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      input.focus();
      input.selectionStart = input.selectionEnd = input.value.length;
    }
  }, []);
  return (
    <>
      <Stack alignY="center" alignX="end">
        {props.icon && (
          <Box position="absolute" asChild className={styles.icon}>
            {!props.isFetching ? (
              <SearchIcon size="md" />
            ) : (
              <LoadIcon size="sm" className={styles.fetchIcon} />
            )}
          </Box>
        )}
        <ToneFocus>
          <Box
            asChild
            width="100%"
            paddingLeft={4}
            paddingRight={12}
            paddingTop={4}
            paddingBottom={4}
            className={styles.input}
            borderRadius="md"
          >
            <input
              ref={inputRef}
              onChange={props.onChange}
              aria-label="Zoek"
              type="text"
              defaultValue={props.defaultValue}
              key={props.defaultValue}
            />
          </Box>
        </ToneFocus>
      </Stack>
    </>
  );
};

export const SearchInputSuggestions = (
  props: SearchInputProps & { onSubmit(e: React.FormEvent<HTMLFormElement>): void; name: string }
) => {
  const sitecoreCtx = useSitecoreContext();

  return (
    <form onSubmit={props.onSubmit}>
      <fieldset>
        <Stack alignY="center" alignX="end">
          <Box position="relative" width="100%">
            <ToneFocus>
              <Box
                asChild
                width="100%"
                paddingLeft={4}
                paddingRight={16}
                paddingTop={4}
                paddingBottom={4}
                className={clsx(styles.input, styles.inputSuggestions)}
                borderRadius="md"
              >
                <input
                  onChange={
                    sitecoreCtx.context.enableSearchSuggestions ? props.onChange : undefined
                  }
                  aria-label="Zoek"
                  type="text"
                  name={props.name}
                  placeholder="Waar bent u naar op zoek?"
                  autoComplete="off"
                />
              </Box>
            </ToneFocus>
            <IconButton
              icon={<SearchIcon />}
              label="zoeken"
              size="small"
              className={styles.tempButton}
            />
          </Box>
        </Stack>
      </fieldset>
    </form>
  );
};
