import React from 'react';
import type { Link } from '@alliander-fe/sitecore-types';
import { Breadcrumb as BreadcrumbComponent } from '@ads-core/components';
import NextLink from 'next/link';

type BreadcrumbProps = {
  fields: {
    breadcrumbLinks: Array<{
      isActive: boolean;
      link: Link;
    }>;
  };
};

const Breadcrumb = (props: BreadcrumbProps) => {
  const mappedLinks = props.fields.breadcrumbLinks.map(({ isActive, link }) => {
    const { text, href, target } = link;
    if (isActive) {
      return { link: text, isActive };
    }

    return {
      link: (
        <NextLink href={href} target={target}>
          {text}
        </NextLink>
      ),
      isActive,
    };
  });

  return <BreadcrumbComponent links={mappedLinks} />;
};

export default Breadcrumb;
