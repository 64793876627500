import clsx from 'clsx';
import React, { useState } from 'react';
import { Heading } from '../Heading';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { Stack } from '../Stack';
import { Box } from '../Box';
import { AnimatedArrowComponent } from '../AnimatedArrow';
import { Hidden } from '../Hidden';
import { StackItem } from '../StackItem';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './TopTasks.css';

export const TopTasksComponent = (props: TopTasksProps) => <TopTasksView {...props} />;

export const TopTasksSitecore = (props: TopTasksProps) => {
  return <TopTasksView {...props} />;
};

export const TopTasksView = ({
  variableBlockPosition = 'end',
  variableBlockSpan = 1,
  ...props
}: TopTasksProps) => {
  const isDesktop = useMedia(mq.lg);
  const topTasksColor = props.color;
  const classNames = styles.TopTasks({ ...props, color: topTasksColor });
  const taskAmount = props.variableBlock ? 4 - variableBlockSpan : 4;

  const variableDesktopBlock =
    isDesktop && props.variableBlock ? (
      <Hidden below="lg">
        <Box
          position="relative"
          zIndex="1"
          className={styles.variableBlockTask({
            items: taskAmount as 2 | 3 | 4,
          })}
        >
          {props.variableBlock}
        </Box>
      </Hidden>
    ) : null;

  return (
    <Stack
      gap={2}
      className={clsx('topTasks', styles.tasksWrapper)}
      direction={{ initial: 'column', sm: 'row' }}
    >
      {!isDesktop && props.variableBlock && (
        <Hidden above="lg">
          <Box position="relative" zIndex="1" className={styles.variableBlockMobile}>
            <Stack direction={{ initial: 'column', lg: 'row' }} alignX="justify" wrap isFullHeight>
              {props.variableBlock}
            </Stack>
          </Box>
        </Hidden>
      )}
      <Box borderRadius={{ initial: 'lg', lg: 'brandXl' }} overflow="hidden" asChild>
        <Stack
          direction={{ initial: 'column', lg: 'row' }}
          isFullWidth
          className={styles.taskBlock}
        >
          {variableBlockPosition === 'start' && variableDesktopBlock}
          {props.tasks.slice(0, taskAmount).map((task, i) => (
            <Box
              key={'toptask-view-' + i}
              paddingTop={{ initial: 6, lg: 12 }}
              paddingBottom={{ initial: 6, lg: 12 }}
              paddingLeft={{ initial: 10, lg: 12 }}
              paddingRight={{ initial: 10, lg: 12 }}
              className={styles.task}
              asChild
            >
              <Stack
                className={clsx(classNames)}
                alignX="justify"
                alignY="center"
                wrap
                direction={{ initial: 'row', lg: 'column' }}
                asChild
                gap={2}
              >
                <StackItem asChild grow>
                  <Stack
                    className={styles.TopTaskLink}
                    direction={{ initial: 'row', lg: 'column' }}
                    alignX="center"
                    alignY="center"
                  >
                    <SingleTopTaskInner
                      isAnchor={task.isAnchor}
                      text={task.text}
                      link={task.link}
                      arrowLink={task.arrowLink}
                      mobileVariant={task.mobileVariant}
                    />
                  </Stack>
                </StackItem>
              </Stack>
            </Box>
          ))}
          {variableBlockPosition === 'end' && variableDesktopBlock}
        </Stack>
      </Box>
    </Stack>
  );
};

export const SingleTopTaskInner = ({
  mobileVariant = 'row',
  ...props
}: SingleTopTaskInnerProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isRowVariant = mobileVariant === 'row';

  return (
    <Stack
      isFullHeight
      isFullWidth
      direction={isRowVariant ? { initial: 'row', lg: 'column' } : 'column'}
      asChild
      alignY={{ initial: 'center' }}
      alignX={{ lg: 'start' }}
    >
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Box paddingRight={{ initial: 8, lg: 0 }} asChild>
          <StackItem grow asChild>
            <Stack gap={7}>
              <Heading
                size={{ initial: 'description', md: 'paragraph' }}
                fontWeight="semiBold"
                as="h2"
                color="onDark"
                isResponsive={false}
              >
                <ToneFocus tone="onDark">{props.link}</ToneFocus>
              </Heading>

              {props.text ? <Box paddingBottom={6}>{props.text}</Box> : null}
            </Stack>
          </StackItem>
        </Box>
        <Stack
          asChild
          alignX={isRowVariant ? { initial: 'end', lg: 'start' } : 'start'}
          alignY={{ initial: 'center', lg: 'end' }}
        >
          <AnimatedArrowComponent
            color="ghostOnDark"
            size={{ initial: 'medium', lg: 'large' }}
            direction={props.isAnchor ? 'topToBottom' : 'leftToRight'}
            active={isHovered}
            link={props.arrowLink}
          />
        </Stack>
      </div>
    </Stack>
  );
};

export type TaskBaseProps = {
  variableBlockSpan?: 1 | 2;
  variableBlock?: React.ReactElement;
  variableBlockPosition?: 'start' | 'end';
} & styles.TopTasksVariants;

export type SingleTopTaskInnerProps = {
  isAnchor?: boolean;
  text?: React.ReactElement;
  link: React.ReactElement;
  arrowLink?: React.ReactElement;
  mobileVariant?: 'row' | 'column';
};

type TopTasksProps = {
  tasks: SingleTopTaskInnerProps[];
} & TaskBaseProps;

