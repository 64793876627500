import { Button } from '@ads-core/components';
import { ButtonFormField } from '@sitecore-jss/sitecore-jss-forms';
import { FieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { useIsConditionallyHidden } from '../hooks';
import { PropsWithConditions } from '../types';

type Props = PropsWithConditions<FieldProps<ButtonFormField>>;

export const ButtonMapper = ({ field }: Props) => {
  const value = field.model.title;
  const { formState } = useFormContext();

  const { fieldKey } = field.model.conditionSettings;
  const isHidden = useIsConditionallyHidden({ fieldKey });

  if (isHidden) return null;

  return (
    <Button
      disabled={formState.isSubmitting}
      isLoading={formState.isSubmitting}
      type="submit"
      className={field.model.cssClass}
      value={value}
      name={field.buttonField.name}
      style={{ alignSelf: 'flex-start' }}
    >
      {value}
    </Button>
  );
};
