import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYBToAo7AGd4k9inry2PRPygkS3KcOLZjOYmTov9ejDZuIZJToDEi2GfOXDjiIaf4A4edPqBW8OdPAHd3t98YYEbfqST/6wSnALP1+DfFdYBZSbjyyyluAsyU8tW9nuI2wMyskcxiijshjhfylgJeCXgtxG/GOq31bopvhPpbIX4n4L2Ab4U+Pwj17IT6H4U4T0KcvbCuZ6HOg4AfBfwk4C8CfhbqHwT+ReC/Cvw3AX8X4lwDzHrLnyn+wThujOqn+GeAGdbNptpMcVRCYmSFre/XZq0iAwmvDPU/terYx0hZrJTFCQtHL+wwZKmtFsvlKpIsstbcAitXRgYWm12gtVVkaAT1oyQ3ZL0tq9ViHRu60cOV5Soy9D/auPBxS7ZSf1lzfvnd6LgqFt3KrtUyXiCrrmt737WRgWXX2d71sYF11/e96aJ9hSy8vu3aJNRBaslRWsdJ8hjFd195jHvF6lsqX6m4V0OAy7k+DKf63B0ukVHSIL7+l5ekRHyXNt5V2i0f0kI/hdaQEuRAKCQn6cIjLd2QRugyWckgaZGkW49KqVzWInW+1RQZWIuKyrLFyNCMe9htVOzBWmw19RQdddQKOiHW4r1WSsXd7QOo0xVQ8bPkx3nb1L9oPwfCOeg5qELRt/nIMlh4793pCloXhgjzdDTae/zyISq0dppz+KJ0leCCFdHNBamwhG4sq1DoSfD5orvCWcWl2EKTUmWerUrlfeluVRXeeXLjN0JdCitR1hFV+C1q2jbA7jB0F1Djh53zu+QhQ8zuml0YjcNxv2uFWI8TSjbKUwDuVVsPD10ryGk/5eRESs8BzOkagQfxjqN/R43EdJLk8CKcECTNGzQIhw1dpBzSyEFvUo534bilq6SsD0lZfNI1bVs1XTSP80lXb9qmj2Q9TvZ901IdTSrjaN/rpktC6VG9EWjGNxyBNvNKeaQvE9AHUAzf/iNzyeYf4rw9IkYVwKqfI7DOVdrkitrkimoDYLqoLuTy9wEozbUNQGmyhwCUZtsF0Gm2xwA6zfYUwKTZ9gFMGvc5gE0jHAK4tLJjAJ/WcArjIR2hLwGqNO6Ze5aSB25aCl+4ayn8GuD38/G6e67ht+NbPYf7867ez2GoD8Pd0J130RXE4/z/c3gP4GLx8JBvEvAj29TPAFhEV6dRubdiMEel3Ms2mqnR0WRMbrcYm6O63HYzPkctA/BNGqFVjloHwLSAhqnR1Go2OW2YNkdlGaW19l/Uv/4GI4wnl4oQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/Divider/Divider.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/Divider/Divider.css.ts.vanilla.css\",\"source\":\"LmhlaTRzMCB7CiAgYm9yZGVyOiAwOwogIG1hcmdpbjogMDsKICBoZWlnaHQ6IDFweDsKfQouaGVpNHMxIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXNpM24xMzJuKTsKfQouaGVpNHMyIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXNpM24xMzJvKTsKfQouaGVpNHMzIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiBsaW5lYXItZ3JhZGllbnQodG8gcmlnaHQsIHZhcigtLV8xc2kzbjEzMm4pIDEwMCUsIHZhcigtLV8xc2kzbjEzMm4pIDAlKTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/Footer/Footer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/Footer/Footer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41Uy27bMBC8+ysWBoLIiOlQkd0kNNpDDz31AwoUgUGJa5oNTaok/UiK/HshynYkv2/icnd2hzOrwSR9W/8tp5LCvw5AzotX6ezCCAYLp5Pbey48KazDezXnEv39T8WNQDf5YW1AN/m+Kxj8KeVtb9wBKKy2jsGSu4SQSepVZtLsIV7ZJbqptisGMyUEmipWWq+CsoaBQ82DWmIVfSfKCFwzSMetuYhX78igqJDGnY/OYEsgjQScXRHJSwZpRsv1KfhG2UMsm3MnlSG5tsUrwYp9e/qs7LWqslh1hmjAdSACC+t43dxY0248bDZWRiuDRztj7woWowi2UiLMGKSU3oz3xAyOG19yhybEK+sEuu1Ul5lwraRhoHEaTktWc2FAYwoXQhlZP+lerGYbgw0OX2oBlZyFbf6uDc+91YsQ20Qqm3j8nlo3hxGlcw/FIlcFyfFdoUvo4OmxD7QPdJBmfUjbEo5+Cx448YEH/Nq1JZruC3yDvXF2DRg4W6Um6RMVKM9hFdp6vA6MtnEeY9re0+354fUaPzwdAh0111VgzzXYhaQjz8lynFqHm10xAU1g0O1GEW25Ebmx2hsXOpnzZJj1YUj7kD1X+mVxzsp+DEa1uz/NvlxV5xnWztm6/4x5agnip+YBfyVkRG96l+lMlfOBFDOlRZPatnPBdZFU7eEOHoblurcjSqpjC5+ffNOzy9jeoGo8Fw5Elb2DBTwqvrxMGQYzVENP+3A+7a5xv62JDJfKq1xpFd4+f/kf/wGlg5f2dQYAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var copyright = '_1yxqpfg4';
export var footerNavLink = '_1yxqpfg3';
export var footerWrapper = '_1yxqpfg0';
export var grid = '_1yxqpfg1';
export var legalNavMobile = '_1yxqpfg8';
export var mobileNavItem = '_1yxqpfg9';
export var mobileNavList = '_1yxqpfga';
export var mobileSocialNav = '_1yxqpfg7';
export var navTitle = '_1yxqpfg2';
export var trigger = '_1yxqpfg5';
export var triggerIcon = '_1yxqpfg6';