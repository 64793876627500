import React from 'react';
import { mergeRefs } from 'react-merge-refs';

import { Error, InputBaseProps } from '../InputBase';

import * as buttonStyles from '../Button/Button.css';

import { Button } from '../Button';
import { Text } from '../Text';
import { UploadIcon } from '../../icons/index';
import { Stack } from '../Stack';
import { VisuallyHidden } from '../VisuallyHidden';
import * as styles from './InputFile.css';

type InputTextProps = Omit<InputBaseProps, 'type'>;

export const InputFile = React.forwardRef<HTMLInputElement, InputTextProps>(
  ({ error, label, onChange, className, ...rest }, ref) => {
    const localRef = React.useRef<HTMLInputElement>();
    const id = React.useId();
    const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);

    return (
      <Stack gap={2} alignX="start">
        <Stack asChild gap={2}>
          <label htmlFor={id}>
            <Text size="label">{label}</Text>
            <Stack
              direction={{ initial: 'column', md: 'row' }}
              gap={3}
              alignX={{ initial: 'start' }}
              alignY={{ initial: 'start', md: 'center' }}
            >
              <Button asChild>
                <span
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      if (localRef.current) {
                        localRef.current.click();
                      }
                    }
                  }}
                  role="button"
                  aria-controls={id}
                  tabIndex={0}
                  className={buttonStyles.button({
                    variant: 'ghost',
                  })}
                >
                  <UploadIcon size="sm" />
                  Kies een bestand
                </span>
              </Button>
              <div className={styles.filename}>
                {selectedFiles.length === 1 && selectedFiles[0]}
                {selectedFiles.length > 1 &&
                  `${selectedFiles.length} bestanden`}
                {selectedFiles.length === 0 && 'Geen bestand gekozen..'}
              </div>
            </Stack>
            <VisuallyHidden>
              <input
                ref={mergeRefs([ref, localRef])}
                type="file"
                id={id}
                className={className}
                onChange={(e) => {
                  const fileList = e.target.files;
                  if (fileList) {
                    const files = Array.from(fileList).map((f) => f.name);
                    setSelectedFiles(files);
                  }

                  if (onChange) {
                    onChange(e);
                  }
                }}
                {...rest}
              />
            </VisuallyHidden>
          </label>
        </Stack>
        {error && <Error>{error}</Error>}
      </Stack>
    );
  }
);
