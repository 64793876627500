import React from 'react';
import { useState } from 'react';
import { FieldActions, ActionTypes } from '../FieldActions';
import { FieldCondition } from '../types';

const useFormConditionsValue = ({ initialActionMap }: { initialActionMap: FieldActions }) => {
  const [fieldActionMap, setFieldActionMap] = useState<FieldActions>(initialActionMap);

  return {
    fieldActionMap: React.useMemo(() => fieldActionMap, [fieldActionMap]),
    setFieldActionMap: React.useCallback<typeof setFieldActionMap>(
      (value) => setFieldActionMap(value),
      []
    ),
  };
};

export const FormConditionsContext = React.createContext<
  (ReturnType<typeof useFormConditionsValue> & StaticFields) | null
>(null);

export type StaticFields = Pick<FormConditions, 'conditionsMap' | 'nameToFieldKeyMap'>;

type FormConditions = {
  children: React.ReactNode;
  initialActionMap: Record<string, keyof ActionTypes>;
  conditionsMap: Record<string, FieldCondition[]>;
  nameToFieldKeyMap: Record<string, string>;
};

export const FormConditionsProvider = ({
  children,
  initialActionMap,
  conditionsMap,
  nameToFieldKeyMap,
}: FormConditions) => {
  const value = useFormConditionsValue({ initialActionMap });
  return (
    <FormConditionsContext.Provider value={{ ...value, conditionsMap, nameToFieldKeyMap }}>
      {children}
    </FormConditionsContext.Provider>
  );
};

export const useFormConditionsContext = () => {
  const context = React.useContext(FormConditionsContext);
  if (context === null) {
    throw new Error('useFormConditions must be used within a FormConditionsProvider');
  }
  return context;
};
