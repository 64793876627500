import React, { useId } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';

import clsx from 'clsx';
import { Stack, Text, StackItem } from '../';
import { useRadioGroup } from '../RadioGroup';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import * as styles from './RadioButton.css';

export const RadioButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, value, className, ...rest }, ref) => {
    const id = useId();
    const { ariaDescribedby, tone } = useRadioGroup();

    return (
      <label htmlFor={id} className={styles.label}>
        <Stack gap={2} direction="row" alignY="start">
          <StackItem shrink={false}>
            <ToneFocus tone={tone}>
              <RadioGroup.Item
                id={id}
                ref={ref}
                value={value}
                aria-describedby={ariaDescribedby}
                className={clsx(styles.radioBase, className)}
                {...rest}
              >
                <RadioGroup.Indicator className={styles.indicator} />
              </RadioGroup.Item>
            </ToneFocus>
          </StackItem>
          <Text>{children}</Text>
        </Stack>
      </label>
    );
  }
);

type Props = Omit<RadioGroupItemProps, 'id' | 'aria-describedby'> & {
  children: string;
} & ToneVariants;
