import { FormResult } from '@sitecore-jss/sitecore-jss-forms';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { decodeNameToSitecoreFormat, encodeNameToReactHookFormFormat } from './utils/utils';

export function handleValidationErrors(
  result: FormResult,
  mappedPrefix: string,
  setError: UseFormReturn<FieldValues, any, undefined>['setError']
) {
  if (result.validationErrors) {
    Object.entries(result.validationErrors).forEach(([key, error]) => {
      if (key === decodeNameToSitecoreFormat(mappedPrefix)) {
        setError(`root.${mappedPrefix}`, {
          message: JSON.stringify([...new Set(error)] || []),
        });

        return;
      }

      setError(encodeNameToReactHookFormFormat(key), { message: error.join(', ') });
    });
  }
}
