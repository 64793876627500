import React from 'react';
import { TextField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { CalamityBanner } from '@ads-core/components';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';

type TextValue = TextField['value'];

type CalamitiesBannerJssResponse = {
  fields: {
    calamitiesBanners: {
      text: TextValue;
      link: LinkField['value'];
    }[];
  };
};

const CalamitiesBanner = (props: CalamitiesBannerJssResponse) => {
  return props.fields.calamitiesBanners
    .filter(
      (data): data is { text: NonNullable<TextValue>; link: LinkField['value'] } => !!data.text
    )
    .map((data) => {
      const hasLink = !!data.link.href && !!data.link.text;

      return (
        <CalamityBanner
          text={data.text.toString()}
          key={data.text.toString()}
          link={
            hasLink ? (
              <InternalOrExternalLink href={data.link.href} target={data.link.target}>
                {data.link.text}
              </InternalOrExternalLink>
            ) : undefined
          }
        />
      );
    });
};

export default CalamitiesBanner;
