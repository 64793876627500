import { CheckmarkIcon, CloseIcon } from '../../icons';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { congestionMarker } from './CongestionMarker.css';

export type CongestionMarkerProps = {
  titel: string;
  congestion?: boolean;
};

export const CongestionMarker = ({
  titel,
  congestion,
}: CongestionMarkerProps) => {
  return (
    <Stack
      className={congestionMarker({ congestion: congestion })}
      alignY="center"
      direction="row"
      gap={2}
    >
      {congestion ? <CheckmarkIcon size="sm" /> : <CloseIcon size="sm" />}
      <Text size="label">{titel}</Text>
    </Stack>
  );
};
