import React from 'react';
import { IconButton, Stack, ThemeContainer } from '@ads-core/components';

import * as Popover from '@radix-ui/react-popover';
import { SearchIcon } from '@ads-core/icons';
import PreviewSearchBasicWidget from 'src/jss/components/PreviewSearch';
import { useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';
import * as styles from './SearchSuggestions.css';

const SearchSuggestions = React.forwardRef<HTMLDivElement, Record<string, never>>((_, ref) => {
  const isDesktop = useMedia(mq.md);
  return (
    <div ref={ref}>
      <Popover.Root>
        <Stack isFullHeight alignY="center" alignX="center">
          <Popover.Trigger asChild>
            <IconButton
              icon={<SearchIcon color="onLight" size={isDesktop ? 'md' : 'sm'} />}
              label="Zoeken"
              variant="transparant"
              shape="squircle"
              size={{ initial: 'small' }}
            />
          </Popover.Trigger>
        </Stack>

        <Popover.Portal>
          <ThemeContainer>
            <Popover.Content align="end" asChild>
              <div className={styles.contentWrapper}>
                <div className={styles.menuIndicator}>
                  <div className={styles.menuArrow} />
                </div>
                <div className={styles.content}>
                  <PreviewSearchBasicWidget rfkId="rfkid_7" />
                </div>
              </div>
            </Popover.Content>
          </ThemeContainer>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
});

SearchSuggestions.displayName = 'SearchSuggestions';
export default SearchSuggestions;
