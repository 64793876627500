import React from 'react';
import { ImageWithTextSitecore, ScrollLinkWrapper } from '@ads-core/components';
import type { TextField, ImageFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText } from '@alliander-fe/jss-utils';

type ImageWithTextProps = ComponentProps & {
  fields: {
    text: TextField;
    image?: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
  };
  params?: {
    anchor?: string;
  };
};

const ImageWithText = (props: ImageWithTextProps) => {
  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <ImageWithTextSitecore
        text={<SitecoreText field={props.fields.text} editable />}
        image={<SitecoreImage field={props.fields.image} editable />}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(ImageWithText);
