import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYBToAo7AGd4k9inry2PRPygkS3KcOLZjOYmTov9ejDZuIZJToDEi2GfOXDjiIaf4A4edPqBW8OdPAHd3t98YYEbfqST/6wSnALP1+DfFdYBZSbjyyyluAsyU8tW9nuI2wMyskcxiijshjhfylgJeCXgtxG/GOq31bopvhPpbIX4n4L2Ab4U+Pwj17IT6H4U4T0KcvbCuZ6HOg4AfBfwk4C8CfhbqHwT+ReC/Cvw3AX8X4lwDzHrLnyn+wThujOqn+GeAGdbNptpMcVRCYmSFre/XZq0iAwmvDPU/terYx0hZrJTFCQtHL+wwZKmtFsvlKpIsstbcAitXRgYWm12gtVVkaAT1oyQ3ZL0tq9ViHRu60cOV5Soy9D/auPBxS7ZSf1lzfvnd6LgqFt3KrtUyXiCrrmt737WRgWXX2d71sYF11/e96aJ9hSy8vu3aJNRBaslRWsdJ8hjFd195jHvF6lsqX6m4V0OAy7k+DKf63B0ukVHSIL7+l5ekRHyXNt5V2i0f0kI/hdaQEuRAKCQn6cIjLd2QRugyWckgaZGkW49KqVzWInW+1RQZWIuKyrLFyNCMe9htVOzBWmw19RQdddQKOiHW4r1WSsXd7QOo0xVQ8bPkx3nb1L9oPwfCOeg5qELRt/nIMlh4793pCloXhgjzdDTae/zyISq0dppz+KJ0leCCFdHNBamwhG4sq1DoSfD5orvCWcWl2EKTUmWerUrlfeluVRXeeXLjN0JdCitR1hFV+C1q2jbA7jB0F1Djh53zu+QhQ8zuml0YjcNxv2uFWI8TSjbKUwDuVVsPD10ryGk/5eRESs8BzOkagQfxjqN/R43EdJLk8CKcECTNGzQIhw1dpBzSyEFvUo534bilq6SsD0lZfNI1bVs1XTSP80lXb9qmj2Q9TvZ901IdTSrjaN/rpktC6VG9EWjGNxyBNvNKeaQvE9AHUAzf/iNzyeYf4rw9IkYVwKqfI7DOVdrkitrkimoDYLqoLuTy9wEozbUNQGmyhwCUZtsF0Gm2xwA6zfYUwKTZ9gFMGvc5gE0jHAK4tLJjAJ/WcArjIR2hLwGqNO6Ze5aSB25aCl+4ayn8GuD38/G6e67ht+NbPYf7867ez2GoD8Pd0J130RXE4/z/c3gP4GLx8JBvEvAj29TPAFhEV6dRubdiMEel3Ms2mqnR0WRMbrcYm6O63HYzPkctA/BNGqFVjloHwLSAhqnR1Go2OW2YNkdlGaW19l/Uv/4GI4wnl4oQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA52U3W6DMAyF7/sU1qRJ7UUmILTdspu+yRSCKdFowpLQsU1994mfotCWje0ObJ/PziFm94ofmeEHtPC2kftqHcDXAiAz+tA+ABSYOQYkDIL75wXAaQHg9CgXdPHTYncJC2/DggmQ1+QGLGprLyT+WFN9brDo7cHmHPKhI6zbbMLF697oSqVE6EIbBkduloS8hFZSFdIwWT17GqZcTkQui3QZreYB+BSAzgQ8TQHimYDtGJDrI5p/HH4zT7Mfaf5hWDYFmGsYTgHmGibGgD8YNjr8ttUcpCLvMnU5A7qJy9oreOwucYE1SbiVlsG6ub5D/ukqT+kDpZT6RfyqKFr7+eSXvOi2EmtHUhTacCe1YqC0wmZjSm1lFzFYcCePONJ23XlRMKiURddIRGVs402ppXJoxvWMJZhpg61OaOVQOQZ3d+NePLG6qFw7gdNlv73eIveOnvc9R7nPvf2//lDOcGVLblC1M34SqVKsW9owXjpYxSD04pkXp018d8BUcrDCICrgKoWl95nDOA7KurtrPSHuf0UDu3lpJOfBo0Yy/O1+xG83jxd07Ok+8OzEuWfUw78B/jg4/jAGAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});