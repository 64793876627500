import { collectActionData } from './FieldActions';
import { replaceNamesWithFieldKeys } from './FieldMapping';
import { Fields, NarrowFieldTypeWithKey, SimpleRecord, ValueFieldWithCondition } from './types';
import { encodeNameToReactHookFormFormat } from './utils/utils';

/**
 * To collect initialValues we need to go through every field and get its defaultValue of the model.value.
 * If its a ListView we need the selected item of the model.items
 */
type MappedValueFields = NarrowFieldTypeWithKey<'valueField'>;
type GetItemsModel<T> = T extends { model: { items: unknown } } ? T : never;

export function createInitialValues(fields: Fields) {
  const isListViewModel = (field: MappedValueFields): field is GetItemsModel<MappedValueFields> => {
    return 'items' in field.model;
  };

  return Object.fromEntries(
    fields
      .filter((f): f is MappedValueFields => 'valueField' in f)
      .map((f) => {
        const name = encodeNameToReactHookFormFormat(f.valueField.name);
        const defaultValue = isListViewModel(f)
          ? f.model.items.filter((item) => item.selected).map((item) => item.value)
          : f.model.value;

        return [name, defaultValue];
      })
  );
}

/**
 * Actions are responsible for showing/hiding fields. Want to know which fields should be hidden or shown on the initial render to prevent layout shifts. That's why we collect all action data to insert into the ContextProvider, so they can be fired before the first render of all the fields. This way we can prevent layout shifts.
 */

type CreateInitialActions = {
  initialValues: SimpleRecord;
  fields: ValueFieldWithCondition[];
  nameToFieldKeyMap: SimpleRecord;
};
export function createInitialActions({
  initialValues,
  fields,
  nameToFieldKeyMap,
}: CreateInitialActions) {
  // Create an object where we map all the fieldKeys to the values of initialValues
  const mappedInitialValues = replaceNamesWithFieldKeys(initialValues, nameToFieldKeyMap);

  const mappedEntries = Object.keys(initialValues).map((name) => {
    const field = fields.find((f) => encodeNameToReactHookFormFormat(f.valueField.name) === name);

    if (!field) return [];

    return collectActionData({
      fieldConditions: field.model.conditionSettings.fieldConditions,
      values: mappedInitialValues,
    });
  });

  return Object.fromEntries(mappedEntries.flat());
}
