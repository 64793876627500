import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { createDynamicVars } from '../../global/utils';
import { InternalComponentProps } from '../Slot';
import * as styles from './Image.css';

export type ImageProps = {
  aspectRatio?: `${number} / ${number}`;
  children: React.ReactNode;
} & styles.ImageRecipeVariants &
  InternalComponentProps & { asChild?: true };

export const Image = React.forwardRef<HTMLDivElement, ImageProps>(
  (
    {
      aspectRatio,
      isCover,
      objectFit,
      style,
      className = '',
      asChild,
      children,
    },
    ref
  ) => {
    // Classnames from the recipe
    const recipeClassNames = styles.imageRecipe({ isCover, objectFit });

    // Dynamic runtime values
    const dynamicVariants = createDynamicVars([
      {
        value: aspectRatio,
        vars: Object.values(styles.aspectRatioVars.extractedVars),
      },
    ]);

    const Component = asChild ? Slot : 'div';

    return (
      <Component
        className={clsx(
          { [styles.aspectRatio]: !!aspectRatio },
          recipeClassNames,
          className
        )}
        style={{ ...dynamicVariants, ...style }}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
