import { Alert, Box } from '@ads-core/components';
import { useAdsContext } from '@ads-core/providers';
import { scrollToElement } from '@ads-core/utils';
import React from 'react';

export const FormErrors = ({ errors, id }: { errors: string[]; id: string }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const adsContext = useAdsContext();
  const hasErrors = errors.length;

  React.useEffect(() => {
    if (hasErrors) scrollToElement(ref, adsContext.topHeaderHeight);
  }, [adsContext.topHeaderHeight, hasErrors]);

  return (
    <Box paddingBottom={hasErrors ? 4 : 0}>
      <Alert id={id} ref={ref} role="alert">
        {hasErrors ? (
          <ul>
            {errors.map((error, index) => (
              <li key={`${error}-${index}`}>{error}</li>
            ))}
          </ul>
        ) : null}
      </Alert>
    </Box>
  );
};
