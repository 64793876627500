import { operators, actionTypes, matchTypes } from './mappers/typeMapper';
import { FieldCondition, SimpleRecord } from './types';

/**
 * Note:
 * With CheckboxListField, the value is an array of strings, so we can't do a simple === comparison.
 * We need to check if the array includes the comparisonValue
 */
function compareValues(operator: string, value: string, comparisonValue: string) {
  switch (operator) {
    case operators.IS_EQUAL_TO:
      return Array.isArray(value) ? value.includes(comparisonValue) : value === comparisonValue;
    case operators.IS_NOT_EQUAL_TO:
      return Array.isArray(value) ? !value.includes(comparisonValue) : value !== comparisonValue;
    case operators.CONTAINS:
      return new RegExp(comparisonValue).test(value);
    case operators.DOES_NOT_CONTAIN:
      return !new RegExp(comparisonValue).test(value);
    case operators.STARTS_WITH:
      return value.startsWith(comparisonValue);
    case operators.DOES_NOT_START_WITH:
      return !value.startsWith(comparisonValue);
    case operators.ENDS_WITH:
      return value.endsWith(comparisonValue);
    case operators.DOES_NOT_END_WITH:
      return !value.endsWith(comparisonValue);
    case operators.IS_GREATER_THAN:
      return value > comparisonValue;
    case operators.IS_GREATER_THAN_OR_EQUAL_TO:
      return value >= comparisonValue;
    case operators.IS_LESS_THAN:
      return value < comparisonValue;
    case operators.IS_LESS_THAN_OR_EQUAL_TO:
      return value <= comparisonValue;
    default:
      console.error(`Invalid operator: ${operator}`);
      return 'INVALID_OPERATOR';
  }
}

function getAction(actionTypeId: string): keyof ActionTypes | 'INVALID_ACTION_TYPE' {
  switch (actionTypeId) {
    case actionTypes.SHOW:
      return 'SHOW';
    case actionTypes.HIDE:
      return 'HIDE';
    case actionTypes.ENABLE:
      return 'ENABLE';
    case actionTypes.DISABLE:
      return 'DISABLE';
    case actionTypes.GO_TO_PAGE:
      return 'GO_TO_PAGE';
    default:
      console.error(`Invalid action type: ${actionTypeId}`);
      return 'INVALID_ACTION_TYPE';
  }
}

/**
 * For every fieldCondition, check if all/any (`matchTypeId`) conditions are met (by comparing the value if the `fieldId` with the `value` of the condition through the `operatorId`)
 * If all/any conditions are met, execute the actions. Every action has an `actionTypeId` and a `fieldId` (fieldKey)
 * The `actionTypeId` is used to determine the action (show/hide/etc) and the `fieldId` is used to determine which field should be affected by the action
 * Every field has a unique fieldKey in the conditionSettings, which is referenced in the `fieldId` in the action and the `fieldId` the each codition
 */
export const collectActionData = ({ fieldConditions = [], values }: ExecuteAction) => {
  let keysWithActions: string[][] = [];

  if (fieldConditions.length > 0) {
    fieldConditions.forEach(({ conditions, matchTypeId, actions }) => {
      const matched =
        matchTypeId === matchTypes.ALL
          ? conditions.every((c) => compareValues(c.operatorId, values[c.fieldId], c.value))
          : conditions.some((c) => compareValues(c.operatorId, values[c.fieldId], c.value));

      if (matched) {
        const keys = actions.map((action) => {
          const actionType = getAction(action.actionTypeId);
          return [action.fieldId, actionType];
        });

        keysWithActions = [...keysWithActions, ...keys];
      }
    });
  }

  return keysWithActions;
};

type ExecuteAction = {
  fieldConditions: FieldCondition[];
  values: SimpleRecord;
};

export type ActionTypes = typeof actionTypes;
export type FieldActions = Record<string, keyof ActionTypes>;
