import { Heading } from '../Heading';
import { GridItem } from '../GridItem';
import { PageGrid } from '../PageGrid';
import { Box } from '../Box';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { ButtonLink } from '../ButtonLink';
import { StackItem } from '../StackItem';
import { ArrowRightIcon } from '../../icons';

import * as styles from './Iframe.css';

export type IframeContentBlockProps = {
  title: string;
  text: string;
  button?: {
    anchor?: string;
    class?: string;
    href: string;
    id?: string;
    linktype?: string;
    querystring?: string;
    target?: string;
    text: string;
    title: string;
    url?: string;
  };
};

type IframeProps = {
  items: {
    title: string;
    source: string;
    width: string;
    height: string;
  }[];
  footer: React.ReactNode;
  contentBlock?: IframeContentBlockProps;
};

function processWidth(input: string) {
  const numericValue = parseFloat(input);

  if (isNaN(numericValue)) {
    return '100%';
  }

  if (input.endsWith('%')) {
    return `${numericValue}%` as const;
  }

  if (input.endsWith('px')) {
    return `${numericValue}px` as const;
  }

  return '100%';
}

const IframeContentBlock = ({
  title,
  text,
  button,
}: IframeContentBlockProps) => {
  const isDesktop = useMedia(mq.md);

  return (
    <Box paddingBottom={6} asChild>
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        gap={6}
        alignX="justify"
        alignY="center"
        isFullWidth
      >
        <StackItem className={styles.content}>
          <Stack direction="column" gap={4}>
            <Heading size="h2">{title}</Heading>
            <Text>{text}</Text>
          </Stack>
        </StackItem>
        {button ? (
          <StackItem className={styles.button} grow asChild>
            <ButtonLink
              href={button.href}
              target={button.target}
              title={button.title}
              variant="ghost"
              afterIcon={<ArrowRightIcon size="sm" />}
              asChild
            >
              {button.text}
            </ButtonLink>
          </StackItem>
        ) : null}
      </Stack>
    </Box>
  );
};

export const Iframe = ({ items, footer, contentBlock }: IframeProps) => {
  const isTablet = useMedia(mq.sm);

  return (
    <PageGrid>
      {contentBlock ? (
        <GridItem>
          <IframeContentBlock {...contentBlock} />
        </GridItem>
      ) : null}
      <GridItem asChild>
        <Stack
          alignX="center"
          gap={10}
          isFullWidth
          direction={{ initial: 'column', md: 'row' }}
          isFullHeight
        >
          {items.map(({ title, source, width, height }, index) => (
            <Stack alignY={'justify'} asChild key={`${title}-${index}`}>
              <Box
                width={isTablet ? processWidth(width) : '100%'}
                height={'100%'}
              >
                <Box asChild paddingBottom={{ initial: 6 }}>
                  <Heading size="h5" as={contentBlock ? 'h3' : 'h2'}>
                    {title}
                  </Heading>
                </Box>
                <Box asChild borderRadius="brandXl">
                  <iframe
                    title={title}
                    src={source}
                    width="100%"
                    height={height}
                  />
                </Box>
              </Box>
            </Stack>
          ))}
        </Stack>
      </GridItem>
      {footer ? <GridItem>{footer}</GridItem> : null}
    </PageGrid>
  );
};
