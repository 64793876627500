import React from 'react';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';

type CookieBotProps = {
  cbid: string;
};

declare global {
  interface Window {
    Cookiebot?: {
      consent: {
        [key: string]: boolean | string | null;
        marketing: boolean;
        method: string | null;
        necessary: boolean;
        preferences: boolean;
        stamp: string;
        statistics: boolean;
      };
      declined: boolean;
      consented: boolean;
      changed?: boolean;
      renew: () => void;
    };
  }
}

export function CookieBotBanner(props: CookieBotProps) {
  return (
    <script
      id="Cookiebot banner"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid={props.cbid}
      data-blockingmode="auto"
      type="text/javascript"
      async
    />
  );
}

export function CookieBotDeclaration(props: CookieBotProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [didLoadScript, setDidLoadScript] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && !didLoadScript) {
      const script = document.createElement('script');
      script.src = `https://consent.cookiebot.com/${props.cbid}/cd.js`;
      script.id = 'CookieDeclaration';
      script.async = true;
      ref.current.appendChild(script);

      setDidLoadScript(true);
    }
  }, [didLoadScript, props.cbid]);

  return (
    <PageGrid>
      <Box overflow={'hidden'} asChild>
        <GridItem
          columnStart={{ initial: '1', md: '3' }}
          columnEnd={{ initial: '-1', md: '-3' }}
        >
          <Box width={'100%'} ref={ref} />
        </GridItem>
      </Box>
    </PageGrid>
  );
}
