export const validationModels = {
  CONDITIONALLY_REQUIRED: '{4945A57A-EC09-449C-A01E-132ACBAE82EC}',
  FILE_SIZE: '{8F2B6077-FE63-4FB2-8740-EF3807BBDB84}',
  FILE_TYPE: '{D81E056F-6C2E-4414-BD46-BA464AC05202}',
  FILE_COUNT: '{F42833A6-F364-4721-ABFC-337E0CC3FD75}',
  EXACT_18_NUMBERS: '{0FCF99E8-C0BD-4884-93AD-F9FD9A2BB07F}',
  EXACT_8_NUMBERS: '{91B2C642-90E9-448E-8AC1-F71A13D1175E}',
  ONLY_NUMBERS: '{3AEF56B9-E348-4DFE-BAB8-25B9836A7FC7}',
  ALL_CHECKBOXES_REQUIRED: '{89AEEBBE-18ED-4F45-8D40-C8A2B87F466C}',
  IS_IBAN: '{7F6D3506-73D7-46F0-8D3A-12737F3D5980}',
  IS_POSTAL_CODE: '{2B272B8B-DB06-4FD4-9712-B187A994FE41}',
  IS_ADRES_BLOCK_POSTAL_CODE: '{E58D72F0-24F4-4A7C-BA85-DF4E029F7C81}',
  IS_EMAIL: '{9BAE3E9A-D89F-4F93-9577-68B5E9D44A38}',
  IS_PHONE_NUMBER: '{1AA74652-2177-48C9-88F5-1214445FEA34}',
  IS_MIN_MAX: '{83E96D09-11C8-4132-8A8A-76D8DB8208D6}',
  IS_NAME: '{9109821C-9443-4E7C-8BC4-6BE840324F8B}',
  MIN_DAYS_IN_FUTURE: '{4DE6365E-EBAC-4C37-A18D-C38D50638EF6}',
  MAX_DAYS_IN_FUTURE: '{75C8801A-5EEB-4ACE-93C0-152AAFC186B6}',
} as const;
