import { useRouter } from 'next/router';
import { getQueryParams } from '../utils/query';

/**
 *
 * @returns the values of the query parameters
 * This hook is a React hook wrapper, coupled to the Nextjs router, around the getQueryParams function
 */
export function useParams() {
  const router = useRouter();
  return getQueryParams(router.query);
}

