import React from 'react';
import { Heading } from '../Heading';
import { ButtonLink } from '../ButtonLink';
import { Tag } from '../Tag';
import { Stack } from '../Stack';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { AccordionView } from '../Accordion';
import { CombineProps } from '../../types/type-helpers';

type FaqProps = {
  items: {
    question: string;
    questionName?: string | number;
    answer: string;
    id: string;
  }[];
  tag?: string;
  title?: string;
  button?: React.ReactElement;
  onAccordionOpen?: (id: string, opened: boolean) => void;
};

type FaqSitecoreProps = {
  items: {
    answer: React.ReactNode;
    question: React.ReactNode;
    questionName?: string | number;
    id: string;
  }[];
  tag?: React.ReactElement;
  title?: React.ReactElement;
  button?: React.ReactElement;
  onAccordionOpen?: (id: string, opened: boolean) => void;
};

type FaqCombinedProps = CombineProps<FaqProps, FaqSitecoreProps>;

export const Faq = (props: FaqProps) => {
  return <FaqView {...props} />;
};

export const FaqSitecore = (props: FaqSitecoreProps) => {
  return <FaqView {...props} />;
};

const FaqView = ({
  title,
  tag,
  button,
  items,
  onAccordionOpen,
}: FaqCombinedProps) => {
  return (
    <PageGrid>
      <GridItem
        asChild
        columnStart={{ initial: '1', md: '3' }}
        columnEnd={{ initial: '-1', md: '-3' }}
      >
        <Stack gap={10} alignX="start" isFullWidth>
          {tag ? <Tag>{tag}</Tag> : null}
          {title ? (
            <Heading size="h2" asChild>
              {title}
            </Heading>
          ) : null}
          {items && (
            <AccordionView items={items} onAccordionOpen={onAccordionOpen} />
          )}
          {button ? (
            <ButtonLink asChild variant="ghost">
              {button}
            </ButtonLink>
          ) : null}
        </Stack>
      </GridItem>
    </PageGrid>
  );
};
