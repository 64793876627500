import React from 'react';
import ReactIs from 'react-is';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { AsChild, InternalComponentProps } from '../Slot';
import { conditions } from '../../global/breakpoints';
import { hidden } from './Hidden.css';

type MinBreakpoints = Exclude<keyof typeof conditions, 'initial'>;

type HiddenProps = {
  children: React.ReactNode;
  above?: Exclude<MinBreakpoints, 'initial'>;
  below?: Exclude<MinBreakpoints, 'initial'>;
  hide?: boolean;
} & InternalComponentProps &
  AsChild;

export const Hidden = React.forwardRef<HTMLDivElement, HiddenProps>(
  (props, ref) => {
    const { above, below, children, hide, asChild, as = 'div' } = props;
    const breakpoints: Partial<Record<string, 'none'>> = {};

    if (below && !above) {
      breakpoints[`${below}Max`] = 'none';
    }

    if (above && !below) {
      breakpoints[above] = 'none';
    }

    const hiddenClassName = hidden({
      display: hide ? 'none' : breakpoints,
    });

    const Component = asChild ? Slot : as;

    const arrayChildren = React.Children.toArray(children);
    const hiddenChildren = React.Children.map(arrayChildren, (child) => {
      if (ReactIs.isElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          className: clsx(hiddenClassName, child.props.className),
          ref: ref,
        });
      }

      return (
        <Component className={clsx(hiddenClassName, props.className)} ref={ref}>
          {child}
        </Component>
      );
    });

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{hiddenChildren}</>;
  }
);
