import React from 'react';
import { Box } from '../Box';

import * as styles from './Card.css';

type CardProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
};
export const Card = ({ children, footer }: CardProps) => {
  return (
    <Box as="article" borderRadius="lg" overflow="hidden" width="100%">
      <Box
        bg="backgroundLight"
        paddingTop={{ initial: 5, md: 10, lg: 10 }}
        paddingBottom={{ initial: 5, md: 10, lg: 10 }}
        paddingLeft={{ initial: 5, md: 10, lg: 16 }}
        paddingRight={{ initial: 5, md: 10, lg: 16 }}
      >
        {children}
      </Box>
      {footer ? (
        <Box as="footer" className={styles.footer}>
          {footer}
        </Box>
      ) : null}
    </Box>
  );
};
