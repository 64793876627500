import { Heading, Box, Stack, Text } from '@ads-core/components';
import { ChevronRightIcon } from '@ads-core/icons';
import useMedia from 'react-use/lib/useMedia';
import { mq } from '@ads-core/breakpoints';

import { legend, legendItem, legendList, LegendVariants } from './CongestionCardLegend.css';

type LegendItemProps = {
  title: string;
  description: string;
} & LegendVariants;

const CapacityLegendItem = (props: LegendItemProps) => {
  const { color, title, description } = props;
  const isTablet = useMedia(mq.md);
  const isDesktop = useMedia(mq.lg);

  return (
    <li className={legendItem}>
      <Stack direction="row"  alignY={{ initial:"start", md: "center"}}>
        <Stack direction="row" alignY="center">
          <Box className={legend({ color })} width='16px' height='16px' borderRadius='circle' />
          {!isTablet || isDesktop ? <Text size="label">{title}</Text> : null}
          <ChevronRightIcon size="xs" />
        </Stack>
        <Text size="label" weight="regular">
          {description}
        </Text>
      </Stack>
    </li>
  );
};

export const CapacityLegend = () => {
  return (
    <Box paddingTop={4} asChild>
      <Stack alignX="center" gap={4} isFullWidth>
        <Heading
          size={{ initial: 'description', md: 'paragraph' }}
          fontWeight="semiBold"
          as="h4"
          isResponsive={false}
        >
          Legenda beschikbare capaciteit
        </Heading>
        <Stack direction="row" alignX="center" className={legendList} wrap asChild>
          <ul>
            <CapacityLegendItem title="Transparant" color="transparent" description="Beschikbaar" />
            <CapacityLegendItem title="Geel" color="yellow" description="Beperkt beschikbaar" />
            <CapacityLegendItem
              title="Oranje"
              color="orange"
              description="Niet beschikbaar: Onderzoek wordt uitgevoerd "
            />
            <CapacityLegendItem title="Rood" color="red" description="Niet beschikbaar" />
          </ul>
        </Stack>
      </Stack>
    </Box>
  );
};
