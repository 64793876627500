import { IntegrationApi } from '@alliander-fe/api';
import { Box, PageGrid, GridItem, Heading, Text, TextLink } from '@ads-core/components';
import { useQuery } from '@tanstack/react-query';
import { rewriteEnergyType } from 'src/utils';
import { OutageView } from './_View';
import { Props } from '.';

type SpecificProps = {
  postalCode: string;
  houseNumber: string;
  addition: string;
} & Props;

export function OutageSpecific(props: SpecificProps) {
  const { postalCode, houseNumber, addition } = props;
  const specificOutageQuery = useQuery({
    queryKey: [postalCode, houseNumber, addition],
    queryFn: () =>
      IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails({
        postalCode,
        houseNumber: Number(houseNumber),
        addition: addition,
      }),
  });

  const referenceNumber = specificOutageQuery.data?.outageNumber?.toString() || '';

  const query = useQuery({
    enabled: Boolean(referenceNumber),
    queryKey: [referenceNumber],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetSpecificOutage({
        outageNumber: referenceNumber,
      }),
    select: (data) => {
      return {
        ...data,
        energyType: rewriteEnergyType(data.energyType),
      };
    },
  });

  const isNetworkOperator = Boolean(
    specificOutageQuery.data?.electricityNetwork?.isOperational ||
      specificOutageQuery.data?.gasNetwork?.isOperational
  );

  if (!isNetworkOperator && specificOutageQuery.isFetched)
    return (
      <PageGrid>
        <GridItem columnStart="1" columnEnd="-1">
          <Box paddingBlock={10} width="100%">
            <Box paddingBottom={3} asChild>
              <Heading size="h2" align="center">
                Liander is niet de netbeheerder op {specificOutageQuery.data?.address?.postalCode}
              </Heading>
            </Box>
            <Text size="paragraph" align="center">
              We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of
              zoek uw netbeheerder op via{' '}
              <TextLink href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">
                mijnaansluiting.nl
              </TextLink>
              .
            </Text>
          </Box>
        </GridItem>
      </PageGrid>
    );

  if (!specificOutageQuery.isFetched) return null;
  if (!specificOutageQuery.data) return null;

  return (
    <OutageView
      {...props}
      outageData={query.data || {}}
      isSuccess={query.isSuccess}
      isLoading={query.isLoading || specificOutageQuery.isLoading}
    />
  );
}
