import { isOutageCause } from "./isOutageCause";

/**
 * @param cause string | null;
 * @returns 'Storing' | 'Onderhoud'
 */
export function getCause(cause?: string | null) {
  return isOutageCause(cause) ? 'Storing' :'Onderhoud';
}

export type Cause = ReturnType<typeof getCause>;