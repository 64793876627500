import { ListFieldItem } from '@sitecore-jss/sitecore-jss-forms';
import { ValidationModels } from '../types/global';

export function allCheckboxesRequired(
  validationModel: ValidationModels[0] | undefined,
  inputValue: string[],
  checkboxes: Required<ListFieldItem>[]
): string | boolean {
  if (validationModel && inputValue.length !== checkboxes.length) {
    return validationModel?.message;
  }

  return true;
}
