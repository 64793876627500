import React from 'react';
import clsx from 'clsx';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { StackItem } from '../StackItem';
import { VisuallyHidden } from '../VisuallyHidden';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './InputBase.css';

type LabelProps = {
  htmlFor: string;
  children: React.ReactNode;
  isOptional?: boolean;
  id?: string;
} & styles.LabelVariants;

export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  htmlFor,
  children,
  tone,
  id,
}) => {
  return (
    <label htmlFor={htmlFor} className={styles.label({ tone })} id={id}>
      <Text size="label">{children}</Text>
    </label>
  );
};

type ErrorProps = {
  id?: string;
  children: string;
  className?: never;
} & styles.ErrorVariants;

export const Error: React.FC<React.PropsWithChildren<ErrorProps>> = ({
  id,
  children,
  className = '',
  tone,
}) => {
  const endsWithPunctuation = /[.?!]$/.test(children);

  return (
    <div className={clsx(className, styles.error({ tone }))}>
      <div id={id}>
        <Text>
          {children}
          {endsWithPunctuation ? null : <VisuallyHidden>{'.'}</VisuallyHidden>}
        </Text>
      </div>
    </div>
  );
};

export type InputBaseOwnProps = {
  label: string;
  name: string;
  error?: string;
} & styles.InputVariants;

export type InputBaseProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'name'
> &
  InputBaseOwnProps;

export const InputBase = React.forwardRef<HTMLInputElement, InputBaseProps>(
  ({ error, name, label, tone, className = '', ...rest }, ref) => {
    const id = React.useId();
    const errorId = error ? `${id}-error` : undefined;
    const classNames = styles.input({ tone });

    return (
      <div>
        <Stack gap={2}>
          <Label htmlFor={id} tone={tone}>
            {label}
          </Label>
          <StackItem grow>
            <ToneFocus tone={tone}>
              <input
                autoComplete="off"
                className={clsx(className, classNames)}
                name={name}
                ref={ref}
                id={id}
                aria-invalid={error ? true : undefined}
                {...rest}
              />
            </ToneFocus>
            {error ? (
              <Error id={errorId} tone={tone}>
                {error}
              </Error>
            ) : null}
          </StackItem>
        </Stack>
      </div>
    );
  }
);
