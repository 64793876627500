import React from 'react';
import { CookieAcceptBanner, VideoComponentSitecore } from '@ads-core/components';
import { Field, TextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import { useAdsContext } from '@ads-core/providers';
import { SitecoreText } from '@alliander-fe/jss-utils';

type VideoProps = ComponentProps & {
  fields: {
    url: Field<string>;
    description: TextField;
  };
};

const Video = (props: VideoProps) => {
  const adsContext = useAdsContext();
  const isEditor = props.fields?.url.editable;

  if (!isEditor && !adsContext.allCookiesAccepted && !adsContext.marketingCookiesAccepted) {
    return <CookieAcceptBanner />;
  }

  return (
    <VideoComponentSitecore
      url={props.fields?.url.value}
      description={<SitecoreText field={props.fields?.description} />}
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(Video);
