import React from 'react';
import { useState } from 'react';
import { useDialogContext } from '@ads-core/providers';
import { IntegrationApi } from '@alliander-fe/api';
import { DialogWithContent, Loader, PostalCodeCheckForm } from '@ads-core/components';

import { RichTextField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { FilterValuePropertyFromFields } from 'src/utils/mapWithoutValueProperty';

import { baseQueryParams } from 'src/utils';
import { DialogContentProps, PostalCodeCheckFormProps } from '@ads-core/types';
import { useTrackingContext } from '@liander/context';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { houseNumberPattern, postalCodePattern } from '@alliander-fe/validation';
import { TextFieldProps } from '@alliander-fe/sitecore-types';
import { extractString } from '../Form/utils/utils';

export type OutageCheckFormProps = {
  outageCheck?: FilteredOutageCheckProps;
  color?: PostalCodeCheckFormProps['color'];
};

type FormInputProps = {
  postalCode: string;
  houseNumber: string;
  addition?: string;
};

export const OutageCheckForm = ({ outageCheck, color }: OutageCheckFormProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const { trackPostalcodeCheckSuccess, trackPostalcodeCheckError } = useTrackingContext();
  const [dialogData, setDialogData] = useState<DialogContentProps>({});
  const router = useRouter();

  const { register, handleSubmit, formState } = useForm({
    mode: 'onTouched',
  });

  const redirectNoOutagesPage = outageCheck?.noOutagesPage?.href;
  const redirectOutagesPage = outageCheck?.outagePage?.href;

  const tagValue = outageCheck?.tag;

  function getTag({
    postalCode,
    houseNumber,
    addition,
  }: {
    postalCode: string;
    houseNumber: number;
    addition?: string | null;
  }) {
    const houseNumberTag = `${houseNumber}${addition && `-${addition}`}`;

    return tagValue !== ''
      ? `${tagValue} <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`
      : `Hoi bewoner op <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`;
  }

  const mutation = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails,
    onError: (error, variables) => {
      if (!variables.postalCode || !variables.houseNumber) return;
      // @ts-ignore status not available
      const message = error.status === 404 ? 'Adres niet gevonden.' : 'Er is iets misgegaan.';

      const tag = getTag({
        postalCode: variables.postalCode,
        houseNumber: variables.houseNumber,
        addition: variables.addition,
      });

      trackPostalcodeCheckError({ error: message });

      if (redirectNoOutagesPage && redirectOutagesPage) {
        openDialog();
      }

      setDialogData({
        tag,
        title: message,
      });
    },
    onSuccess: ({ electricityNetwork, address, gasNetwork, outageNumber }, variables) => {
      trackPostalcodeCheckSuccess();

      const { postalCode, houseNumber, addition } = variables;
      if (!outageCheck || !postalCode || !houseNumber) return;

      const queryAddressParams = {
        [baseQueryParams.postalCode]: postalCode,
        [baseQueryParams.houseNumber]: houseNumber,
        [baseQueryParams.city]: address?.city?.toLowerCase(),
        [baseQueryParams.houseNumberAddition]: addition 
      }

      const tag = getTag({
        postalCode,
        houseNumber,
        addition,
      });

      if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outageCheck.noOutageButton;
        // No outage, operational electricity and gas

        if (redirectNoOutagesPage) {
          router.push({
            pathname: redirectNoOutagesPage,
            query: queryAddressParams,
          });
        }

        return setDialogData({
          tag,
          title: outageCheck?.noOutageTitle ?? '',
          description: outageCheck.noOutageElectricityAndGasText,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        !electricityNetwork?.isOperational &&
        !gasNetwork?.isOperational
      ) {
        const { href, text } = outageCheck.noElectricityAndNoGasButton;
        // Not operational electricity and gas

        openDialog();

        return setDialogData({
          tag,
          title: outageCheck.noElectricityAndNoGasTitle ?? '',
          description: outageCheck.noElectricityAndNoGasText,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        !gasNetwork?.isOperational &&
        gasNetwork?.isHealthy
      ) {
        const { href, text } = outageCheck.noOutageButton;
        // No outage, operational electricity

        if (redirectNoOutagesPage) {
          router.push({
            pathname: redirectNoOutagesPage,
            query: queryAddressParams,
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.noOutageTitle,
          description: outageCheck.noOutageElectricityAndNoGasText,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        !electricityNetwork?.isOperational &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outageCheck.noOutageButton;
        // No outage, operational gas
        
        if (redirectNoOutagesPage) {
          router.push({
            pathname: redirectNoOutagesPage,
            query: queryAddressParams,
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.noOutageTitle ?? '',
          description: outageCheck.noOutageNoElectricityAndGasText,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outageCheck.outageButton;
        // Outage electricity, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              [baseQueryParams.reference]: outageNumber,
              ...queryAddressParams
            },
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.outageTitle ?? '',
          description: outageCheck.outageElectricityAndGasText,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        !gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outageCheck.outageButton;
        // Outage gas and electricity, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              [baseQueryParams.reference]: outageNumber,
              ...queryAddressParams
            },
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.outageTitle ?? '',
          description: outageCheck.outageElectricityAndGasText,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        !gasNetwork?.isOperational &&
        gasNetwork?.isHealthy
      ) {
        const { href, text } = outageCheck.outageButton;
        // Outage electricity, operational electricity

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              [baseQueryParams.reference]: outageNumber,
              ...queryAddressParams
            },
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.outageTitle ?? '',
          description: outageCheck.outageElectricityAndNoGasText,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        !electricityNetwork?.isOperational &&
        gasNetwork?.isOperational &&
        !gasNetwork?.isHealthy
      ) {
        const { href, text } = outageCheck.outageButton;
        // Outage gas, operational gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              [baseQueryParams.reference]: outageNumber,
              ...queryAddressParams
            },
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.outageTitle ?? '',
          description: outageCheck.outageNoElectricityAndGasText,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        gasNetwork?.isOperational &&
        !gasNetwork?.isHealthy
      ) {
        const { href, text } = outageCheck.outageButton;
        // Outage gas, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              [baseQueryParams.reference]: outageNumber,
              ...queryAddressParams
            },
          });
        }

        return setDialogData({
          tag,
          title: outageCheck.outageTitle ?? '',
          description: outageCheck.outageNoElectricityAndGasText,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      }

      // We shouldn't come here but we set this fallback just in case.
      return setDialogData({
        tag,
        title: 'Er is iets misgegaan.',
      });
    },
  });

  React.useEffect(() => {
    if (!isOpen)
      setDialogData({
        title: (
          <>
            Laden... <Loader />
          </>
        ),
      });
  }, [openDialog, isOpen]);

  const onSubmit: SubmitHandler<FormInputProps> = async (data: FormInputProps) => {
    const { houseNumber = '', postalCode = '', addition = '' } = data;
    const redirectNoOutagesPage = outageCheck?.noOutagesPage?.href;
    const redirectOutagesPage = outageCheck?.outagePage?.href;

    if (!redirectNoOutagesPage && !redirectOutagesPage) {
      openDialog();
    }

    mutation.mutate({
      postalCode,
      houseNumber: Number(houseNumber),
      addition,
    });
  };

  if (!outageCheck) return null;

  return (
    <>
      <PostalCodeCheckForm handleOnSubmit={handleSubmit(onSubmit)}>
        <PostalCodeCheckForm.Container color={color}>
          <PostalCodeCheckForm.Heading>
            {outageCheck.title ?? 'Controleer op actuele storingen in de buurt.'}
          </PostalCodeCheckForm.Heading>
          <PostalCodeCheckForm.Input
            label={outageCheck.postcode ?? 'Postcode'}
            tone="onDark"
            placeholder="1234AB"
            error={extractString(formState?.errors?.postalCode?.message)}
            {...register('postalCode', {
              required: {
                value: true,
                message: 'Dit veld is verplicht',
              },
              pattern: postalCodePattern,
            })}
          />
          <PostalCodeCheckForm.Addendum
            label={outageCheck.huisnr ?? 'Postcode en huisnummer'}
            tone="onDark"
            error={extractString(formState?.errors?.houseNumber?.message)}
            houseNumber={{
              label: 'huisnummer',
              placeholder: '10',
              ...register('houseNumber', {
                required: {
                  value: true,
                  message: 'Dit veld is verplicht',
                },
                pattern: houseNumberPattern,
              }),
            }}
            addition={{
              label: 'toevoeging',
              placeholder: 'A',
              ...register('addition'),
            }}
          />
          <PostalCodeCheckForm.SubmitButton size="large" tone="onDark" variant="secondary">
            {outageCheck?.button ?? 'Controleren'}
          </PostalCodeCheckForm.SubmitButton>
        </PostalCodeCheckForm.Container>
      </PostalCodeCheckForm>
      <DialogWithContent {...dialogData} />
    </>
  );
};

export type SiteCoreOutageCheckProps = {
  title: TextFieldProps;
  postcode: TextFieldProps;
  huisnr: TextFieldProps;
  button: TextFieldProps;
  tag: TextFieldProps;
  noOutageTitle: TextFieldProps;
  outageTitle: TextFieldProps;
  noElectricityAndNoGasTitle: TextFieldProps;
  noOutageButton: LinkField;
  outageButton: LinkField;
  noElectricityAndNoGasButton: LinkField;
  noOutageElectricityAndGasText: RichTextField;
  noOutageNoElectricityAndGasText: RichTextField;
  noOutageElectricityAndNoGasText: RichTextField;
  outageElectricityAndGasText: RichTextField;
  outageNoElectricityAndGasText: RichTextField;
  outageElectricityAndNoGasText: RichTextField;
  noElectricityAndNoGasText: RichTextField;
  outagePage: LinkField;
  noOutagesPage: LinkField;
};

export type FilteredOutageCheckProps = FilterValuePropertyFromFields<SiteCoreOutageCheckProps>;
