import { Checkbox, CheckboxGroup } from '@ads-core/components';
import { ListFieldItem, ListViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ListFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { Controller, useFormContext } from 'react-hook-form';
import {
  allCheckboxesRequired,
  getOptions,
  getEnabledValidation,
  validationModels,
} from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { InputViewWithParameters, PropsWithConditions } from '../types';

type Props = PropsWithConditions<ListFieldProps<InputViewWithParameters<ListViewModel>>>;

export const CheckboxListFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, control, setValue, trigger } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const checkboxFieldItems = field.model.items.filter(
    (item): item is Required<ListFieldItem> => !!item.text
  );
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  const allRequired = getEnabledValidation(
    validationModels.ALL_CHECKBOXES_REQUIRED,
    field.model.validationDataModels
  );

  const error = extractString(formState.errors[name]?.message);

  if (isHidden) return null;

  const options = getOptions(field, ['required']);

  const rules = {
    ...options,
    validate: {
      allCheckboxesChecked: (v: string[]) =>
        allCheckboxesRequired(allRequired, v, checkboxFieldItems),
    },
  };

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={(props) => {
        return (
          <CheckboxGroup
            label={getLabel(field.model.title, !!options.required)}
            ref={props.field.ref}
            error={error}
          >
            {checkboxFieldItems.map((item) => {
              return (
                <Checkbox
                  key={item.itemId}
                  label={item.text}
                  className={field.model.cssClass}
                  name={props.field.name}
                  defaultChecked={item.selected}
                  onCheckedChange={(isChecked) => {
                    // We need to manually create and set an array with the values of the checked checkboxes for Sitecore
                    const checkboxValue = item.value;
                    const currentValues: string[] = getValues(name) || [];

                    const values = isChecked
                      ? [...currentValues, checkboxValue]
                      : currentValues.filter((v) => v !== checkboxValue);

                    setValue(name, values);
                    trigger(name);
                  }}
                  onFocus={() => tracker.onFocusField(field, getValues(name))}
                  onBlur={() => {
                    tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
                    props.field.onBlur();
                  }}
                />
              );
            })}
          </CheckboxGroup>
        );
      }}
    />
  );
};
