export type CongestionLevel = 'Purple' | 'Red' | 'Yellow' | 'GridFull' | 'None' | undefined;

/**
 * Maps the CongestionLevel but because the naming doesn't match we map it to the correct names.
 *
 * @param level String | undefined for example 'NetIsVol'
 * @returns Translated levels so it will
 */
export const congestionLevel = (level: string | undefined): CongestionLevel => {
  switch (level) {
    case 'Paars':
      return 'Purple';
    case 'Rood':
      return 'Red';
    case 'Geel':
      return 'Yellow';
    case 'NetIsVol':
      return 'GridFull';
    case 'Geen':
      return 'None';
    default:
      return undefined;
  }
};
