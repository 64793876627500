import React from 'react';
import clsx from 'clsx';
import { Stack } from '../Stack';
import { InputBaseOwnProps, Label, Error } from '../InputBase';
import { StackItem } from '../StackItem';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './Textarea.css';

type Props = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'id'> &
  InputBaseOwnProps & { rows?: number };

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, name, tone, error, className, ...rest }, ref) => {
    const id = React.useId();
    const errorId = error ? `${id}-error` : undefined;
    const classNames = styles.textarea({ tone });

    return (
      <Stack gap={2}>
        <Label htmlFor={id} tone={tone}>
          {label}
        </Label>
        <StackItem grow>
          <ToneFocus tone={tone}>
            <textarea
              autoComplete="off"
              className={clsx(className, classNames)}
              name={name}
              ref={ref}
              id={id}
              aria-invalid={error ? true : undefined}
              {...rest}
            />
          </ToneFocus>
          {error ? (
            <Error id={errorId} tone={tone}>
              {error}
            </Error>
          ) : null}
        </StackItem>
      </Stack>
    );
  }
);
