import { InputFile } from '@ads-core/components';
import { FileInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  isValidFileCount,
  isValidFileSize,
  isValidFileType,
  getOptions,
  validationModels,
  getEnabledValidation,
} from '@alliander-fe/validation';
import { extractString, getLabel, encodeNameToReactHookFormFormat } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { InputViewWithParameters, PropsWithConditions } from '../types';

type Props = PropsWithConditions<ValueFieldProps<InputViewWithParameters<FileInputViewModel>>>;

export const FileFieldMapper = ({ field, tracker }: Props) => {
  const { register, formState, getValues } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });
  if (isHidden) return null;

  const error = extractString(formState.errors[name]?.message);

  const fileSizeValidator = getEnabledValidation(
    validationModels.FILE_SIZE,
    field.model.validationDataModels
  );
  const fileTypeValidator = getEnabledValidation(
    validationModels.FILE_TYPE,
    field.model.validationDataModels
  );
  const fileCountValidator = getEnabledValidation(
    validationModels.FILE_COUNT,
    field.model.validationDataModels
  );

  const options = getOptions(field, ['required']);

  const methods = register(name, {
    ...options,
    validate: {
      fileSize: (v) => isValidFileSize(fileSizeValidator, v, field.model),
      fileType: (v) => isValidFileType(fileTypeValidator, v, field.model),
      fileCount: (v) => isValidFileCount(fileCountValidator, v, field.model),
    },
  });

  return (
    <InputFile
      {...methods}
      accept={fileTypeValidator ? field.model.allowedContentTypes : undefined}
      multiple={field.model.isMultiple}
      className={field.model.cssClass}
      label={getLabel(field.model.title, !!options.required)}
      error={error}
      tone="onLight"
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
    />
  );
};
