import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '@ads-core/components';
import { CheckedState } from '@radix-ui/react-checkbox';
import { getOptions } from '@alliander-fe/validation';
import { extractString, encodeNameToReactHookFormFormat, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions } from '../types';

type Props = PropsWithConditions<ValueFieldProps>;

export const CheckboxFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, control } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });
  const options = getOptions(field, ['required']);
  if (isHidden) return null;

  const error = extractString(formState.errors[name]?.message);

  return (
    <Controller
      rules={options}
      name={name}
      control={control}
      render={(props) => {
        return (
          <Checkbox
            defaultChecked={field.model.value as CheckedState}
            ref={props.field.ref}
            error={error}
            label={getLabel(field.model.title, !!options.required)}
            className={field.model.cssClass}
            onCheckedChange={(v) => {
              props.field.onChange(v.toString());
            }}
            onFocus={() => tracker.onFocusField(field, getValues(name))}
            onBlur={() => {
              props.field.onBlur();
              tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
            }}
          />
        );
      }}
    />
  );
};
