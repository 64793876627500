import React from 'react';
import { InputBase, InputBaseProps } from '../InputBase';

type InputNumberProps = Omit<InputBaseProps, 'type'>;

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (props, ref) => {
    return <InputBase type="number" {...props} ref={ref} />;
  }
);
