import { Stack } from '@ads-core/components';
import { FormFieldSection, FormTracker } from '@sitecore-jss/sitecore-jss-forms';
import { FieldWithValueProps } from '@sitecore-jss/sitecore-jss-react-forms';

import { useIsConditionallyHidden } from '../hooks';
import { formFieldsFactory } from '..';
import { PropsWithConditions } from '../types';

type SectionFieldProps = PropsWithConditions<FieldWithValueProps<FormFieldSection>>;

export const SectionFieldMapper = ({
  field,
  tracker,
}: {
  field: SectionFieldProps['field'];
  tracker: FormTracker;
}) => {
  const { fieldKey } = field.model.conditionSettings;

  const isHidden = useIsConditionallyHidden({ fieldKey });
  if (isHidden) return null;

  return (
    <fieldset className={`${field.model.cssClass} section-field`}>
      <Stack gap={6}>{formFieldsFactory(field.fields, tracker)}</Stack>
    </fieldset>
  );
};
