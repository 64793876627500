import * as RadixDialog from '@radix-ui/react-dialog';

import { CloseIcon } from '../../icons';
import { ThemeContainer } from '../ThemeContainer';
import { useDialogContext } from '../../providers';
import { IconButton } from '../IconButton';
import { Box } from '../Box';

import { RichText } from '../RichText';
import { Stack } from '../Stack';
import { Heading } from '../Heading';
import { ButtonLink } from '../ButtonLink';

import * as styles from './Dialog.css';

type DialogProps = {
  children: React.ReactElement;
};

export const Dialog = (props: DialogProps) => {
  const { children } = props;
  const { isOpen, closeDialog } = useDialogContext();

  if (!children) {
    return null;
  }

  return (
    <RadixDialog.Root open={isOpen} onOpenChange={closeDialog}>
      <RadixDialog.Portal>
        <ThemeContainer>
          <RadixDialog.Overlay className={styles.overlay} />
          <RadixDialog.Content className={styles.dialogContent}>
            <RadixDialog.Close
              className={styles.closeButton}
              asChild
              onClick={(event) => event.stopPropagation()}
            >
              <IconButton
                icon={<CloseIcon />}
                label="Sluit"
                tone="onLight"
                shape="brand"
                variant="ghost"
              />
            </RadixDialog.Close>
            <Box className={styles.innerContent} width="100%" height="100%">
              {children}
            </Box>
          </RadixDialog.Content>
        </ThemeContainer>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export type DialogContentProps = {
  tag?: string;
  title?: string | React.ReactElement;
  description?: string;
  button?: {
    href?: string;
    text?: string;
  };
  image?: React.ReactNode;
  video?: React.ReactNode;
};

export const DialogContent = (props: DialogContentProps) => {
  const { tag, title, description, button } = props;

  if (!tag && !title && !description && !button) {
    return null;
  }

  return (
    <Box
      width="100%"
      height="100%"
      paddingTop={{ initial: 10, md: 40 }}
      paddingRight={{ initial: 30, md: 40 }}
      paddingLeft={{ initial: 6, md: 20 }}
      paddingBottom={{ initial: 12, md: 24 }}
    >
      <Box paddingBottom={{ initial: 12, md: 10 }} asChild>
        <Box paddingBottom={4}>
          <RichText>{props.tag}</RichText>
        </Box>
      </Box>
      <Box paddingBottom={20} asChild>
        <Stack gap={{ initial: 8, md: 10 }}>
          <RadixDialog.Title asChild>
            <Heading size="h2">{props.title}</Heading>
          </RadixDialog.Title>
          <RadixDialog.Description asChild>
            <RichText>{props.description}</RichText>
          </RadixDialog.Description>
          {props.image ? props.image : null}
          {props.video ? props.video : null}
          <Box>
            {props.button?.text ? (
              <ButtonLink asChild href={props.button?.href}>
                {props.button?.text}
              </ButtonLink>
            ) : null}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const DialogWithContent = (props: DialogContentProps) => {
  if (!props.tag && !props.title && !props.description && !props.button) {
    return null;
  }

  return (
    <Dialog>
      <DialogContent {...props} />
    </Dialog>
  );
};
