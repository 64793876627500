import { GridItem } from '../GridItem';
import { PageGrid } from '../PageGrid';
import { Box } from '../Box';
import { Text } from '../Text';
import { CombineProps } from '../../types/type-helpers';
import { Stack } from '../Stack';
import * as styles from './Image.css';

type ImageWithTextProps = {
  image: React.ReactElement;
  text: string;
};

export type ImageWithTextSitecoreProps = {
  image: React.ReactElement;
  text: React.ReactElement;
};

type ImageWithTextViewProps = CombineProps<
  ImageWithTextProps,
  ImageWithTextSitecoreProps
>;

export const ImageWithTextComponent = (props: ImageWithTextProps) => (
  <ImageWithTextView {...props} />
);

export const ImageWithTextSitecore = (props: ImageWithTextSitecoreProps) => {
  return <ImageWithTextView {...props} />;
};

export const ImageWithTextView = (props: ImageWithTextViewProps) => {
  const leftColsMd = '3';

  return (
    <PageGrid>
      {props.image ? (
        <GridItem  columnStart="1" columnEnd="-1" asChild>
          <Box paddingBottom={{ initial: 6, md: 4 }}>
            <Stack 
              className={styles.ImageClipPath}
              style={{'--mdLeft': (100 / 12) * (Number(leftColsMd) - 1) + '%',}}
              direction='row'
              alignX='center'
              isFullWidth
            >
              <div>
                <Box
                  className={styles.ImageWrapper}
                  asChild
                >
                  <Box width="100%" height="100%" asChild className={styles.image}>
                    {props.image}
                  </Box>
                </Box>
              </div>
            </Stack>
          </Box>
        </GridItem>
      ) : null}
      {props.text ? (
        <GridItem
          columnStart={{ initial: '1', md: leftColsMd }}
          columnEnd={{ initial: '-1', md: 'span 4' }}
        >
          <Text size="description">{props.text}</Text>
        </GridItem>
      ) : null}
    </PageGrid>
  );
};
