import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { WarningIcon } from '../../icons/index';
import { TextLink } from '../TextLink';
import * as styles from './CalamityBanner.css';

type CalamityBannerProps = {
  text: string;
  link?: React.ReactElement;
};

export const CalamityBanner = ({ text, link }: CalamityBannerProps) => {
  return (
    <Box position="relative" zIndex="1" className={styles.banner} asChild>
      <div aria-live="assertive">
        <PageGrid>
          <GridItem columnStart="1" columnEnd="-1" asChild>
            <Box paddingTop={4} paddingBottom={4}>
              <Stack direction="row" gap={4} alignY="center">
                <WarningIcon color="onLight" />
                <Text color="onLight" size="description">
                  {text}{' '}
                  {link ? (
                    <Text weight="bold" size="description" asChild>
                      <TextLink tone="onDark" asChild>
                        {link}
                      </TextLink>
                    </Text>
                  ) : null}
                </Text>
              </Stack>
            </Box>
          </GridItem>
        </PageGrid>
      </div>
    </Box>
  );
};

