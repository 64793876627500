import { Stack } from '../Stack';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading';
import { TextLink } from '../TextLink';
import { Box } from '../Box';
import { Text } from '../Text';

export const CookieAcceptBanner = () => {
  const handleCookieRenewal = () => {
    window.Cookiebot?.renew();
  };

  return (
    <PageGrid>
      <GridItem
        asChild
        columnStart={{ initial: '1', md: '3' }}
        columnEnd={{ initial: '-1', md: '-3' }}
      >
        <Stack gap={10} alignX="start" isFullWidth>
          <Box width="100%">
            <Heading size="h2" align={'center'}>
              Cookies
            </Heading>
          </Box>
          <Stack asChild isFullWidth alignX="justify">
            <Text asChild align="center">
              <TextLink asChild>
                <span onClick={handleCookieRenewal}>
                  Accepteer marketing cookies om dit te bekijken
                </span>
              </TextLink>
            </Text>
          </Stack>
        </Stack>
      </GridItem>
    </PageGrid>
  );
};
