import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { isValidValidationModel } from '@alliander-fe/validation';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@ads-core/components';
import {
  isIBAN,
  getOptions,
  validationModels,
  getEnabledValidation,
} from '@alliander-fe/validation';
import { extractString, encodeNameToReactHookFormFormat, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { InputViewWithParameters, PropsWithConditions } from '../types';

type Props = PropsWithConditions<ValueFieldProps<InputViewWithParameters<StringInputViewModel>>>;

export const InputMapper = ({ field, tracker }: Props) => {
  const { register, formState, getValues, setValue } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const error = extractString(formState.errors[name]?.message);

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const exact18NumbersValidator = getEnabledValidation(
    validationModels.EXACT_18_NUMBERS,
    field.model.validationDataModels
  );
  const exact8NumbersValidator = getEnabledValidation(
    validationModels.EXACT_8_NUMBERS,
    field.model.validationDataModels
  );
  const isPostalCodeValidator = getEnabledValidation(
    validationModels.IS_POSTAL_CODE,
    field.model.validationDataModels
  );
  const isIBANValidator = getEnabledValidation(
    validationModels.IS_IBAN,
    field.model.validationDataModels
  );
  const onlyNumbersValidator = getEnabledValidation(
    validationModels.ONLY_NUMBERS,
    field.model.validationDataModels
  );
  const isNameValidator = getEnabledValidation(
    validationModels.IS_NAME,
    field.model.validationDataModels
  );

  const inputModeNumeric = exact18NumbersValidator || exact8NumbersValidator;

  const options = getOptions(field);
  const methods = register(name, {
    ...options,
    validate: {
      IBAN: (v) => {
        return isIBANValidator ? isIBAN(v, isIBANValidator.message) : true;
      },
      validationModel: (v) => {
        const validationModel =
          exact18NumbersValidator ||
          exact8NumbersValidator ||
          isPostalCodeValidator ||
          isNameValidator ||
          onlyNumbersValidator;

        if (validationModel) {
          return isValidValidationModel(validationModel, v);
        }

        return true;
      },
    }
  });

  return (
    <InputText
      label={getLabel(field.model.title, !!options.required)}
      {...methods}
      placeholder={field.model.placeholderText}
      onFocus={() => tracker.onFocusField(field, getValues(name))}

      onBlur={(e) => {        
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
      onChange={(e) => {
        const value = e.target.value;

        // When the name validator is active we change the first letter to a uppercase.
        if (isNameValidator) {
          setValue(name, value.charAt(0).toUpperCase() + value.slice(1));
        }
      }}
      inputMode={inputModeNumeric ? 'numeric' : undefined}
      error={error}
    />
  );
};
