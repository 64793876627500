import React from 'react';
import { PageGrid } from '../PageGrid';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Stack } from '../Stack';
import { ButtonLink } from '../ButtonLink';
import { ButtonUnset } from '../ButtonUnset';
import { ChevronDownIcon } from '../../icons/index';
import { Divider } from '../Divider';
import { StackItem } from '../StackItem';
import { VisuallyHidden } from '../VisuallyHidden';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './Highlight.css';

type HighlightProps = {
  title?: string;
  highlights?: {
    button?: React.ReactElement;
    title?: string;
    text?: string;
    image?: React.ReactElement;
    position?: 'right' | 'left';
  }[];
  newsOverviewButton?: React.ReactElement;
  onColoredSection?: boolean;
};

type SitecoreHighlightProps = {
  title?: React.ReactElement;
  highlights?: {
    button?: React.ReactElement;
    title?: React.ReactElement;
    text?: React.ReactElement;
    image?: React.ReactElement;
    position?: 'right' | 'left';
  }[];
  newsOverviewButton?: React.ReactElement;
  onColoredSection?: boolean;
};

type HightlightViewProps = CombineProps<HighlightProps, SitecoreHighlightProps>;

export const Highlight = (props: HighlightProps) => {
  return <HighlightView {...props} />;
};

export const SitecoreHighlight = (props: SitecoreHighlightProps) => {
  return <HighlightView {...props} />;
};

const HighlightView = ({
  title,
  highlights,
  newsOverviewButton,
  onColoredSection,
}: HightlightViewProps) => {
  const refs = React.useRef<HTMLDivElement[]>([]);
  if (highlights?.length === 0) {
    return null;
  }
  return (
    <section>
      {title ? (
        <PageGrid as="header">
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Box paddingBottom={12} asChild width="100%">
              <Heading
                size="h2"
                align={{ initial: 'center', lg: 'start' }}
                color={onColoredSection ? 'onDark' : 'onLight'}
                asChild
              >
                {title}
              </Heading>
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
      <Stack isFullWidth gap={{ initial: 0, md: 10 }}>
        {highlights?.map(({ button, image, text, title, position }, index) => {
          const columnEnd = position === 'right' ? '-1' : 'auto';
          return (
            <Box asChild width="100%" key={`highlight-${index}`}>
              <div
                className={styles.imageWrapper}
                ref={(el) => {
                  if (refs.current && el) {
                    refs.current[index] = el;
                  }
                }}
              >
                {image ? (
                  <Box asChild className={styles.image}>
                    {image}
                  </Box>
                ) : null}
                <Box asChild height="100%">
                  <PageGrid className={styles.content} alignY="center">
                    <Box paddingBlock={10} asChild>
                      <GridItem
                        columnStart={{ initial: '1', lg: 'span 5' }}
                        columnEnd={{ initial: '-1', lg: columnEnd }}
                      >
                        <Box
                          bg="backgroundLight"
                          borderRadius="brandXl"
                          padding={{ initial: 10, md: 16 }}
                        >
                          <Stack alignX="start">
                            {title ? (
                              <Box paddingBottom={4}>
                                <Heading size="h5" isResponsive={false} asChild>
                                  {title}
                                </Heading>
                              </Box>
                            ) : null}
                            {text ? (
                              <Box paddingBottom={8} asChild>
                                <Text>{text}</Text>
                              </Box>
                            ) : null}
                            {button ? <ButtonLink asChild>{button}</ButtonLink> : null}
                          </Stack>
                        </Box>
                      </GridItem>
                    </Box>
                    {index !== highlights.length - 1 ? (
                      <Box className={styles.pulseContainer} width="100%" height="100%">
                        <ButtonUnset
                          tone="onDark"
                          className={styles.pulseButton}
                          onClick={() => {
                            const nextRef = refs.current[index + 1];
                            if (nextRef) {
                              nextRef.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              });
                            }
                          }}
                        >
                          <VisuallyHidden>Scroll naar volgende scherm</VisuallyHidden>
                          <ChevronDownIcon color="onDark" size="md" />
                        </ButtonUnset>
                      </Box>
                    ) : null}
                  </PageGrid>
                </Box>
              </div>
            </Box>
          );
        })}
      </Stack>
      {newsOverviewButton ? (
        <Stack alignX="center" alignY="center" isFullWidth asChild direction="row">
          <Box paddingTop={12}>
            <StackItem grow asChild>
              <Divider tone="onDark" />
            </StackItem>
            <ButtonLink variant="ghostOnDark" asChild>
              {newsOverviewButton}
            </ButtonLink>
            <StackItem grow asChild>
              <Divider tone="onDark" />
            </StackItem>
          </Box>
        </Stack>
      ) : null}
    </section>
  );
};

