import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import * as styles from './ButtonUnset.css';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonUnsetProps = ButtonProps & {
  children: React.ReactNode;
} & ToneVariants;

export const ButtonUnset = React.forwardRef<
  HTMLButtonElement,
  ButtonUnsetProps
>(({ className = '', tone = 'onLight', ...props }, ref) => {
  return (
    <ToneFocus tone={tone}>
      <button
        {...props}
        className={clsx(className, styles.buttonUnset)}
        ref={ref}
      />
    </ToneFocus>
  );
});
