import React from 'react';
import { InputBase, InputBaseProps } from '../InputBase';
import { Stack } from '../Stack';

type InputTextProps = Omit<InputBaseProps, 'type'>;

export const InputDate = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    return (
      <Stack asChild alignY="center" direction="row">
        <InputBase type="date" {...props} ref={ref} />
      </Stack>
    );
  }
);
