import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement } from 'react-is';
import { AsChild, InternalComponentProps } from '../Slot';
import * as ColumnsStyles from './Columns.css';

export type ColumnsProps = {
  children?: React.ReactNode;
} & ColumnsStyles.Variants;

type ColumnsComponentProps = ColumnsProps & AsChild & InternalComponentProps;

export const Columns = React.forwardRef<HTMLDivElement, ColumnsComponentProps>(
  (props, ref) => {
    const {
      children,
      className = '',
      as = 'div',
      asChild,
      ...recipeProps
    } = props;

    const recipeClassnames = ColumnsStyles.list(recipeProps);
    const Component = asChild && isElement(children) ? Slot : as;

    return (
      <Component className={clsx(recipeClassnames, className)} ref={ref}>
        {children}
      </Component>
    );
  }
);
