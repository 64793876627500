import { CountryCodesProps, Headers } from '@ads-core/types';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ButtonFormField } from '@sitecore-jss/sitecore-jss-forms';
import { countryCodes } from '@ads-core/data';
import { SimpleRecord } from '../types';

// Get the name and replace all dots with underscores and replace brackets [ ] with { }
export function encodeNameToReactHookFormFormat(name: string) {
  return name.replace(/\./g, '_').replace(/\[/g, '{').replace(/\]/g, '}');
}

// Revert it so we can send it to Sitecore
export function decodeNameToSitecoreFormat(name: string) {
  return name.replace(/_/g, '.').replace(/{/g, '[').replace(/}/g, ']');
}

export function extractString(value: unknown) {
  return typeof value === 'string' ? value : undefined;
}

export const getLabel = (name: string, isRequired: boolean) =>
  isRequired ? name : `${name} (niet verplicht)`;

/** Type guards */
export function isHeadingSize(size: string): size is Headers {
  return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size);
}

export function isButtonField(field: any): field is ButtonFormField {
  return field && 'buttonField' in field;
}

export function isCheckedState(value: any): value is CheckedState {
  return value === true || value === false || value === 'indeterminate';
}

export function stringify(value: any) {
  return value !== undefined && value !== null && typeof value !== 'object'
    ? value.toString()
    : value;
}

export const stringifyObject = (obj: SimpleRecord) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, stringify(value)]));
};

// Apply the correct masking to a the telephone number.
export const TelephoneNumberMasking = (
  value: string,
  countryCode: CountryCodesProps | undefined
): string => {
  const cleanString = value.replace(/[-\s+]/g, '');
  const isMobile = cleanString.startsWith('06');
  let areaCode: string | undefined;
  let otherCountryCode: string | undefined;
  let restOfNumber = cleanString;
  let formattedValue = '';

  if (!countryCode) {
    otherCountryCode = countryCodes.find((code) => cleanString.slice(0, code.length) === code);
  }

  if (countryCode && !isMobile && cleanString) {
    areaCode = countryCode.areaCodes.find((code) => cleanString.slice(0, code.length) === code);
  }

  if (isMobile) {
    restOfNumber = cleanString.slice(2, cleanString.length);
  }

  if (otherCountryCode) {
    restOfNumber = cleanString.slice(otherCountryCode.length, cleanString.length);
  }

  if (areaCode) {
    restOfNumber = cleanString.slice(areaCode.length, cleanString.length);
  }

  if (restOfNumber) {
    formattedValue = restOfNumber.replace(/([a-z0-9]{3})/gi, '$1 ').trim();
  }

  if (isMobile) {
    return `06 ${formattedValue}`;
  }

  if (areaCode) {
    return `${areaCode} ${formattedValue}`;
  }

  if (otherCountryCode) {
    return `+${otherCountryCode} ${formattedValue}`;
  }

  return `${formattedValue}`;
};

// Apply C4 rules for phone Telephone fields
// Starts with +[countrycode]
// Phone number doesn't start with a 0 example 06 should be 6
// There should be a space between the countrycode and the phone number
export const applyC4Rules = (value: string, countryCode: CountryCodesProps | undefined) => {
  if (!value) return '';

  const cleanString = value.replace(/[-\s+]/g, '');
  let firstNumber = cleanString.charAt(0);
  let selectedCountryCode = countryCode?.countryCodes ? `+${countryCode?.countryCodes} ` : '';
  let restOfNumber = cleanString.slice(1, cleanString.length);

  // "Other" countyCode is selected, check the number for a countyCode so we can apply the c4 rules.
  if (!countryCode) {
    const otherCountryCode = countryCodes.find(
      (code) => cleanString.slice(0, code.length) === code
    );
    selectedCountryCode = otherCountryCode ? `+${otherCountryCode} ` : '';
    const phoneNumber = cleanString.slice(selectedCountryCode.length, cleanString.length);

    firstNumber = phoneNumber.charAt(0);
    restOfNumber = phoneNumber.slice(1, phoneNumber.length);
  }

  let updatePhoneNumber = `${selectedCountryCode}${firstNumber}${restOfNumber}`;

  if (firstNumber.startsWith('0')) {
    updatePhoneNumber = `${selectedCountryCode}${restOfNumber}`;
  }

  return updatePhoneNumber;
};
