import React, { createContext, useContext, useId } from 'react';

import { Root as RadioGroupRoot } from '@radix-ui/react-radio-group';
import type { RadioGroupProps as PrimitiveGroupProps } from '@radix-ui/react-radio-group';

import { Stack } from '../';
import { Label, Error as ErrorComponent, InputBaseProps } from '../InputBase';
import { useInputIds } from '../../hooks/useInputIds';
import { ToneVariants } from '../ToneFocusProvider';

interface RadioGroupBaseProps {
  children: React.ReactNode;
  name: string;
  hint?: string;
  error?: string;
  isOptional?: boolean;
}

type RootProps = Pick<
  PrimitiveGroupProps,
  'defaultValue' | 'value' | 'onValueChange'
>;
type StackProps = Pick<
  React.ComponentProps<typeof Stack>,
  'direction' | 'wrap' | 'alignY'
>;

type LabelProps =
  | {
      label: string;
      'aria-labelledby'?: never;
    }
  | {
      'aria-labelledby': string;
      label?: never;
    };

type RadioGroupProps = RadioGroupBaseProps &
  RootProps &
  StackProps &
  LabelProps &
  Pick<InputBaseProps, 'tone'>;

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      label,
      name,
      value,
      onValueChange,
      defaultValue,
      error,
      hint,
      isOptional,
      direction = 'row',
      wrap = true,
      alignY,
      tone,
      ...rest
    },
    ref
  ) => {
    const { errorId, hintId, inputId } = useInputIds({ error, hint });
    const groupLabelId = useId();

    return (
      <RadioGroupRoot
        ref={ref}
        name={name}
        onValueChange={onValueChange}
        value={value}
        defaultValue={defaultValue}
        required={!isOptional}
        aria-labelledby={groupLabelId}
        id={inputId}
        orientation={direction === 'row' ? 'horizontal' : 'vertical'}
        aria-describedby={hintId}
        loop
        {...rest}
      >
        <RadioGroupContext.Provider value={{ ariaDescribedby: errorId, tone }}>
          <Stack gap={3}>
            {label && (
              <Label tone={tone} id={groupLabelId} htmlFor="">
                {label}
              </Label>
            )}
            <Stack direction={direction} gap={3} wrap={wrap} alignY={alignY}>
              {children}
            </Stack>
            {error ? (
              <ErrorComponent id={errorId}>{error}</ErrorComponent>
            ) : null}
          </Stack>
        </RadioGroupContext.Provider>
      </RadioGroupRoot>
    );
  }
);

type RadioGroupContextValue = {
  ariaDescribedby?: string;
} & ToneVariants;

const RadioGroupContext = createContext<RadioGroupContextValue | undefined>(
  undefined
);

export const useRadioGroup = () => {
  const context = useContext(RadioGroupContext);

  if (context === undefined) {
    throw new Error(
      `useRadioCard must be used within a RadioGroupContext.Provider. Make sure to place your Radio component inside a RadioGroup.`
    );
  }

  return context;
};

/**
 * Note on ariaDescribedby and RadioGroupContext
 *
 * When a group of radio buttons is invalid and requires an error, it should be announced by assistive technology. This
 * can be done by describing the group with the error, using aria-describedby. However, when using VoiceOver the error
 * will not be announced (see https://russmaxdesign.github.io/accessible-forms/fieldset-error02.html). On error, when a
 * form is submitted, a radio input will be focussed when it's the first error in the form. The error won't be announced
 * by VoiceOver.
 *
 * We can circumvent this by adding aria-describedby to every radio input instead of the radio group. The downside will
 * be that all screen readers will read the error for every radio input, but the upside is that we're not excluding
 * VoiceOver. For hints we only need to describe the radio group, and not every single radio input because hints are not
 * blocking the user from continuing.
 */
