import React from 'react';
import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { Controller, useFormContext } from 'react-hook-form';
import { InputTelephone } from '@ads-core/components';

import {
  getOptions,
  isPhoneNumber,
  validationModels,
  getEnabledValidation,
} from '@alliander-fe/validation';
import { InputViewWithParameters, PropsWithConditions } from '../types';
import {
  TelephoneNumberMasking,
  applyC4Rules,
  encodeNameToReactHookFormFormat,
  extractString,
  getLabel,
} from '../utils/utils';
import { useConditionalActions } from '../hooks';

type Props = PropsWithConditions<ValueFieldProps<InputViewWithParameters<StringInputViewModel>>>;

export const TelephoneFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, setValue } = useFormContext();
  const tone = 'onLight';

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const error = extractString(formState.errors[name]?.message);

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  const options = getOptions(field, ['required']);

  const isPhoneNumberValidator = getEnabledValidation(
    validationModels.IS_PHONE_NUMBER,
    field.model.validationDataModels
  );

  if (isHidden) return null;

  return (
    <Controller
      name={name}
      rules={{
        ...options,
        validate: {
          validationModel: (v) => {
            return isPhoneNumber(isPhoneNumberValidator, v);
          },
        },
      }}
      render={(props) => {
        return (
          <InputTelephone
            name={props.field.name}
            tone={tone}
            label={getLabel(field.model.title, !!options.required)}
            error={error}
            onFocus={() => {
              const currentValue = getValues(props.field.name);

              tracker.onFocusField(field, currentValue);
            }}
            value={props.field.value}
            onBlur={() => {
              const currentValue = getValues(props.field.name);

              tracker.onBlurField(field, currentValue, error ? [error] : undefined);
              props.field.onBlur();
            }}
            handlePhoneMasking={TelephoneNumberMasking}
            onValueChange={(value, countryCode) =>
              setValue(props.field.name, applyC4Rules(value, countryCode))
            }
          />
        );
      }}
    />
  );
};
