import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { collectActionData } from '../FieldActions';
import { useFormConditionsContext } from '../providers/FormConditionsProvider';
import { replaceNamesWithFieldKeys } from '../FieldMapping';

/**
 * This hooks dispatches actions (hide/show etc) to fields based on the fieldConditions
 * It also returns the hide/show status for the (current) field with a value
 */
export function useConditionalActions({ fieldKey, name }: { fieldKey: string; name: string }) {
  const { conditionsMap, setFieldActionMap, nameToFieldKeyMap } = useFormConditionsContext();
  const { unregister, getValues } = useFormContext();
  const isHidden = useIsConditionallyHidden({ fieldKey });

  const value = useWatch({ name });
  const fieldConditions = conditionsMap[fieldKey];

  React.useEffect(() => {
    const values = replaceNamesWithFieldKeys(getValues(), nameToFieldKeyMap);
    const keysWithActions = Object.fromEntries(collectActionData({ fieldConditions, values }));
    setFieldActionMap((prev) => ({ ...prev, ...keysWithActions }));
  }, [fieldConditions, value, setFieldActionMap, getValues, nameToFieldKeyMap]);

  React.useEffect(() => {
    if (isHidden) {
      unregister(name);
    }
  }, [isHidden, name, unregister]);

  return { isHidden };
}

/**
 * This hook is used to retrieve if a field should be hidden or not.
 * It can also be directly used in fields that do not have a value (like a TextField or a RichTextField)
 */
export function useIsConditionallyHidden({ fieldKey }: { fieldKey: string }) {
  const formConditions = useFormConditionsContext();

  const action = formConditions.fieldActionMap[fieldKey];
  const isHidden = action === 'HIDE' || action === 'DISABLE';

  return isHidden;
}
