import React from 'react';
import clsx from 'clsx';
import { InternalComponentProps } from '../Slot';
import { LoadIcon } from '../../icons';
import * as styles from './Loader.css';

type IconWrapperProps = styles.IconVariants & InternalComponentProps;

export const Loader = React.forwardRef<HTMLOrSVGElement, IconWrapperProps>(
  ({ size, color, className = '', style }, ref) => {
    return (
      <LoadIcon
        ref={ref}
        className={clsx(styles.icon({ size, color }), className)}
        aria-hidden="true"
        style={style}
      />
    );
  }
);
