import React from 'react';
import { Stack, StackProps } from '../Stack';
import * as styles from './PageWrapper.css';

type PageWrapperProps = Pick<StackProps, 'gap' | 'children'>;

export const PageWrapper = ({ gap = 0, children }: PageWrapperProps) => {
  return (
    <Stack gap={gap} className={styles.pageWrapper}>
      {children}
    </Stack>
  );
};
