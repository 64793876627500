import {
  FormResult,
  FormTracker,
  SitecoreForm,
  serializeForm,
} from '@sitecore-jss/sitecore-jss-forms';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Box, Stack } from '@ads-core/components';
import { useTrackingContext, useTrackingFormTouch } from '@liander/context';
import { FormErrors } from '../FormErrors';
import { MapperKeys, mapper } from './mappers/fieldMapper';
import {
  isButtonField,
  encodeNameToReactHookFormFormat,
  decodeNameToSitecoreFormat,
} from './utils/utils';
import { FormConditionsProvider } from './providers/FormConditionsProvider';
import { Field } from './types';
import {
  flattenNestedFields,
  getValueFields,
  createNameToFieldKeyMap,
  createMappedFieldConditions,
} from './FieldMapping';
import { handleValidationErrors } from './FormHandlers';
import { createInitialValues, createInitialActions } from './FormInitialization';

type SubmitStatus = 'loading' | 'success' | 'nextForm' | 'done' | 'error';
export type FormProps = {
  form: SitecoreForm;
  language?: string;
  handleOnSubmit: (status: SubmitStatus) => void;
};

export const Form = ({ form: initialForm, language, handleOnSubmit }: FormProps) => {
  const router = useRouter();
  const [nextForm, setNextForm] = React.useState<SitecoreForm | null>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const sitecoreApiKey = process.env.NEXT_PUBLIC_SITECORE_API_KEY;
  const endpoint = `/api/jss/fieldtracking/register?sc_apikey=${sitecoreApiKey}`;
  const tracker = React.useMemo(() => new FormTracker({ endpoint }), [endpoint]);
  const { trackFormSubmitError, trackFormSubmitSucces } = useTrackingContext();

  const form = nextForm || initialForm;
  const mappedPrefix = encodeNameToReactHookFormFormat(form.htmlPrefix);

  React.useEffect(() => {
    tracker.setFormData(
      form.formItemId.value,
      form.formSessionId.value,
      form.metadata.isTrackingEnabled
    );
  }, [form.formItemId.value, form.formSessionId.value, form.metadata.isTrackingEnabled, tracker]);

  const flattenedFields = React.useMemo(() => flattenNestedFields(form.fields), [form.fields]);

  const initialValues = React.useMemo(
    () => createInitialValues(flattenedFields),
    [flattenedFields]
  );

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
  });

  useTrackingFormTouch({
    fields: methods.formState.touchedFields,
    wizardName: form.metadata.name,
    wizardStep: '1',
  });

  if (!form.metadata) {
    return <div>Form data invalid. Forget to set the rendering contents resolver?</div>;
  }

  const qsLanguage = language ? `&sc_lang=${language}` : '';

  const action = `/api/jss/formbuilder?fxb.FormItemId=${form.metadata.itemId}&fxb.HtmlPrefix=${form.htmlPrefix}&sc_apikey=${sitecoreApiKey}&sc_itemid=${form.contextItemId}${qsLanguage}`;

  const formFields = formFieldsFactory(form.fields, tracker);

  const buttonField = form.fields.find((f) => f.model.fieldTypeItemId === FieldTypes.Button);
  const buttonName = isButtonField(buttonField) ? buttonField.buttonField.name : null;

  const onSubmit: SubmitHandler<Record<string, unknown>> = async (data, event) => {
    handleOnSubmit('loading');
    // Because we've encoded the data to work with react-hook-form, we need to decode it to Sitecore format
    const mappedData = Object.entries(data)
      .filter(([, value]) => value)
      .reduce((acc, [key, value]) => {
        const revertedKey = decodeNameToSitecoreFormat(key);
        return { ...acc, [revertedKey]: value instanceof FileList ? [...value] : value };
      }, {});

    const formData = serializeForm(form, { submitButtonName: buttonName });
    formData.mergeOverwritingExisting(mappedData);

    try {
      const req = await fetch(event?.target.action, {
        body: formData.toMultipartFormData(),
        method: 'post',
        credentials: 'include', // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
      });
      const result = (await req.json()) as FormResult;

      // TODO: improve this ugly piece of handling succes/errors
      if (result.success) {
        trackFormSubmitSucces({ formName: form.metadata.name });
        handleOnSubmit('success');
        if (result.redirectUrl) {
          router.push(result.redirectUrl);
        }
      } else {
        const errorString = Object.values(result.validationErrors).flat();
        if (errorString.length > 0) {
          trackFormSubmitError({
            formName: form.metadata.name,
            errorString: errorString.join(', '),
          });
        }
      }

      handleValidationErrors(result, mappedPrefix, methods.setError);

      if (result.nextForm) {
        handleOnSubmit('nextForm');
        setNextForm(result.nextForm);
      }
    } catch (err) {
      console.error('Error while submitting', err);
      handleOnSubmit('error');
      methods.setError(`root.${mappedPrefix}`, {
        message: JSON.stringify([
          'Er is iets misgegaan bij het versturen van het formulier. Probeer het later opnieuw.',
        ]),
      });
    }
  };

  // Get general form errors
  const parsedGeneralFormErrors: string[] = JSON.parse(
    methods.formState.errors?.root?.[mappedPrefix].message || '[]'
  );

  const fieldsWithValues = getValueFields(flattenNestedFields(form.fields));

  const nameToFieldKeyMap = createNameToFieldKeyMap(Object.keys(initialValues), fieldsWithValues);
  const initialActionMap = createInitialActions({
    initialValues,
    fields: fieldsWithValues,
    nameToFieldKeyMap,
  });

  const mappedFieldConditions = createMappedFieldConditions(flattenedFields);

  return (
    <FormProvider {...methods}>
      <FormConditionsProvider
        nameToFieldKeyMap={nameToFieldKeyMap}
        initialActionMap={initialActionMap}
        conditionsMap={mappedFieldConditions}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          action={action}
          aria-describedby={
            parsedGeneralFormErrors && parsedGeneralFormErrors.length ? 'formErrors' : undefined
          }
          ref={formRef}
        >
          <FormErrors id={'formErrors'} errors={parsedGeneralFormErrors} />
          <Stack gap={6}>{formFields}</Stack>
          <Box paddingBottom={6} />
        </form>
      </FormConditionsProvider>
    </FormProvider>
  );
};

export function formFieldsFactory(fields: Field[] = [], tracker: FormTracker) {
  return fields.map((field) => {
    const type = field.model.fieldTypeItemId as MapperKeys;
    const Component = mapper[type] as any; // We're not differentiating between different types of fields, so any is fine
    if (!Component) {
      return (
        <div key={field.model.itemId} style={{ border: '1px solid red', padding: 10 }}>
          <b>Geen mapping gevonden voor {type}</b>
        </div>
      );
    }

    return <Component field={field} tracker={tracker} key={field.model.itemId} />;
  });
}
