import { capitalizeLocationName, setReplaceText } from 'src/utils';

import { TextLink } from '@ads-core/components';
import { ChecklistIcon, ChevronRightIcon, PhoneAndroidIcon } from '@ads-core/icons';
import { useRouter } from 'next/router';
import { useMedia } from 'react-use';
import { mq } from '@ads-core/breakpoints';
import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';
import { useParams } from 'src/hooks/useParams';

type ExplanationLinksProps = {
  showRecentResolved?: boolean;
  showAllResolved?: boolean;
  hasOutages: boolean;
  outageData: FilteredOutagesOnLocationProps;
};

export const ExplanationLinks = ({
  showRecentResolved,
  showAllResolved,
  hasOutages,
  outageData,
}: ExplanationLinksProps) => {
  const { city } = useParams();
  const isDesktop = useMedia(mq.md);
  const router = useRouter();
  const path = router.query.path || [];
  const queryLocation = city || path[path.length - 1] || null;
  const location = capitalizeLocationName(queryLocation ?? '');

  const links: Array<{
    href: string;
    text: string;
    afterIcon?: React.ReactElement;
    beforeIcon?: React.ReactElement;
  }> = [];

  // Maps the icons to all the different states
  const noOutagesIcon = !hasOutages ? <ChevronRightIcon size="sm" /> : undefined;
  const allResolvedIcon =
    !showRecentResolved && showAllResolved ? <ChevronRightIcon size="sm" /> : undefined;
  const recentResolvedDesktopIcon =
    showRecentResolved && isDesktop ? <ChevronRightIcon size="sm" /> : undefined;
  const recentResolvedMobileIcon =
    showRecentResolved && !isDesktop ? <ChevronRightIcon size="sm" /> : undefined;
  const unresolvedLinkIconOne =
    !showRecentResolved && !showAllResolved ? <PhoneAndroidIcon size="sm" /> : undefined;
  const unresolvedLinkIconTwo =
    !showRecentResolved && !showAllResolved ? <ChecklistIcon size="sm" /> : undefined;

  // Maps the link in case of there are outages
  const linkOne = !hasOutages ? outageData.noOutagesLinkOne : outageData.linkOne;
  const linkTwo = !hasOutages ? outageData.noOutagesLinkTwo : outageData.linkTwo;

  // sets te afterIcon.
  const afterIcon = noOutagesIcon ?? allResolvedIcon ?? recentResolvedDesktopIcon;

  // Sets the links if it as a text and a href
  if (linkOne?.href && linkOne?.text) {
    links.push({
      href: linkOne.href,
      text: linkOne.text,
      afterIcon: afterIcon,
      beforeIcon: noOutagesIcon ? undefined : recentResolvedMobileIcon ?? unresolvedLinkIconOne,
    });
  }

  if (linkTwo?.href && linkTwo?.text) {
    links.push({
      href: linkTwo.href,
      text: linkTwo.text,
      afterIcon: afterIcon,
      beforeIcon: noOutagesIcon ? undefined : recentResolvedMobileIcon ?? unresolvedLinkIconTwo,
    });
  }

  return links.map((link, index) => (
    <TextLink
      href={setReplaceText(link.href, { '{places}': location })}
      key={`link-${index}`}
      afterIcon={link.afterIcon}
      beforeIcon={link.beforeIcon}
    >
      {setReplaceText(link.text, { '{places}': location })}
    </TextLink>
  ));
};

