import * as Select from '@radix-ui/react-select';
import React from 'react';
import { ChevronDownIcon } from '../../icons/index';
import { Stack } from '../Stack';
import { InputBaseOwnProps, Label, Error } from '../InputBase';
import { useInputIds } from '../../hooks/useInputIds';
import { Box } from '../Box';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import * as styles from './Dropdown.css';

type DropdownProps = {
  items: { value: string; label: string }[];
  placeholder?: string;
} & Select.SelectProps &
  InputBaseOwnProps &
  Select.SelectTriggerProps &
  ToneVariants;

export const Dropdown = React.forwardRef<HTMLButtonElement, DropdownProps>((props, ref) => {
  const { items, placeholder, label, error, onFocus, onBlur, tone, ...rootProps } = props;
  const { errorId, inputId } = useInputIds({
    error,
  });

  return (
    <Box asChild position="relative">
      <Stack gap={2}>
        <Label htmlFor={inputId}>{label}</Label>
        <Select.Root {...rootProps}>
          <ToneFocus tone={tone}>
            <Select.Trigger
              id={inputId}
              aria-label={label}
              className={styles.selectTrigger}
              onFocus={onFocus}
              onBlur={onBlur}
              ref={ref}
            >
              <Select.Value placeholder={placeholder} />
              <Select.Icon>
                <ChevronDownIcon />
              </Select.Icon>
            </Select.Trigger>
          </ToneFocus>

          <Select.Content className={styles.selectContent} position="popper" sideOffset={10}>
            <Select.Viewport>
              {(items || []).map((item) => (
                <Select.Item value={item.value} className={styles.selectItem} key={item.value}>
                  <Select.ItemText>{item.label}</Select.ItemText>
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Root>
        {error ? <Error id={errorId}>{error}</Error> : null}
      </Stack>
    </Box>
  );
});

