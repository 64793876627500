import React from 'react';

import { FollowUpStepsSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { FollowUpStepCardType } from '@ads-core/types';

import { TextField, RichTextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { SitecoreText } from '@alliander-fe/jss-utils';

import { ComponentProps } from 'src/jss/lib/component-props';

type FollowUpStepsProps = ComponentProps & {
  fields: {
    tag: TextField;
    title: TextField;
    followUpSteps: {
      fields: {
        moreDetailsText: RichTextField;
        moreDetailsTitle: { value?: string };
        moreDetailsTag: { value?: string };
        moreDetailsButton: { value: { href?: string; text?: string } };
        text: TextField;
        title: TextField;
        tag: TextField;
        type: { value: FollowUpStepCardType };
      };
    }[];
  };
  params?: {
    anchor?: string;
  };
};

const FollowUpStepsSection = (props: FollowUpStepsProps) => {
  const siteCoreSteps = props.fields.followUpSteps.map((step) => {
    return {
      moreDetails: true,
      moreDetailsButton: step.fields.moreDetailsButton?.value,
      moreDetailsTag: step.fields.moreDetailsTag?.value,
      moreDetailsTitle: step.fields.moreDetailsTitle?.value,
      moreDetailsText: step.fields.moreDetailsText?.value,
      tag: <SitecoreText field={step.fields.tag} editable tag="span" />,
      text: <SitecoreText field={step.fields.text} editable tag="p" /> ?? undefined,
      title: <SitecoreText field={step.fields.title} editable tag="p" />,
      type: step.fields.type.value,
    };
  });

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <FollowUpStepsSitecore
        title={<SitecoreText field={props.fields.title} editable tag="h2" />}
        tag={<SitecoreText field={props.fields.tag} editable tag="span" />}
        followUpSteps={siteCoreSteps}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(FollowUpStepsSection);
