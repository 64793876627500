import { DateInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

import { InputDate } from '@ads-core/components';
import {
  getOptions,
  getEnabledValidation,
  validationModels,
  minDateValidator,
  maxDateValidator,
  getFutureDate,
} from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { DatePropsWithConditions, InputViewWithParameters } from '../types';

type Props = DatePropsWithConditions<ValueFieldProps<InputViewWithParameters<DateInputViewModel>>>;

export const DateFieldMapper = ({ field, tracker }: Props) => {
  const { register, formState, getValues } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const minimumDaysInFutureValidator = getEnabledValidation(
    validationModels.MIN_DAYS_IN_FUTURE,
    field.model.validationDataModels
  );
  const maximumDaysInFutureValidator = getEnabledValidation(
    validationModels.MAX_DAYS_IN_FUTURE,
    field.model.validationDataModels
  );

  const error = extractString(formState.errors[name]?.message);
  const options = getOptions(field, ['required']);

  const methods = register(name, {
    ...options,
    validate: {
      minDaysInFuture: (v) =>
        minDateValidator(minimumDaysInFutureValidator, v, field.model.minimumDaysInFuture),
      maxDaysInFuture: (v) =>
        maxDateValidator(maximumDaysInFutureValidator, v, field.model.maximumDaysInFuture),
    },
  });

  const inputMaxDate = () => {
    if (maximumDaysInFutureValidator && field.model.maximumDaysInFuture) {
      const maxDate = getFutureDate(field.model.maximumDaysInFuture);
      return format(maxDate, 'yyyy-MM-dd');
    }

    if (field.model.max) {
      return format(field.model.max, 'yyyy-MM-dd');
    }

    // Added fallback so the user can't fill in a year with more than 4 numbers. This to prevent the sitecore english errors.
    return '9999-12-30';
  };

  const inputMinDate = () => {
    if (minimumDaysInFutureValidator && field.model.minimumDaysInFuture) {
      const maxDate = getFutureDate(field.model.minimumDaysInFuture);
      return format(maxDate, 'yyyy-MM-dd');
    }

    if (field.model.min) {
      return format(field.model.min, 'yyyy-MM-dd');
    }
  };

  return (
    <InputDate
      error={error}
      tone="onLight"
      label={getLabel(field.model.title, !!options.required)}
      className={field.model.cssClass}
      min={inputMinDate()}
      max={inputMaxDate()}
      {...methods}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
    />
  );
};
