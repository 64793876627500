import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { Box, Stack, PostalCodeCheckForm } from '@ads-core/components';
import { PostalCodeCheckFormProps } from '@ads-core/types';
import { useDialogContext } from '@ads-core/providers';

import { IntegrationApi } from '@alliander-fe/api';

import { TextFieldProps } from '@alliander-fe/sitecore-types';
import { RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { FilterValuePropertyFromFields } from 'src/utils/mapWithoutValueProperty';

import {
  setDisplayPostalCode,
  capitalizeLocationName,
  setReplaceText,
  formatPostalCode,
  congestionLevel,
} from 'src/utils';
import { useTrackingContext } from 'src/context/TrackingContext';
import { houseNumberPattern, postalCodePattern } from '@alliander-fe/validation';

import { extractString } from '../Form/utils/utils';
import { replaceUrlParameters } from './utils/replaceUrlParameters';
import { mappedNetworkAvailability } from './utils/mappedNetworkAvailability';
import { mappedNetworkOutages } from './utils/mappedNetworkOutages';
import { mappedNetworkCongestions } from './utils/mappedNetworkCongestions';

import DialogNetwork, { DialogAddressProps, DialogStatusProps } from './_DialogNetwork';

type FormInputProps = {
  postalCode: string;
  houseNumber: string;
  houseNumberAddition?: string;
};

export type NetworkCheckForm = {
  networkCheck?: FilteredNetworkCheckProps;
  color?: PostalCodeCheckFormProps['color'];
  featureToggle?: {
    networkCheck?: boolean;
    outageCheck?: boolean;
    congestionCheck?: boolean;
  };
};

type DialogNetworkProps = {
  networkOutage?: DialogStatusProps;
  networkAvailability?: DialogStatusProps;
  networkCongestions?: DialogStatusProps;
} & Omit<DialogAddressProps, 'modalTag'>;

// With this component we can provide the user with the congestion and outage data on their address:
// To Request the data we need the users postalCode, houseNummer and houseNumberAddition.

// For Network Availability:
// We will only show a message they Liander is Not Operational on their address.

// For the Outage:
// Will only be available when the user address is within the Liander service area.
// Shows if theres a outage on a energyType or not.

// For the congestions:
// When the 'featureToggle.congestionCheck' is false only the 'congestionsTitle' and 'congestionsText' will be visible
// When the 'featureToggle.congestionCheck' is true we will always request the 'mutateCongestions'.
// For the congestions we have the "{queryParameters}" params to redirect to the congestion page.

export const NetworkCheckForm = ({ networkCheck, color, featureToggle }: NetworkCheckForm) => {
  const { openDialog, closeDialog } = useDialogContext();
  const [dialogData, setDialogData] = useState<DialogNetworkProps>();
  const { trackPostalcodeCheckSuccess, trackPostalcodeCheckError } = useTrackingContext();

  const { register, handleSubmit, formState } = useForm({
    mode: 'onTouched',
  });

  const mutateAddress = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails,
  });

  const mutateCongestions = useMutation({
    mutationFn: IntegrationApi.congestionEndpointsGetSmallScaleCongestions,
  });

  const isLoading = mutateAddress.status === 'pending' || mutateCongestions.status === 'pending';

  // Submit the form and sends the request when the form is valid.
  const onSubmit: SubmitHandler<FormInputProps> = async (data: FormInputProps) => {
    const { postalCode, houseNumber, houseNumberAddition } = data;

    setDialogData(undefined);
    openDialog();

    // Get the valid forms and create the displayPostalCode for the viewport
    const displayPostalCode = setDisplayPostalCode(postalCode);
    const requestPostalCode = postalCode.replaceAll(' ', '').toUpperCase();

    let userAddress: FormInputProps & { city?: string } = {
      postalCode: displayPostalCode,
      houseNumber,
      houseNumberAddition,
    };

    // Gets the user address data and create a response based on the data.
    if (featureToggle?.networkCheck || featureToggle?.outageCheck) {
      mutateAddress.mutate(
        {
          postalCode: requestPostalCode,
          houseNumber: Number(houseNumber),
          addition: houseNumberAddition ?? null,
        },
        {
          onError: (response) => {
            // Maps the response to the correct response for the view
            const networkAvailability = mappedNetworkAvailability({
              networkCheck: networkCheck,
              isOperationalElectricity: false,
              isOperationalGas: false,
            });

            // Handles the errors for the serviceAvailability
            setDialogData((prevProps) => {
              return {
                ...prevProps,
                networkAvailability: networkAvailability,
                networkOutage: undefined,
                address: userAddress,
              };
            });

            trackPostalcodeCheckError({ error: response.message || '' });
          },
          onSuccess: ({ address, outageNumber, electricityNetwork, gasNetwork }) => {
            // enrich the userAddress with additional data
            if (address) {
              userAddress = {
                ...userAddress,
                city: address.city ? capitalizeLocationName(address.city) : undefined,
              };
            }

            let networkOutage: DialogStatusProps;
            let networkAvailability: DialogStatusProps;
            const isOperational = electricityNetwork?.isOperational || gasNetwork?.isOperational;

            const replaceText = {
              '{postalCode}': formatPostalCode(userAddress.postalCode) ?? '',
              '{houseNumber}': userAddress.houseNumber ?? '',
              '{houseNumberAddition}': userAddress.houseNumberAddition ?? '',
              '{places}': userAddress.city ?? '',
            };

            if (featureToggle?.networkCheck) {
              // Maps the response to the correct response for the view
              networkAvailability = mappedNetworkAvailability({
                networkCheck,
                isOperationalElectricity: Boolean(electricityNetwork?.isOperational),
                isOperationalGas: Boolean(gasNetwork?.isOperational),
                replaceText: { ...replaceText },
              });
            }

            if (featureToggle?.outageCheck && isOperational) {
              // Create the the url query parameters for the outage check url
              const replaceTextOutage = replaceUrlParameters({
                ...userAddress,
                postalCode: requestPostalCode,
                reference: outageNumber,
              });

              // Maps the response to the correct response for the view
              networkOutage = mappedNetworkOutages({
                networkCheck,
                isHealthyElectricity: Boolean(electricityNetwork?.isHealthy),
                isHealthyGas: Boolean(gasNetwork?.isHealthy),
                replaceText: { ...replaceText, ...replaceTextOutage },
              });
            }

            setDialogData((prevProps) => {
              return {
                ...prevProps,
                networkAvailability: networkAvailability,
                networkOutage: networkOutage,
                address: userAddress,
              };
            });

            // @todo: Implement the correct siteSection parameter.
            trackPostalcodeCheckSuccess();
          },
        }
      );
    }

    // Get the congestion data based on the postal code.
    if (featureToggle?.congestionCheck) {
      mutateCongestions.mutate(
        {
          PostalCode: requestPostalCode,
          HouseNumber: Number(houseNumber),
          Addition: houseNumberAddition,
        },
        {
          onError: (error) => {
            console.error('onError', error.message);
          },
          onSuccess: ({ level }) => {
            const replaceText = {
              '{postalCode}': formatPostalCode(userAddress.postalCode) ?? '',
              '{houseNumber}': userAddress.houseNumber ?? '',
              '{houseNumberAddition}': userAddress.houseNumberAddition ?? '',
              '{places}': userAddress.city ?? '',
            };

            // Maps the urlParams for the congestion page route.
            const replaceTextOutage = replaceUrlParameters({
              ...userAddress,
              postalCode: requestPostalCode,
            });

            const mappedLevel = congestionLevel(level);

            // if undefined is return we log it to the console
            if (!mappedLevel) {
              console.error(`Unknown congestion level ${level}`);
            }

            const networkCongestions = mappedNetworkCongestions({
              level: mappedLevel,
              networkCheck: networkCheck,
              replaceText: { ...replaceText, ...replaceTextOutage },
            });

            setDialogData((prevState) => {
              return {
                ...prevState,
                networkCongestions: networkCongestions,
                address: userAddress,
              };
            });
          },
        }
      );
    }

    // When the the FeatureToggle for the congestionCheck is off we show only some text.
    if (
      (networkCheck?.congestionsTitle || networkCheck?.congestionsText) &&
      !featureToggle?.congestionCheck
    ) {
      const replaceTextOutage = replaceUrlParameters({
        ...userAddress,
        postalCode: requestPostalCode,
      });

      const replaceText = {
        '{postalCode}': formatPostalCode(userAddress.postalCode) ?? '',
        '{houseNumber}': userAddress.houseNumber ?? '',
        '{houseNumberAddition}': userAddress.houseNumberAddition ?? '',
        '{places}': userAddress.city ?? '',
      };

      const networkCongestions: DialogStatusProps = {
        title: networkCheck?.congestionsTitle?.toString() ?? '',
        message: setReplaceText(networkCheck?.congestionsText ?? '', {
          ...replaceTextOutage,
          ...replaceText,
        }),
      };

      setDialogData((prevState) => {
        return {
          ...prevState,
          networkCongestions,
          address: userAddress,
        };
      });
    }
  };

  // Close The dialog when the viewport changes.
  React.useEffect(() => {
    return () => closeDialog();
  }, [closeDialog]);

  if (!networkCheck) return null;

  return (
    <>
      <PostalCodeCheckForm handleOnSubmit={handleSubmit(onSubmit)}>
        <PostalCodeCheckForm.Container color={color}>
          <PostalCodeCheckForm.Heading>
            {networkCheck.title ??
              'networkCheckControleer voor uw adres of het stroomnet vol is en of er storingen zijn'}
          </PostalCodeCheckForm.Heading>
          <PostalCodeCheckForm.Input
            label={networkCheck?.postcode?.toString() ?? 'Postcode'}
            tone="onDark"
            placeholder="1234AB"
            error={extractString(formState?.errors?.postalCode?.message)}
            {...register('postalCode', {
              required: {
                value: true,
                message: 'Dit veld is verplicht',
              },
              pattern: postalCodePattern,
            })}
          />
          <PostalCodeCheckForm.Addendum
            label={networkCheck?.huisnr?.toString() ?? 'Huisnr. en toevoeging'}
            error={extractString(formState?.errors?.houseNumber?.message)}
            tone="onDark"
            houseNumber={{
              label: 'Huisnummer',
              placeholder: '10',
              error: extractString(formState?.errors?.houseNumber?.message),
              ...register('houseNumber', {
                required: {
                  value: true,
                  message: 'Dit veld is verplicht',
                },
                pattern: houseNumberPattern,
              }),
            }}
            addition={{
              label: 'Toevoeging',
              placeholder: 'A',
              error: extractString(formState?.errors?.houseNumberAddition?.message),
              ...register('houseNumberAddition'),
            }}
          />
          <Box paddingTop={1} width="100%">
            <PostalCodeCheckForm.SubmitButton size="large" tone="onDark" variant="secondary">
              {networkCheck?.button ?? 'Controleren'}
            </PostalCodeCheckForm.SubmitButton>
          </Box>
        </PostalCodeCheckForm.Container>
      </PostalCodeCheckForm>

      <DialogNetwork.Dialog>
        {isLoading ? (
          <DialogNetwork.Loading />
        ) : (
          <>
            <DialogNetwork.Address
              address={dialogData?.address}
              modalTag={networkCheck.greetingTitle}
            />
            <DialogNetwork.Heading>{networkCheck.title}</DialogNetwork.Heading>
            <Stack gap={4}>
              {dialogData?.networkAvailability ? (
                <DialogNetwork.Status {...dialogData.networkAvailability} />
              ) : null}
              {dialogData?.networkOutage ? (
                <DialogNetwork.Status {...dialogData.networkOutage} />
              ) : null}
              {dialogData?.networkCongestions ? (
                <DialogNetwork.Status {...dialogData.networkCongestions} />
              ) : null}
            </Stack>
          </>
        )}
      </DialogNetwork.Dialog>
    </>
  );
};

export type SiteCoreNetworkCheckProps = {
  title: TextFieldProps;
  postcode: TextFieldProps;
  huisnr: TextFieldProps;
  button: TextFieldProps;

  greetingTitle: TextFieldProps;
  feedbackModalTitle: TextFieldProps;

  isNotGridOperatorTitle: TextFieldProps;
  isNotGridOperatorText: RichTextField;
  isElectricityAndGasOperatorTitle: TextFieldProps;
  isElectricityAndGasOperatorText: RichTextField;
  isElectricityNotGasOperatorTitle: TextFieldProps;
  isElectricityNotGasOperatorText: RichTextField;
  isNotElectricityGasOperatorTitle: TextFieldProps;
  isNotElectricityGasOperatorText: RichTextField;

  noOutagesTitle: TextFieldProps;
  noOutagesText: RichTextField;
  gasOutageTitle: TextFieldProps;
  gasOutageText: RichTextField;
  electricityOutageTitle: TextFieldProps;
  electricityOutageText: RichTextField;
  gasAndelElectricityOutageTitle: TextFieldProps;
  gasAndelElectricityOutageText: RichTextField;

  noCongestionsTitle: TextFieldProps;
  noCongestionsText: RichTextField;
  congestionsTitle: TextFieldProps;
  congestionsText: RichTextField;
};

export type FilteredNetworkCheckProps = FilterValuePropertyFromFields<SiteCoreNetworkCheckProps>;
