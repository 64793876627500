import React from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { Text, Stack } from '../';
import { Error, InputBaseProps } from '../InputBase';
import { StackProps } from '../Stack';
import * as styles from './CheckboxGroup.css';

export const CheckboxGroup = React.forwardRef<HTMLFieldSetElement, Props>(
  ({ children, label, error, direction = 'column' }, ref) => {
    const { errorId } = useInputIds({ error });

    const augmentedChildren = React.Children.map(children, (child) => {
      return <div>{child}</div>;
    });

    return (
      <fieldset className={styles.group} ref={ref}>
        <Stack gap={2}>
          {label && (
            <Text as="legend" weight="bold" size="label">
              {label}
            </Text>
          )}
          <Stack gap={3} direction={direction} wrap>
            {augmentedChildren}
          </Stack>
          {error && <Error id={errorId}>{error}</Error>}
        </Stack>
      </fieldset>
    );
  }
);

interface Props
  extends Omit<InputBaseProps, 'name' | 'isDisabled' | 'isReadOnly'> {
  children: React.ReactNode[];
  direction?: StackProps['direction'];
}
