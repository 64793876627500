import { FileField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

import { ComponentProps } from 'src/jss/lib/component-props';

import { forceArray, baseQueryParams } from 'src/utils';

import { useRouter } from 'next/router';

import {
  Pagination,
  Heading,
  PageGrid,
  GridItem,
  Stack,
  Checkbox,
  Table,
  TextLink,
  Box,
  Dropdown,
  CheckboxGroup,
  TableVideo,
  TableImage,
  Text,
  StackItem,
  Card,
} from '@ads-core/components';
import { DownloadIcon } from '@ads-core/icons';
import Link from 'next/link';

type JSSFileField = FileField['value'] & {
  description: string;
  extensions: string;
  keywords: string;
  mimeType: string;
  name: string;
  size: string;
};

type MeterManualFields = {
  brand: { value: string };
  image: { value: { alt: string; height: string; width: string; src: string } };
  manual: { value: null | JSSFileField };
  modelType: { value: string };
  sort: { value: string };
  technicalManual: { value: null | JSSFileField };
  type: { value: string };
  videoUrl: { value: string };
};

type MeterManualsJssResponse = {
  fields: {
    items: {
      displayName: string;
      fields: MeterManualFields;
      id: string;
      name: string;
      url?: string;
    }[];
    title: { value: string };
  };
  params: { anchor: string };
} & ComponentProps;

export const queryParams = {
  ...baseQueryParams,
};

const FilterItem = ({
  label,
  filters,
  filterKey,
}: {
  label: string;
  filters: string[];
  filterKey: string;
}) => {
  const value = label.toLowerCase();
  const router = useRouter();
  return (
    <Checkbox
      defaultChecked={filters.includes(value)}
      label={label}
      onCheckedChange={(checked) => {
        const newCategories = checked
          ? [...filters, value]
          : filters.filter((cat) => cat !== value);

        router.push({
          query: {
            ...router.query,
            [filterKey]: newCategories,
          },
        });
      }}
    />
  );
};

const MeterManuals = (props: MeterManualsJssResponse) => {
  const router = useRouter();

  // Get possible filters from the props
  const filterCategories = [...new Set(props.fields.items.map((item) => item.fields.sort.value))];
  const filterTypes = [...new Set(props.fields.items.map((item) => item.fields.type.value))];
  const filterBrands = [...new Set(props.fields.items.map((item) => item.fields.brand.value))];

  // Get data from the query params
  const page = router.query[queryParams.page];
  const amount = router.query[queryParams.amount];
  const category = router.query[queryParams.category];
  const type = router.query[queryParams.type];
  const brand = router.query[queryParams.brand];

  // Normalize filter data so we always have an array
  const categoryUrlFilters = forceArray(category);
  const typeUrlFilters = forceArray(type);
  const brandUrlFilters = forceArray(brand);

  // Filter all the items given the query params
  const filteredItems = props.fields.items.filter((item) => {
    const itemCategory = item.fields.sort.value.toLowerCase();
    const itemType = item.fields.type.value.toLowerCase();
    const itemBrand = item.fields.brand.value.toLowerCase();

    // Give that category and type could be an array or undefined, we need to check both
    // If the category or type is undefined, we don't want to filter on it
    const categoryMatch = !category || categoryUrlFilters.includes(itemCategory);
    const typeMatch = !type || typeUrlFilters.includes(itemType);
    const brandMatch = !brand || brandUrlFilters.includes(itemBrand);

    return categoryMatch && typeMatch && brandMatch;
  });

  const itemCount = filteredItems.length;

  // If no amount is passed from the url, we show this amount of items per page
  const defaultAmount = 6;

  // The amount of items we're showing per page - we're always showing 1 or more items
  const myAmount = Math.max(Math.min(Number(amount || defaultAmount), itemCount), 1);

  // Total amount of pages
  const pageCount = Math.ceil(itemCount / myAmount);

  // The page that we want to show
  const myPage = page ? Math.min(Math.max(Number(page), 1), pageCount) : 1;

  // Indexing the the start and end from the total collection that we want to show
  const from = Math.max(myPage - 1, 0) * myAmount;
  const to = Math.min(myPage * myAmount, itemCount);
  const items = filteredItems.slice(from, to);

  const mappedFields = items.map((item) => {
    const manualHref = item.fields.manual.value?.src;
    const technicalManualHref = item.fields.technicalManual.value?.src;
    const videoUrl = item.fields.videoUrl.value;

    return {
      fields: {
        productInformation: (
          <Stack
            direction={{ initial: 'row' }}
            gap={4}
            alignY="center"
            wrap={{ initial: true, md: false }}
          >
            <StackItem shrink>
              <TableImage
                src={item.fields.image.value.src}
                title={[item.fields.brand.value, item.fields.modelType.value].join(' | ')}
                alt={item.fields.image.value.alt}
              />
            </StackItem>
            <Text size="paragraph">
              {[item.fields.brand.value, item.fields.modelType.value].join(' | ')}
            </Text>
          </Stack>
        ),
        sortType: (
          <Text size="paragraph">
            {[item.fields.sort.value, item.fields.type.value].join(' | ')}
          </Text>
        ),
        manual:
          videoUrl || manualHref || technicalManualHref ? (
            <Stack wrap direction={{ initial: 'column', md: 'row' }} gap={{ initial: 0, md: 1 }}>
              {manualHref ? (
                <TextLink afterIcon={<DownloadIcon />} asChild>
                  <Link href={manualHref} onClick={(event) => event.stopPropagation()}>
                    Handleiding
                  </Link>
                </TextLink>
              ) : null}
              {technicalManualHref ? (
                <TextLink afterIcon={<DownloadIcon />}>
                  <Link href={technicalManualHref} onClick={(event) => event.stopPropagation()}>
                    Technische handleiding
                  </Link>
                </TextLink>
              ) : null}
              {videoUrl && (
                <TableVideo
                  url={videoUrl}
                  title="Meterhandleiding"
                  description={`Hoe werkt de ${item.fields.brand.value} meter?`}
                >
                  Video
                </TableVideo>
              )}
            </Stack>
          ) : null,
      },
    };
  });

  return (
    <PageGrid>
      {props.fields.title.value ? (
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <Box paddingBottom={{ initial: 4, md: 2 }} asChild>
            <Heading size="h1">{props.fields.title.value}</Heading>
          </Box>
        </GridItem>
      ) : null}

      <GridItem columnStart="1" columnEnd="-1">
        <Card
          footer={
            pageCount > 1 ? (
              <Box paddingTop={6} paddingBottom={6}>
                <Pagination
                  pageCount={pageCount}
                  onPageChange={(e) => {
                    const selectedPage = e.selected + 1;
                    router.push({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        [queryParams.page]: selectedPage,
                        [queryParams.amount]: myAmount,
                      },
                    });
                  }}
                />
              </Box>
            ) : null
          }
        >
          <Box asChild paddingBottom={{ initial: 4, md: 12 }}>
            <Stack direction={{ initial: 'column', md: 'row' }} gap={{ initial: 6, md: 10 }}>
              <Stack gap={4}>
                <Box width={{ sm: '100%', md: '310px' }}>
                  <Dropdown
                    placeholder="Kies merk"
                    items={[
                      { label: 'Toon alle', value: '' },
                      ...filterBrands.map((brand) => ({
                        label: brand,
                        value: brand.toLowerCase(),
                      })),
                    ]}
                    label="Filter op merk"
                    name="brand"
                    value={brandUrlFilters[0]}
                    onValueChange={(v) => {
                      router.push({
                        query: {
                          ...router.query,
                          [queryParams.brand]: v.toLowerCase(),
                        },
                      });
                    }}
                  />
                </Box>
              </Stack>

              <Stack gap={4}>
                <CheckboxGroup label="Filter op soort en type: " direction="row">
                  {filterTypes.map((cat) => {
                    return (
                      <FilterItem
                        label={cat}
                        filters={typeUrlFilters}
                        key={cat}
                        filterKey={queryParams.type}
                      />
                    );
                  })}
                  {filterCategories.map((cat) => {
                    return (
                      <FilterItem
                        label={cat}
                        filters={categoryUrlFilters}
                        key={cat}
                        filterKey={queryParams.category}
                      />
                    );
                  })}
                </CheckboxGroup>
              </Stack>
            </Stack>
          </Box>

          {items.length > 0 ? (
            <Table
              title={props.fields.title.value}
              columns={[
                {
                  dataKey: 'productInformation',
                  title: 'Productinformatie',
                  style: { minWidth: 350 },
                },
                { dataKey: 'sortType', title: 'Soort / Type' },
                { dataKey: 'manual', title: 'Handleidingen' },
              ]}
              data={mappedFields}
            />
          ) : (
            <Box asChild paddingTop={{ initial: 4, md: 0 }}>
              <Heading size="h6" as="h4">
                Geen resultaten
              </Heading>
            </Box>
          )}
        </Card>
      </GridItem>
    </PageGrid>
  );
};

export default withDatasourceCheck()<ComponentProps>(MeterManuals);
