import { InputEmail } from '@ads-core/components';
import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import {
  isValidValidationModel,
  getOptions,
  getEnabledValidation,
  validationModels,
} from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { InputViewWithParameters, PropsWithConditions } from '../types';

type Props = PropsWithConditions<ValueFieldProps<InputViewWithParameters<StringInputViewModel>>>;

export const EmailFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, register } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const error = extractString(formState.errors[name]?.message);
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });
  if (isHidden) return null;

  const isEmailValidation = getEnabledValidation(
    validationModels.IS_EMAIL,
    field.model.validationDataModels
  );

  const options = getOptions(field);
  const methods = register(name, {
    ...options,
    validate: {
      validationModel: (v) => isValidValidationModel(isEmailValidation, v),
    },
  });

  return (
    <InputEmail
      error={error}
      tone="onLight"
      label={getLabel(field.model.title, !!options.required)}
      className={field.model.cssClass}
      placeholder={field.model.placeholderText}
      {...methods}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
      autoComplete="email"
    />
  );
};
